import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material'

export const inputDeviceSelectorUseStyles = () : Function => makeStyles((theme : Theme) => ({
  container: {
    display: 'inline-flex', alignItems: 'center' 
  },
  form : { 
    alignItems: 'center',
    margin: '-10px' 
  },
  autocomplete : { 
    marginRight: '10px', width: '230px'
  },
  textField : { 
    width: '230px'
  },
  refreshIcon : {
    // marginTop: '15px', 
    cursor : 'pointer',
    "&:hover": {
        transition: "color 0.2s",
        backgroundColor: theme.palette.secondary.main
    },

  },
  button : {
    marginLeft : '1rem'
  },
  '@media (min-width: 769px)': {
    form: {
      display: 'flex',
      alignItems: 'center',
    },
    autocomplete: {
      marginRight: '10px',
      width: '220px',
    },
  },
}));
