/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import AppRouter from './router/AppRouter';
import PageMessage from '../features/sensor/components/UI/PageMessage';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { initTheme, ThemeType } from '../store/features/view/themeSlice';
import { useInitThemeInfo } from '../features/sensor/hooks/viewHooks';
import { useAppDispatch } from '../store/hooks';

const Preloader: React.FunctionComponent<{}> = (props): React.ReactElement => {
  const { t } = useTranslation();
  const { error, done } = useInitThemeInfo();
  const dispatch = useAppDispatch();

  useEffect(() => {
    !done && dispatch(initTheme(process.env.REACT_APP_DEFAULT_THEME as ThemeType));
  }, [done]);

  if (done && !error.isError) {
    return (
      <AppRouter />
    );
  } 
  
  if (done && error.isError) {
    return <PageMessage message={capitalize(t('shared.genericError'))} height={'100vh'} />;
  }
  
  return ( 
    <></>
  );
};

export default Preloader;

