import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';

export const attemptsByCountryUseStyles = (displacement: number, breakpoint1200px: boolean): Function =>
makeStyles((theme : Theme) => ({
  gridContainer: {
    marginBottom: '118px',
    flexDirection: 'column',
    [theme.breakpoints.up(900)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  },
  sideBox: {
    width: '100%',
    [theme.breakpoints.up(900)]: {
      width: '40%',
      position : 'relative',
      top: `-${0 + theme.spacing(1 + 5)}`,
    },
    [theme.breakpoints.up(1200)]: {
      width: '30%',
    }
  },
  chart: {
    minHeight: '250px'
  },
  mapGridContainer: {
    width: '100%',
    [theme.breakpoints.up(900)]: {
      width: '60%',
    },
    [theme.breakpoints.up(1200)]: {
      width: '70%',
    }
  },
}));
