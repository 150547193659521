import React, { FunctionComponent, useEffect, useRef } from 'react';
import SystemInfoChartDescriptor from './SystemInfoChartDescriptor';

export interface SystemInfoChartWidgetContainerProps {
  setSize?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const SystemInfoChartDescriptorContainer: FunctionComponent<SystemInfoChartWidgetContainerProps> = ({
  setSize
}): React.ReactElement => {
  const rootElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSize && setSize(rootElement.current?.offsetHeight);
  });

  return <SystemInfoChartDescriptor/>;
};

export default SystemInfoChartDescriptorContainer;
