import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useAttemptsInfo } from '../../../../../hooks/viewHooks';
import { AttemptsFilterObj } from '../../../../../interfaces/components';
import { ChartBlockedTableContainerData } from '../../AttemptsByTimeContainer';
import AttemptsDetailsModal from '../../../../modals/components/AttemptsDetailsModal';
import IpDataGrid, { TableData } from '../../../../DataGrid/components/IpDataGrid';
import { blockedTableUseStyles } from './tableContainer-styles';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IpAttemptsList from '../../../../IpListAttempts/components/IpAttemptsList';
import { useAppSelector } from 'src/store/hooks';
import { useFetchIpAttemptsQuery } from 'src/features/sensor/features/DataGrid/store/ipAttempts-api-slice';
import { format as formatDate, parseISO } from 'date-fns';
import { mapData } from 'src/features/sensor/features/MapAttempts/map-utils/country-definitions';
import { calculateNewOffset } from 'src/features/sensor/features/DataGrid/grid-utils/table-utils';

const ChartBlockedTableContainer: FunctionComponent<ChartBlockedTableContainerData> = ({
  setSize,
  outbound
}): React.ReactElement => {
  const classes = blockedTableUseStyles()();
  const openModalRef: React.MutableRefObject<((filter: AttemptsFilterObj) => void) | undefined> = useRef();
  const { disableAttemptsDetailsOnMobile: disableOnMobile, mobileThreshold } = useAttemptsInfo();
  const rootElement = useRef<HTMLDivElement>(null);
  const mobileBreakpoint = useMediaQuery('(max-width: 840px)');
  const preload = process.env.REACT_APP_IP_ROWS_PER_PAGE;
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const rowsPerPage = 15;
  const deviceSelected = useAppSelector(state => state.deviceBase.selectedDevice);
  const timeScalePeriod = useAppSelector(state => state.timePeriod);
  const tooltipText = outbound? t('shared.tooltips.table_blocked_ip.outbounds') : t('shared.tooltips.table_blocked_ip.inbounds');
  const from = timeScalePeriod.from;
  const to = timeScalePeriod.to;

  const queryParams = `?from=${from}&to=${to}&hostname=${deviceSelected?.hostname}&limit=${preload}&offset=${offset}&filter=direction:${outbound? 'outbound' : 'inbound'}`;
  const { data, isLoading, error } = useFetchIpAttemptsQuery(queryParams);

  let rows: TableData[] = [];

  useEffect(()=>{
    setOffset(0)
  }, [timeScalePeriod])
  
  if (data) {
    const attempts = data.attempts.map((attempt, index) => {
      return {
        id: attempt.timestamp + index,
        date: new Date(attempt.timestamp).toLocaleDateString(),
        time: formatDate(parseISO(attempt.timestamp), 'HH:mm:ss'),
        source: outbound ? attempt.target : attempt.threat,
        destination: outbound ? attempt.threat : attempt.target,  
        sourcePort: attempt.details.sourcePort,
        destinationPort: attempt.details.destinationPort,
        protocol: attempt.details.protocol,
        country: (attempt.country in mapData)?  mapData[attempt.country].name : t('shared.unknown')
      }
    })
    rows = [...attempts];
  }

  let attemptsJsx = <React.Fragment></React.Fragment>;

  const handlePageChange = (
    newPage: number,
  ) => {
    const page = newPage + 1;
    if (page > 1) {
      const newOffset = calculateNewOffset(page, rowsPerPage)
      setOffset(newOffset);
    } else {
      setOffset(0);
    }
  }

  if (rows.length > 0 && !mobileBreakpoint) {
    attemptsJsx = <IpDataGrid total={data!.total} handlePageChange={handlePageChange} tableData={rows} isLoading={isLoading} error={error} outbound={outbound} tooltipText={tooltipText}/>;
  } else if (rows.length > 0 && mobileBreakpoint) {
    attemptsJsx = <IpAttemptsList cardData={rows} handlePageChange={handlePageChange} total={data!.total}  />;
  } else {
    attemptsJsx = <></>
  }

  useEffect(() => {
    setSize && setSize(rootElement.current?.offsetHeight);
  });

  return (
    <div className={clsx(classes.container)} ref={rootElement} style = {{display : data?.attempts.length === 0 ? 'none' : ''}}>
      { attemptsJsx }
      <AttemptsDetailsModal
        disableOnMobile={disableOnMobile}
        setOpenRef={openModalRef}
        modalLeftDisplacement={0}
        mobileThreshold={mobileThreshold}
      />
    </div>
  );
};

export default ChartBlockedTableContainer;
