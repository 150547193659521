import  makeStyles  from '@mui/styles/makeStyles';

export const buildGridStyles = () : Function => makeStyles((themeSelected : any) => ({
  root: {
    width: '100%',
    '& .super-app-theme--cell': {
      textTransform: 'lowercase',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'flex',
      justifyContent: 'center',
      border: 0,
    },
    '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
      width: '100%',
      borderBottom: 'none',
    },
    '& .css-f3jnds-MuiDataGrid-columnHeaders': {
      border: 0
    },
    '& .css-gl260s-MuiDataGrid-columnHeadersInner': {
      width: '100%',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none'
    },
    '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
      color: themeSelected.palette.text.primary,
      fontSize: '0.875rem',
      fontWeight: 550,
      
    },
    '& .row-justify': {
      color: themeSelected.palette.text.primary,
    },
    '& div div div div .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '.css-ti3brj-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-ti3brj-MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
      height: '40px',
      width: '40px',
      borderRadius: '50px'
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '52px',
    },
    '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
      color: themeSelected.palette.text.primary,
    },
    protocolCell: {
      '& ..MuiDataGrid-cellContent': {
        textTransform: 'lowercase',
      }
    }
  }
}

));
