import { createSlice } from "@reduxjs/toolkit";
import { IpAttempt, ipAttempsApi } from "./ipAttempts-api-slice";

interface IpAttemps {
      attempts : IpAttempt[]
}


const initialState: IpAttemps = {
      attempts: []
};

const ipAttemptSlice = createSlice({
  name: "ipAttempt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      ipAttempsApi.endpoints.fetchIpAttempts.matchFulfilled,
      (state, { payload }) => {
        state.attempts = payload.attempts;
      }
    );
  },
});

export default ipAttemptSlice.reducer;
