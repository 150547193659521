import { PaletteColor } from '@mui/material/styles/createPalette';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeUnit } from '@amcharts/amcharts4/core';
import customSettings from '../../../themes/defaults/light/customSettings';

//TODO maybe make a different file for the interface
export type MapAnimationType = 'dot-once' | 'line-pernament' | 'line-infinite' | 'line-once' | 'dot-infinite';
export type MapType = 'bubble' | 'heat';

export interface MapColors {
  background: string;
  main: string;
  heatStart: string;
  heatStop: string;
  homeIcon?: string;
  homeButtonHover?: string;
  homeButtonPress?: string;
  animation: string;
}

export interface MapOptions {
  type: MapType;
  animation: {
    enabled: boolean;
    type: MapAnimationType;
    disableOnMobile: boolean;
    mobileThreshold: string;
  };
  colors: MapColors;
  numberOfAnimatedLines: number;
}

export interface ChartColors {
  background: string;
  firstChartColumn: string;
  secondChartColumn: string;
  thirdChartColumn: string;
  color: string;
}

export interface ChartTimeAggregats {
  hourValue: number;
  dayValue: number;
  weekValue: number;
  monthValue: number;
  threeMonthsValue: number;
  sixMonthsValue: number;
  yearValue: number;
  customValue: {
    timeUnit: TimeUnit;
    count: number;
  };
}

export interface ChartTimeMetricsAggregats {
  hourValue: number;
  dayValue: number;
  weekValue: number;
  monthValue: number;
  threeMonthsValue: number;
  sixMonthsValue: number;
  yearValue: number;
  customValue: {
    timeUnit: TimeUnit;
    count: number;
  };
}

export interface BasicChartOptions {
  colors: ChartColors;
  isViewTypeSwitchShown: boolean;
}
export interface AttemptsChartOptions extends BasicChartOptions {
  timeAggregats: ChartTimeAggregats;
}

export interface MetricsChartOptions extends BasicChartOptions {
  timeAggregatsMetrics: ChartTimeMetricsAggregats;
}

export interface AttemptsSettings {
  disableAttemptsDetailsOnMobile: boolean;
  mobileThreshold: string;
}

export interface StatusSettings {
  heartbeat: {
    enabled: boolean;
    speed: number;
  };
  colorScheme: keyof PaletteColor;
}

export interface CustomDashboardSettings {
  map: MapOptions;
  attempts: AttemptsSettings;
  attemptsChart: AttemptsChartOptions;
  metricsChart: MetricsChartOptions;
  status: StatusSettings;
}

export interface CustomSettings {
  dashboard: CustomDashboardSettings;
}

export interface CustomThemeSettings extends CustomSettings {
  isBasicView: boolean;
}

const initialState: CustomThemeSettings = { ...customSettings, isBasicView: true };

const customViewSettings = createSlice({
  name: 'customViewSettings',
  initialState,
  reducers: {
    setMapType(state: typeof initialState, action: PayloadAction<MapType>): void {
      state.dashboard.map.type = action.payload;
    },
    setMapAnimationType(state: typeof initialState, action: PayloadAction<MapAnimationType>): void {
      state.dashboard.map.animation.type = action.payload;
    },
    setMapColors(state: typeof initialState, action: PayloadAction<MapColors>): void {
      state.dashboard.map.colors = action.payload;
    },
    setCustomSettings(state: typeof initialState, action: PayloadAction<CustomSettings>): void {
      state = { ...state, ...action.payload };
      // console.log("c",state.dashboard.map.colors); // right colors are given and set: for useffect() to activate
      // in the mapDataProvider  I think more specific parts of the state have to change in order for things
      // like map colors to change
    },
    setViewType(state: typeof initialState, action: PayloadAction<boolean>): void {
      state.isBasicView = action.payload;
    },
    setAttemptsChartColors(state: typeof initialState, action: PayloadAction<ChartColors>): void {
      state.dashboard.attemptsChart.colors = action.payload;
    },
    setMetricsChartColors(state: typeof initialState, action: PayloadAction<ChartColors>): void {
      state.dashboard.metricsChart.colors = action.payload;
    }
  }
});

export const {
  setMapType,
  setMapAnimationType,
  setMapColors,
  setCustomSettings,
  setViewType,
  setAttemptsChartColors,
  setMetricsChartColors
} = customViewSettings.actions;

export default customViewSettings.reducer;
