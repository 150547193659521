import { DateAxis, ValueAxis, XYChart, XYCursor, SerialChart, LineSeries, Series, ColumnSeries } from "@amcharts/amcharts4/charts";
import { create as createChart } from "@amcharts/amcharts4/.internal/core/utils/Instance";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import colorCodes from "src/themes/defaults/colorCodes";
import { SerialChartType } from "src/features/sensor/components/charts/hooks/chartHooks";
import { capitalize } from "lodash";
import { color as amColor } from '@amcharts/amcharts4/core';
// import { DateRange, TramOutlined } from "@material-ui/icons";


export type SerieType = 'LINE' | 'COLUMN';
export interface XyChart {
  chart: XYChart;
  xAxis: DateAxis;
  yAxis: ValueAxis;
  type: SerialChartType;
}
export interface SerialChartObj {
  chart: SerialChart;
  xAxis: DateAxis;
  yAxis: ValueAxis;
  type: SerialChartType;
}

export interface CustomTimePeriod {
  customFrom : string;
  customTo: string;
}

export interface SerieDefinition {
  field?: string;
  name: string;
  type: SerieType;
}
export interface ChartOptions {
  xAxisTitle: string;
  yAxisTitle: string;
  valueY: string;
  dateX: string;
  name: string;
  type: string;
  outbound? : boolean;
  chartTitle? : string;
}

const colors = {
  background: "#e6f1f5",
  color: colorCodes.dark.secondary.light,
  series: "#007C88",
}

const MS_TO_MINUTE = 60000;
export const MIN_PERIOD = 60000;

export function createXYChartBase(
  chartOps : ChartOptions,
  containerID : unknown
): XyChart {

		const chart: XYChart = createChart(containerID as HTMLDivElement, XYChart);
		const xAxis: DateAxis = chart.xAxes.push(new DateAxis());
		const yAxis: ValueAxis = chart.yAxes.push(new ValueAxis());
    let title = chart.titles.create();





    if(chart){
      if(chartOps.outbound !== undefined && chartOps.chartTitle){
        title.text = chartOps.chartTitle;
        title.fontSize = 25;
        title.fontWeight = 'bold'
        title.fill = amColor(colors.series)
        title.marginBottom = 30;
      }
      yAxis.chart.cursor = new XYCursor();
      yAxis.maxPrecision = 0;
      yAxis.renderer.labels.template.horizontalCenter = "middle";
      xAxis.renderer.labels.template.verticalCenter = "middle";
      xAxis.renderer.labels.template.horizontalCenter = "middle";
      xAxis.renderer.grid.template.disabled = false;
      xAxis.renderer.minGridDistance = 120;
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      xAxis.renderer.grid.template.location = 0.5;
		  if (yAxis.tooltip) yAxis.tooltip.disabled = true;

		  am4core.options.autoDispose = false;
      chart.background.fill = amColor(colors.background);	
      if(chartOps.outbound!== undefined && chartOps.outbound === true){
      chart.background.stroke = am4core.color(colors.series);	
      chart.background.strokeWidth = 5;	
      }
      xAxis.title.text = chartOps.xAxisTitle
      yAxis.title.text = chartOps.yAxisTitle
      xAxis.title.fill = amColor(colors.color);
      yAxis.title.fill = amColor(colors.color); 
      xAxis.renderer.grid.template.stroke = amColor(colors.color);
      yAxis.renderer.grid.template.stroke = amColor(colors.color);
      xAxis.renderer.baseGrid.stroke = amColor(colors.color);
      yAxis.renderer.baseGrid.stroke = amColor(colors.color);
      xAxis.renderer.labels.template.fill = amColor(colors.color);
      yAxis.renderer.labels.template.fill = amColor(colors.color);
    }

    if(chartOps.type === "SERIES") createSeries({chart, xAxis, yAxis, type: "DATE",}, chartOps)

    if(chartOps.type === "COLUMN") createColumnSeries({chart, xAxis, yAxis, type: "DATE",}, chartOps)

    //Removing the logo
    chart.logo.dispose();

		return {
			chart,
			xAxis,
			yAxis,
			type: "DATE",
		};

}

export function changeTitle(chart: XyChart, text: string) : void {
  let title = chart.chart.titles.create();
  title.text = text;
  title.fontSize = 25;
  title.marginBottom = 30;
  title.align = 'left'
}

// export function startEvents(chart: XyChart) : CustomTimePeriod{
//   const xAxis = chart.chart.xAxes.getIndex(0);

//   if(xAxis){
//     xAxis.events.on("startchanged", dateAxisChanged);
//     xAxis.events.on("endchanged", dateAxisChanged);
//   }
//   function dateAxisChanged(ev :any) {
//     let start = new Date(ev.target.minZoomed);
//     let end = new Date(ev.target.maxZoomed);
//     console.log("New range: " + start + " -- " + end);
//   }
// }

//Returns true if the period is lower than the minimum period
export function checkMinPeriod(from : Date, to : Date, count? : number) : boolean{
  if(to.getTime() - from.getTime() <= (MIN_PERIOD * (count ? count : 1))){
    return true; 
  }
  return false
}

//Rounds the date based on the chart resolution
export function roundDate(date : Date, timeUnit : string, count : number) : Date{
  let coeff = MS_TO_MINUTE *  getAmChartsResolution(timeUnit) * count;

  return new Date(Math.round(date.getTime() / coeff) * coeff)
}

//calculates the beggining of the week, and shifts the date to the begging of the week. This way if the resolution is set to week
//we can selected the attemmpts properly
export function weekOffsetFrom(date : Date){
  return date.getDate() - date.getDay()
}

//Same thing as weekOffsetFrom but for the end of the week
export function weekOffsetTo(date : Date){
  return date.getDate() + (7 - date.getDay())
}

export function changeLegend(chart: XyChart, chartOps: ChartOptions) : void {
  const yAxis = chart.chart.yAxes.getIndex(0);
  const xAxis = chart.chart.xAxes.getIndex(0);
  const title = chart.chart.titles.getIndex(0);

  if(chartOps.outbound !== undefined && chartOps.chartTitle && title){
    title.text = chartOps.chartTitle;
  }

  if(xAxis && yAxis){
    xAxis.title.text = chartOps.xAxisTitle
    yAxis.title.text = chartOps.yAxisTitle
  }
}

function createSeries(chart: XyChart, chartOpts: ChartOptions): void {
  const series: LineSeries = new LineSeries();
  series.name = chartOpts.name;
  series.dataFields.valueY = chartOpts.valueY;
  series.dataFields.dateX = chartOpts.dateX;
  series.strokeWidth = 0.1;
  series.fillOpacity = 0.3;

  if (series.tooltip) {
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = amColor("#000000");
    series.tooltipText = `${capitalize("{name}")}: {valueY}`;
  }
  if(chart.chart.series.length >0) chart.chart.series.removeIndex(0).dispose()

  chart.chart.series.push(series);
}


// create column series
export function createColumnSeries(
chart: XyChart, chartOpts: ChartOptions
): void {
	const series: ColumnSeries = new ColumnSeries();
	series.name = chartOpts.name;
	series.dataFields.valueY = chartOpts.valueY;
	series.dataFields.dateX = chartOpts.dateX;
	series.sequencedInterpolation = true;
	series.columns.template.width = am4core.percent(20);
	if (series.tooltip) {
		series.tooltip.getFillFromObject = false;
		series.tooltip.background.fill = amColor("#000000");
		series.tooltipText = `${capitalize("{name}")}: {valueY}`;
	}

  if(chart.chart.series.length >0) chart.chart.series.removeIndex(0).dispose()

  chart.chart.series.push(series);
}

export function changeColumnsWidth(chartObj: XyChart): void {
  
  const {
    chart: { series },
  } = chartObj; 
  series.template.width = am4core.percent(5);

}


export function injectDataIntoSeries(chartObj : XyChart, data: any[], chartOpts : ChartOptions, from? : string, to? : string, outbound? : boolean) : void {

  const {
    chart: { series },
  } = chartObj;

  const dateAxis = chartObj.chart.xAxes.getIndex(0) as DateAxis;

  //Define min and max so the chart period for outbounds matches the inbounds chart period
    if(outbound){
      dateAxis.min = new Date(from!).getTime()
      dateAxis.max = new Date(to!).getTime()
      if(outbound && dateAxis.tooltip){

        dateAxis.tooltip.disabled = true;
      } 
    }

    //Define min and max when a period with no inbounds is selected so the charts shows this period
    if(data.length === 0){
      dateAxis.min = new Date(from!).getTime()
      dateAxis.max = new Date(to!).getTime()
    }else{
      dateAxis.min = undefined
      dateAxis.max = undefined
    }
  series.values.forEach((serie: Series): void => {
    serie.data = data;
    serie.fill = amColor(colors.series);
    serie.stroke = amColor(colors.series);
  });
}


export const createChartObject = (elemId: unknown): XyChart => {

  const chart: XYChart = createChart(elemId as HTMLDivElement, XYChart);
  const xAxis: DateAxis = chart.xAxes.push(new DateAxis());
  const yAxis: ValueAxis = chart.yAxes.push(new ValueAxis());
  let title = chart.titles.create();
  title.text = "Speed";
  title.fontSize = 25;
  title.marginBottom = 30;
  yAxis.chart.cursor = new XYCursor();
  yAxis.maxPrecision = 0;
  yAxis.renderer.labels.template.horizontalCenter = "middle";
  xAxis.renderer.labels.template.verticalCenter = "middle";
  xAxis.renderer.labels.template.horizontalCenter = "middle";
  xAxis.renderer.grid.template.disabled = false;
  xAxis.renderer.minGridDistance = 120;
  chart.cursor.lineY.disabled = true;
  chart.cursor.lineX.disabled = true;
  xAxis.renderer.grid.template.location = 0.5;
  if (yAxis.tooltip) yAxis.tooltip.disabled = true;
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.name = "IP";
  series.dataFields.valueY = "value";
	series.dataFields.dateX = "id";
	series.sequencedInterpolation = true;
  series.stacked = true;
  series.columns.template.width = am4core.percent(25);
  if (series.tooltip) {
		series.tooltip.getFillFromObject = false;
		series.tooltip.background.fill = amColor("#000000");
		series.tooltipText = `${capitalize("{name}")}: {valueY}`;
	}
  // am4core.options.autoDispose = true;

  return {
    chart,
    xAxis,
    yAxis,
    type: "DATE",
  }
}

export const getAmChartsResolution = (resolution : string): number => {
  switch (resolution){
    case 'minute':
      return 1;
    case 'hour':
      return 60;

    default:
      return 1;
  }
}



export const getChartResolution = (time: string): string => {

  let resolution = "";
  switch(time) {
    case 'h':
      resolution = "1m";
    break;
    case 'd':
      resolution = "1h";
    break;
    case 'w':
      resolution = "1d";
    break;
    case 'm':
      resolution = "1d";
    break;
    case '3m':
      resolution = "2d";
    break;
    case '6m':
      resolution = "1w";
    break;
    case 'y':
      resolution = "1w";
    break;

    default:
      resolution = "1m";
    break;

  }

  return resolution;

}