import { createSlice, PayloadAction } from '@reduxjs/toolkit';



export interface ErrorState {
  error: boolean;
  errorCode?: string;
  errorMessage?: string;
}
  
const initialState: ErrorState = {
  error: false,
};

const errorSlice = createSlice({
  name: 'errorState',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ErrorState>): void {
      state.error = action.payload.error;
      state.errorCode = action.payload.errorCode;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const {setError} = errorSlice.actions

export default errorSlice.reducer;