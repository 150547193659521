import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';

export const customPaginationStyles = () : Function => makeStyles((themeSelected : Theme) => ({
  root: {
    '& .MuiPaginationItem-root.Mui-selected': {
        backgroundColor: themeSelected.palette.primary.main,
        color: themeSelected.palette.primary.contrastText,
      },
      '& .MuiPaginationItem-root.Mui-selected:hover': {
        backgroundColor: themeSelected.palette.primary.light,
        color: themeSelected.palette.text.secondary,
      },
      '& .MuiPaginationItem-root:hover': {
        backgroundColor: themeSelected.palette.primary.light,
        color: themeSelected.palette.text.secondary,
      },
  }
}));
