import colorInterpolate from 'color-interpolate';
import { toUpper } from 'lodash';
import i18next from 'i18next';
import { AccumulatedObj } from '../../interfaces/services';
import { AggregatedObjFunction } from '../../interfaces/components';
import { GroupedObj } from '../../interfaces/components';
import { MergedData, MergedMapData } from './countryDefinitions/countryDefinitions';
import { logMessage } from '../logging/logging';

export type PrepareMapData = (
  id: string,
  max: number,
  value: number
) => ColorEnhancedAccumulatedObj | undefined;

const colorTester = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export interface ColorEnhancedAccumulatedObj extends AccumulatedObj {
  color: string;
}

export function prepareMapData(colorMin: string, colorMax: string): PrepareMapData {
  const isInputCorrect = colorTester.test(colorMin) && colorTester.test(colorMax);
  //TODO memoize generated gradient
  const grad = isInputCorrect ? colorInterpolate([colorMin, colorMax]) : null;
  return (id: string, max: number, value: number): ColorEnhancedAccumulatedObj | undefined => {
    const base = MergedMapData.find((elem) => elem.id === id);
    const color = grad ? (max >= value ? grad(value / Number(max.toFixed(1))) : grad(1)) : '';
    if (base) {
      return { ...base, value, color };
    } else {
      return undefined;
    }
  };
}

export type AggregatedCountryData<T extends GroupedObj = GroupedObj> = T & Partial<MergedData>;

export function enchanceDataWithCountryNames<T extends AccumulatedObj>(
  data: T[]
): AggregatedCountryData<T>[] {
  return data
    ? data.map((dataElem) => {
        const countryData = MergedMapData.find((countryObject) => countryObject?.id === dataElem.id);
        !countryData && logMessage(['enchanceDataWithCountryNames error', dataElem.id]);
        return { ...dataElem, ...countryData };
      })
    : [];
}

export const enchanceWithCountryName: AggregatedObjFunction<AggregatedCountryData, Record<string, any>> = (
  data: AggregatedCountryData
) => {
  if (data) {
    const countryData =
      !data.name || !data.latitude || !data.longitude
        ? MergedMapData.find((countryObject) => countryObject.id === data.id)
        : {};
    !countryData && logMessage(['enchanceDataWithCountryNames error', data.id]);
    return { ...data, ...countryData };
  }
  return data;
};

export function getCountryById(id: string): string {
  return MergedMapData.find((country) => country.id === id)?.name || toUpper(i18next.t('shared.na'));
  //countryName === '' && console.log('getCountryById error', id);
}
