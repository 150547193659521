import React, { FunctionComponent } from 'react';
import { Info as InfoIcon } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';
import { Fade, Paper, Popper } from '@mui/material';
import { PopperPlacementType } from '@mui/material/Popper';
import clsx from 'clsx';

type TooltipSize = 'SMALL' | 'LARGE';

interface TooltipProps {
  size: TooltipSize;
  children? : any;
  text: string;
  placement: PopperPlacementType;
  positionClassName?: string;
}

const useStyles = (iconSize: number, iconBackgroundSize: number): Function =>
  makeStyles((theme : Theme) => ({
    iconContainer: {
      display: 'flex',
      zIndex: 10,
      position: 'relative'
    },
    icon: {
      color: theme.palette.success.main,
      zIndex: 2,
      height: iconSize,
      width: iconSize
    },
    popper: {
      zIndex: 2000
    },
    paper: {
      padding: 10,
      background: theme.palette.background.default,
      color: theme.palette.secondary.contrastText,
      maxWidth: 200,
      fontSize: 12
    },
    iconBackground: {
      width: iconBackgroundSize,
      height: iconBackgroundSize,
      background: theme.palette.success.contrastText,
      position: 'absolute',
      top: '25%',
      left: '25%',
      zIndex: 1,
      pointerEvents: 'none'
    }
  }));

const Tooltip: FunctionComponent<TooltipProps> = ({
  size,
  text,
  placement,
  positionClassName
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const iconSize: number = size === 'SMALL' ? 17 : 33;
  const iconBackgroundSize: number = size === 'SMALL' ? 9 : 18;
  const classes = useStyles(iconSize, iconBackgroundSize)();
  const open = Boolean(anchorEl);
  const id: string | undefined = open ? 'popper' : undefined;
  const fadeTimeout = 350;

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseOut = (): void => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx(positionClassName)}>
      <div
        aria-describedby={id}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={classes.iconContainer}
      >
        <InfoIcon className={classes.icon} pointerEvents={'none'} />
        <div className={classes.iconBackground} />
      </div>
      <Popper id={id} className={classes.popper} open={open} anchorEl={anchorEl} placement={placement}>
        <Fade in={open} timeout={fadeTimeout}>
          <Paper className={classes.paper}>{text}</Paper>
        </Fade>
      </Popper>
    </div>
  );
};

export default Tooltip;
