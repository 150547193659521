import  makeStyles  from '@mui/styles/makeStyles';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { ITheme } from '../../../../themes/ITheme';

const drawerWidth = 220;

export const dashboardCss = () : Function => makeStyles((theme: ITheme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    // overflow: 'hidden',
    backgroundColor: theme.extraThemeSettings?.dashboard.background,
    color: theme.palette.text.primary,
    textTransform: 'capitalize'
  },
  logoSpacing: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  absolutelyCentered: {
    position : 'absolute'
  },
  dashboardTitle: {
    flexGrow: 1,
    top: '10%',
    left: '50%',
    transform: 'translate(-50%,0%)',
  },
  title: {
    flexGrow: 1,
  },
  period:{
    fontSize: "small", position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%,80%)',
    textTransform: 'none',
  },
  deviceName:{
    fontSize: "small", position: 'absolute',
    top: '23%',
    left: '50%',
    transform: 'translate(-50%,80%)',
    textTransform: 'none',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    padding: 16,
    backgroundColor: theme.extraThemeSettings?.header.background,
    display: 'flex',
    justifyContent: 'space-between'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    color: theme.palette.secondary.contrastText
  },
  appBarDrawerOpen: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down(1279)]: {
      width: '100%',
    }
  },
  buttonsWrapper: {
    height: '60px'
  },
  deviceSelectionButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  menuButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 0,
    [theme.breakpoints.up(1279)]: {
      marginRight: 36,
      display: 'none',
    }
  },
  menuButtonHidden: {
    display: 'block'
  },
  companyName: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  toolbarItemMargin: {
    margin: `0px ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      margin: '0px 10px 0px 0px'
    }
  },
  toolbarMobileWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  closeDropdownButton: {
    top: '10px',
    right: '10px',
    position: 'absolute',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  closeDropdownIcon: {
    color: theme.palette.grey[500],
    fontSize: '26px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflow: 'visible',
    backgroundColor: theme.extraThemeSettings?.drawer.background,
    color: theme.palette.primary.contrastText,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    paddingTop: theme.spacing(8)
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: 'relative',
    flexGrow: 1,
    height: '100vh',
    overflow: 'visible',
    paddingTop: "5rem"  
  },
  cris_content: {
    position: 'relative',
    flexGrow: 1,
    height: '100vh',
    overflowX: 'visible',
  },
  container: {
    paddingTop: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4)
    },
    backgroundColor: theme.palette.secondary.main
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  feedbackButton: {
    background: theme.palette.success.main,
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 30,
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    textTransform: 'none',
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    '& > span': {
      cursor: 'pointer'
    },
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    },
    [theme.breakpoints.down('sm')]: {
      '&:hover': {
        backgroundColor: theme.palette.success.main
      }
    }
  },
  status: {
    backgroundColor: theme.palette.background.default
  },
}));

// don't think this is used -> similar code in getIndicatorClassForStatus in viewHelpers that is actually used
export const useIndicatorStyles = (version: keyof PaletteColor): Function =>
  makeStyles((theme: ITheme) => ({
    indicatorOk: {
      borderColor: theme.palette.success[version],
      color: theme.palette.success[version],
      backgroundColor: theme.palette.success[version]
      //boxShadow: '0px 0px 6px 2px rgba(31,237,0,1)',
    },
    indicatorWarning: {
      borderColor: theme.palette.warning[version],
      color: theme.palette.warning[version],
      backgroundColor: theme.palette.warning[version]
      //boxShadow: '0px 0px 6px 2px rgba(245,228,0,1)',
    },
    indicatorError: {
      borderColor: theme.palette.error[version],
      color: theme.palette.error[version],
      backgroundColor: theme.palette.error[version]
      //boxShadow: '0px 0px 6px 2px rgba(217,42,2,1)',
    },
    indicatorUnknown: {
      borderColor: theme.palette.grey[500],
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.grey[500]
    }
  }));
