import React from 'react';
import { useSizeCallback } from '../../../../hooks/utilityHooks';
import { AttemptType } from '../AttemptsByTimeContainer';
import ContentBoxBase from '../../../../layouts/ContentBoxBase';
import ChartBlockedTableContainer from './chartDescriptor/ChartBlockedTableContainer';
import { useAppSelector } from 'src/store/hooks';
import AttemptsChartWidget from 'src/features/sensor/components/charts/AttemptsChartWidget';
import {attemptsContentBoxUseStyles} from './AttemptsContentBox-styles'
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
export interface AttemptsContentBoxProps {
  title: string;
  tooltipText: string;
  dataToDisplay: AttemptType;
  chartTooltipText?: string;
  chartItem: React.ReactElement;
  isActive?: boolean;
  displacement: number;
  iconSize?: number;
  isBasicView: boolean;
  compensation?: number;
}

const AttemptsContentBox: React.FC<AttemptsContentBoxProps> = ({
  title,
  tooltipText,
  dataToDisplay,
  chartTooltipText,
  chartItem,
  isActive = true,
  displacement,
  iconSize = 0,
  isBasicView,
  compensation = 30
}: AttemptsContentBoxProps): React.ReactElement => {

  const { size: sizeBottom, setSize: setSizeBottom } = useSizeCallback();
  const classes = attemptsContentBoxUseStyles()()
  const { t } = useTranslation();
  const outboundsNumber = useAppSelector(state => state.stats.outboundAttempts);

  return (
    <>
      {outboundsNumber?
      <div className={clsx(classes.container)}>
      <ContentBoxBase
        compensation={compensation}
        sizeBottom={sizeBottom as number}
        topComponent={(<AttemptsChartWidget id={`basicViewAttempts`} outbound= {true}/>)}
        displacement={displacement}
        iconSize={iconSize}
        isActive={isActive}
        bottomComponent={(
          <ChartBlockedTableContainer
            setSize={setSizeBottom as React.Dispatch<React.SetStateAction<number | undefined>>}
            dataToDisplay={dataToDisplay}
            title={title}
            tooltipText={tooltipText}
            outbound= {true}
          />
        )}
      tooltipText={t('shared.tooltips.table_blocked_ip.outbounds')}
    /> 
    </div>
    : null}
    <ContentBoxBase
      compensation={compensation}
      sizeBottom={sizeBottom as number}
      topComponent={(<AttemptsChartWidget id={`basicViewAttempts`} outbound= {false}/>)}
      displacement={displacement}
      iconSize={iconSize}
      isActive={isActive}
      bottomComponent={(
          <ChartBlockedTableContainer
            setSize={setSizeBottom as React.Dispatch<React.SetStateAction<number | undefined>>}
            dataToDisplay={dataToDisplay}
            title={title}
            tooltipText={tooltipText}
          />
        )}
      tooltipText={t('shared.tooltips.table_blocked_ip.inbounds')}
    />
    </>
  );
};

export default AttemptsContentBox;
