/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid } from '@mui/material';
// import DateFnsUtils from '@date-io/date-fns';
// import { DatePicker } from '@mui/lab'
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
 
import { DateTimePicker, LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { subDays, startOfDay, endOfDay } from 'date-fns';
import { capitalize } from 'lodash';
import CustomTimeScaleSwitch from '../../../components/UI/NamedSwitch';
import { TimeScaleSelectorProps } from './TimeScaleSelector';
import { commonCss } from '../../../assets/css/commonCss';
import { ClassNameOnly } from '../../../interfaces/components';
import { Timescale, CustomTimescale } from '../../../interfaces/services';
import { customDatesUseStyles } from '../assets/timeSelection-styles';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';

export interface CustomDatesModalSelectorProps
  extends Omit<TimeScaleSelectorProps, 'label' | 'handleChange' | 'customButtonLabel'> {
  open: boolean;
  handleSubmit: (value: Timescale) => void;
  handleClose: () => void;
}

const CustomDatesModalSelector: React.FunctionComponent<CustomDatesModalSelectorProps & ClassNameOnly> = ({
  handleSubmit,
  handleClose,
  dialogLabel,
  dateFormat,
  timePeriod,
  ampm,
  open
}: CustomDatesModalSelectorProps): React.ReactElement => {
  const [customTimeScale, setCustomTimeScale] = useState('dates');
  const classes = customDatesUseStyles()();
  const commonClasses = commonCss()();
  const { t } = useTranslation();
  const defaultDateFormat = dateFormat || 'dd/mm/yyyy';
  const defaultDateTimeFormat = `${defaultDateFormat} HH:mm:ss`;
  const [selectedDates, setSelectedDates] = useState<CustomTimescale>({
    from: subDays(new Date(), 1),
    to: new Date()
  });

  const handleCloseInternal = (event: React.MouseEvent, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    event.preventDefault();
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    handleClose();
  };

  const handleCustomTimeScaleSwitch = (event: React.FormEvent, checked: boolean): void => {
    if (checked) {
      setCustomTimeScale('time');
    } else {
      setCustomTimeScale('dates');
    }
  };

  // console.log("Custom time scale", defaultDateFormat);

  const handleFromDateChange = (dateFrom: any): void => {
    // console.log("Date from", dateFrom.toDate());
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dateFrom && setSelectedDates({ ...selectedDates, from: startOfDay(dateFrom.tz().utc(true).toDate()) });
  };

  const handleToDateChange = (dateTo: any): void => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dateTo && setSelectedDates({ ...selectedDates, to: endOfDay(dateTo.tz().utc().toDate()) });
  };

  const handleFromTimeChange = (dateFrom: Dayjs | null): void => {
    dateFrom && setSelectedDates({ ...selectedDates, from: dateFrom.toDate() });
  };

  const handleToTimeChange = (dateTo: Dayjs | null): void => {
    dateTo && setSelectedDates({ ...selectedDates, to: dateTo.toDate() });
  };

  const handleSubmitInternal = (event: React.MouseEvent): void => {
    event.preventDefault();
    console.log('Custom dates', selectedDates);
    handleSubmit({ value: 'custom', selectedDates });
  };

  useEffect(() => {
    if (open) {
      setSelectedDates({ from: subDays(new Date(), 1), to: new Date() });
    }
  }, [open]);


  return (
    <Dialog open={open} onClose={handleCloseInternal}>
      <DialogTitle>{dialogLabel}</DialogTitle>
      <DialogContent>
        <Box className={clsx(classes.verticalSeparator)}>
          <CustomTimeScaleSwitch
            labelOn={`${capitalize(t('shared.date_plural'))} & ${capitalize(t('shared.time'))}`}
            labelOff={capitalize(t('shared.date_plural'))}
            handleChange={handleCustomTimeScaleSwitch}
            checked={customTimeScale !== 'dates'}
          />
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid
            container
            justifyContent={'space-between'}
            spacing={2}
            className={clsx(customTimeScale !== 'dates' && commonClasses.hide)}
          >
            <Grid item xs={5}>
              <DatePicker
                // disableToolbar
                className={classes.test}
                // autoOk
                disableFuture
                // initialFocusedDate={new Date()}
                minDate={dayjs('2017-01-01T00:00:00')}
                // variant="inline"
                format={defaultDateFormat}
                // margin="normal"
                // id="from"
                label={capitalize(t('dashboard.menu.dateStart'))}
                value={dayjs(selectedDates.from)}
                onChange={handleFromDateChange}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                // disableToolbar
                // autoOk
                disableFuture
                // initialFocusedDate={new Date()}
                minDate={dayjs('2017-01-01T00:00:00')}
                // variant="inline"
                format={defaultDateFormat}
                // margin="normal"
                // id="to"
                label={capitalize(t('dashboard.menu.dateStop'))}
                value={dayjs(selectedDates.to)}
                onChange={handleToDateChange}
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid
            container
            justifyContent={'space-between'}
            spacing={2}
            className={clsx(customTimeScale !== 'time' && commonClasses.hide)}
          >
            <Grid item xs={5}>
              <DateTimePicker
                ampm={ampm}
                // disableToolbar
                disableFuture
                // initialFocusedDate={dayjs()}
                format={defaultDateTimeFormat}
                // minDate={dayjs('2017-01-01T00:00:00')}
                value={dayjs(selectedDates.from)}
                onChange={handleFromTimeChange}
                label={capitalize(t('dashboard.menu.dateStart'))}
              />
            </Grid>
            <Grid item xs={5}>
              <DateTimePicker
                ampm={ampm}
                //disableToolbar
                disableFuture
                // initialFocusedDate={new Date()}
                // referenceDate={}
                format={defaultDateTimeFormat}
                // minDate={dayjs('2017-01-01T00:00:00')}
                value={dayjs(selectedDates.to)}
                onChange={handleToTimeChange}
                label={capitalize(t('dashboard.menu.dateStop'))}
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseInternal} color="primary">
          {capitalize(t('shared.actions.cancel'))}
        </Button>
        <Button onClick={handleSubmitInternal} color="primary">
          {capitalize(t('shared.actions.ok'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDatesModalSelector;
