import { StateApiObj, ApiError } from '../../../features/sensor/interfaces/services';
import { PayloadAction } from '@reduxjs/toolkit';

export type RequestErrorAction<T> = (state: StateApiObj<T>, action: PayloadAction<ApiError>) => void;
export type RequestFulfillAction<T> = (state: StateApiObj<T>, action: PayloadAction<T>) => void;
export type RequestInProgressAction<T> = (state: StateApiObj<T>, action: PayloadAction<boolean>) => void;

interface RequestReducers<T> {
  setRequestInProgress: RequestInProgressAction<T>;
  setRequestError: RequestErrorAction<T>;
  setRequestFulfilled: RequestFulfillAction<T>;
  setPostRequestError: RequestErrorAction<T>;
  setPostRequestInProgress: RequestInProgressAction<T>;
}

export const createDefaultApiError = (): ApiError => ({ isError: false, errorMsg: '' });

export const createRequestReducers = <T>(): RequestReducers<T> => ({
  setRequestInProgress(state, action): void {
    state.inProgress = action.payload;
    state.error = createDefaultApiError();
  },
  setRequestError(state, action): void {
    state.inProgress = false;
    state.error = action.payload;
  },
  setRequestFulfilled(state, action): void {
    state.inProgress = false;
    state.error = createDefaultApiError();
    state.data = action.payload;
  },
  setPostRequestError(state, action): void {
    state.postInProgress = false;
    state.postError = action.payload;
  },
  setPostRequestInProgress(state, action): void {
    state.postInProgress = action.payload;
    state.postError = createDefaultApiError();
  }
});

//this the default initial state that we will use for the all slices
export const createInitialRequestState = <T, P = never>(
  //needs to be one of the props of the StateApiObj
  props: Partial<StateApiObj<Partial<T>>>,
  //we can have any kind of props that we want(defined by type P)
  additionalProps: P,
  //injecting initial values for data
  initData: Partial<T>
): StateApiObj<T> & P => {
  //we can have properties only from StateApiObj
  const stateOb: StateApiObj<T> = {
    ...props,
    inProgress: false,
    error: { isError: false, errorMsg: '' },
    data: initData as T
  };

  //we can have any kind of properties
  const addedProps: P = {
    ...additionalProps
  };

  //we are glueing the two together
  return { ...stateOb, ...addedProps };
};
