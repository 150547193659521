/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import { toastUseStyles } from "../assets/systemHealth-styles";
import { useAppSelector, useAppDispatch } from "src/store/hooks";
import { setError } from "../store/errorSlice";

const Toast = (): ReactElement => {
	const classes = toastUseStyles()();
	const { t } = useTranslation();
	const error = useAppSelector((state) => state.error);
	const [showToast, setShowToast] = useState(false);
	const [title, setTitle] = useState("");
	const dispatch = useAppDispatch();

	// shows and hides the toast depending in active errors
	const handleVisibility = useCallback((): void => {
		setShowToast(true);
		setTimeout((): void => {
			setShowToast(false);
		}, 6000);
		setTimeout((): void => {
			dispatch(
				setError({ error: false, errorCode: "", errorMessage: undefined })
			);
			setTitle("");
		}, 8000);
	}, []);

	useEffect((): void => {
		// errorHandler(activeData());
		if (error.error) {
			handleVisibility();
		}
	}, [error]);

	return (
		<div
			data-testid='toast'
			className={`${clsx(classes.container)} ${
				showToast ? clsx(classes.visible) : ""
			}`}
		>
			<Typography className={classes.message}>
				{error.errorMessage
					? error.errorMessage
					: capitalize(`${t("dashboard.sync.error_info")} ${title}`)}
			</Typography>
		</div>
	);
};

export default Toast;
