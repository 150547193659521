import React from 'react';
import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';
import { Grid, Typography, Switch } from '@mui/material';
import { NamedSwitchProps } from '../../interfaces/components';

const useStyles = () : Function => makeStyles((theme : Theme) => ({
  root: {
    width: 38,
    height: 20,
    padding: 0,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: 34,
      height: 21,
      marginTop: '5px'
    }
  },
  switchBase: {
    padding: 2,
    color: theme.palette.primary.dark,
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.primary.dark,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.grey[500]
      }
    }
  },
  checked: {
    //transform: 'translateX(160px)',
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 11,
      height: 11
    }
  },
  track: {
    [theme.breakpoints.down('xs')]: {
      height: '15px'
    },
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.background.default
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  }
}));

const NamedSwitch: React.FunctionComponent<NamedSwitchProps> = ({
  labelOff,
  labelOn,
  handleChange,
  checked,
  ...props
}: NamedSwitchProps): React.ReactElement => {
  const classes = useStyles()();
  //const breakpoint500px = useMediaQuery('(max-width: 500px)');
  //const buttonSize = breakpoint500px ? 'small' : 'medium';

  return (
    <div {...props}>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item className={classes.label}>
            <Typography variant="body1" noWrap>
              {labelOff}
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
              }}
              onChange={handleChange}
              checked={checked}
            />
          </Grid>
          <Grid item className={classes.label}>
            <Typography variant="body1" noWrap>
              {labelOn}
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    </div>
  );
};

export default NamedSwitch;
