/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import PageMessage from '../features/sensor/components/UI/PageMessage';
import { useAuth0 } from '@auth0/auth0-react';
import Auth0LoginPage from 'src/features/Authentication/login/Auth0LoginPage';
import { jwtDecode } from "jwt-decode";
import clsx from 'clsx';
import { commonCss } from 'src/features/sensor/assets/css/commonCss';
import { setTokenIsExpiring } from 'src/features/TokenExpiration/store/token-expiration-slice';
import { useMemoryCache } from '../features/sensor/hooks/useMemoryCache';
import { setDevice, sensor } from '../features/sensor/features/Devices/store/device-slice';
import { timescale2ISOdates } from 'src/features/sensor/services/timeCalculations/timeScaleTranslation';
import { setTimeScalePeriod } from 'src/features/sensor/features/DataReload/store/time-scale-slice';
import { initiateStack } from 'src/features/sensor/features/DataReload/store/period-stack-slice';
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import { setDeviceList } from '../features/sensor/features/Devices/store/device-slice';
import { setAdmin, setCris, setIsDashboardUser, Roles, setCrisAdmin } from 'src/features/Authentication/store/auth-slice';
import CoverSpinner from 'src/features/sensor/components/UI/CoverSpinner';

const LoginContainer: React.FunctionComponent<{}> = ({ ...props }): React.ReactElement => {
  const [authDone, setAuthDone] = useState(false);
  const [authError, setAuthError] = useState(false);
  const { t } = useTranslation();
  const isDashboardUser = useAppSelector(state => state.auth0Slice.isDashboardUser)
  const commonClasses = commonCss()();

  const { isAuthenticated, getAccessTokenSilently, isLoading, logout,  } = useAuth0();

  const cache = useMemoryCache();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const defaultdevice = useAppSelector(state => state.deviceBase);
  const timeParams = timescale2ISOdates(defaultdevice.timePeriod);

  //clean all the tokens and logout
  const handleLogOut = () => {
    logout();
  };

  //Logout the user every 23h
  const expireToken = async () => {
    try {
      if (isAuthenticated) {

        dispatch(setTokenIsExpiring());
      }
    } catch (error) {
      console.error('Error renewing token:', error);
    }
  };
    
  // TODO: Check if we need to clear this interval inside the useEffect
  setInterval(expireToken, Number(process.env.REACT_APP_API_TOKEN_EXPIRING_HOURS!!) * 3600 * 1000);

  //TODO: For now we are assuming that the customer has at least one cybersensor connected
  //There is no device selection page
  const postAuth = useCallback(async () => {
    
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        scope: process.env.REACT_APP_AUTH0_SCOPE!,
      }
    });

    type DecodedToken = {
      userRole: string[];
      cybersensors: sensor[];
    }

    let device;
    let location;
    let country;
    let decoded: DecodedToken;

    if (accessToken) {

      decoded = jwtDecode(accessToken);

      const tokenToCache = { tokenType: 'Bearer', accessToken: accessToken };
      cache.put('token', tokenToCache);
      
      if (decoded.cybersensors && decoded.cybersensors.length > 0) {
        dispatch(setDeviceList(decoded.cybersensors));
        device = decoded.cybersensors[0].hostname;
        location = decoded.cybersensors[0].location;
        country = decoded.cybersensors[0].country;
      } else  if (!decoded.userRole.includes(Roles.PORTALCRIS)) {
        dispatch(setError({ error: true, errorMessage: "no devices found" }));
        setAuthError(true)
      }

      //Dispatching the user role from the token
      if (decoded.userRole) {
        dispatch(setAdmin({userRole : decoded.userRole}))
        dispatch(setCrisAdmin({userRole : decoded.userRole}))
        dispatch(setCris({userRole : decoded.userRole}))
        dispatch(setIsDashboardUser({userRole : decoded.userRole}))
      }
      
    } else {
      dispatch(setError({ error: true, errorMessage: 'login.error' }));
    }

    if (device && location && country) {
      dispatch(setDevice({ hostname: device, location: location, country: country }));
      dispatch(setTimeScalePeriod(timeParams));
      dispatch(initiateStack(timeParams));
      setAuthDone(true);

    } else {

      setAuthDone(true);
      setAuthError(true);
      
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      postAuth();
    } 

  }, [isAuthenticated]);

  
  if (!isAuthenticated && !isLoading) {
    // if user is not logged in, show login page
    return  (
      <Auth0LoginPage />
    );
  } 
    
  if (isLoading) {
    return <CoverSpinner type='full' size={100} />
  }
    
  if (isAuthenticated && authDone && !authError) {
    //user is logged and has a default device chosen
    return (
      <Route
        render={(innerProps: RouteProps): React.ReactElement => (
          <Redirect
            to={{
              pathname: (location.state as any)?.from?.pathname || isDashboardUser ? '/dashboard' : '/cris',
              state: { from: innerProps.location }
            }}
          />
      )}/>
    );
  }
      
  if (authDone && authError) {
    return (
      <>
        <PageMessage message={t('login.noDevices')} />
        <Button 
          className={clsx(commonClasses.fillerX, commonClasses.flexCentered)} 
          onClick={handleLogOut}>Logout
        </Button>
      </>
    );
  }
      
  return (
    <CoverSpinner type='full' size={100} />
  );
};

export default LoginContainer;

