import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import TablePagination from '@mui/material/TablePagination';
import { capitalize } from 'lodash';
import TablePaginationActions from '../../../../Pagination/components/TablePaginationActions';
import { commonCss } from '../../../../../assets/css/commonCss';
import { useSelectedDevice } from '../../../../../hooks/deviceHooks';
import { notWellDefined } from '../../../../../interfaces/common';
import { AttemptsFilterObj } from '../../../../../interfaces/components';
import { useAppSelector } from 'src/store/hooks';
import { Countrynames, mapData } from 'src/features/sensor/features/MapAttempts/map-utils/country-definitions';
import { attemptsByLocationUseStyles } from './attemptsByLocation-styles';

export interface AttemptsByLocationProps {
  clickHandlerRef: React.MutableRefObject<notWellDefined<(filter: AttemptsFilterObj) => void>>;
  disableOnMobile: boolean;
  mobileThreshold?: string;
  displacement: number;
}

const AttemptsByLocation: React.FunctionComponent<AttemptsByLocationProps> = ({
  clickHandlerRef,
  disableOnMobile,
  displacement
}: AttemptsByLocationProps) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 13;
  const classes = attemptsByLocationUseStyles(displacement, disableOnMobile)();
  const { t } = useTranslation();
  const commonClasses = commonCss()();

  const timeScalePeriod = useAppSelector(state => state.timePeriod);

  const device = useSelectedDevice();
  let country = '';
  if (device) {
    country = mapData[device.country as keyof Countrynames].name;
  }
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  useEffect(()=>{
    setPage(0)
  }, [timeScalePeriod])

  const countryList = useAppSelector(state => state.attempts.countries);
  const countries = [];
  if (countryList && Object.keys(countryList).length > 0) {  
    for (const key in countryList) {
      if (key as string === "N/A") {
        countries.push({
          id: key,
          name: "N/A",
          value: countryList[key]
        })
      } else {
        if(key in mapData){
          countries.push({
            id: key,
            name: mapData[key].name,
            value: countryList[key]
          })
        }
      } 
    }
    countries.sort((a, b) => b.value - a.value);
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      alignContent="center"
      className={clsx(classes.content)}
    >
      <Typography className={clsx(classes.header, commonClasses.textBold)} variant="h5">
        {country}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Typography variant={'body2'} className={commonClasses.textBold}>
                {capitalize(t('dashboard.core.country_plural'))}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant={'body2'} className={commonClasses.textBold}>
                {capitalize(t('dashboard.core.attack_plural'))}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {countries.length > 0 &&
            countries
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((dataItem) => (
                <TableRow
                  key={dataItem.id}
                  className={clsx(classes.tableRow)}
                  onClick={(): void => {
                    clickHandlerRef &&
                      clickHandlerRef.current &&
                      clickHandlerRef.current({ name: 'country', filterValue: dataItem.id });
                  }}
                >
                  <TableCell className={clsx(classes.tableCell)}>
                    <Typography variant={'body2'}>{dataItem.name}</Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.tableCell)}>
                    <Typography variant={'body2'}>{dataItem.value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={countries?.length || 0}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={handleChangePage}
        classes={{
          root: classes.pagination,
          toolbar: classes.paginationToolbar,
          spacer: classes.paginationSpacer
        }}
        ActionsComponent={TablePaginationActions}
      />
    </Grid>
  );
};

export default AttemptsByLocation;
