import React from 'react';
import { 
  useGridApiContext, 
  useGridSelector, 
  gridPageSelector, 
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination';
// import { useAppSelector } from 'src/store/hooks';
import { customPaginationStyles } from './custom-pagination-styles'; 


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const classes = customPaginationStyles()()

  const handleOnPageChange = (value: number, ev?: React.ChangeEvent<unknown>) => {
    apiRef.current.setPage(value);
  } 

  return (
    <Stack sx={{
      spacing: 3,
    }}>
      <Pagination
        className={classes.root}
        count={pageCount} 
        page={page + 1} 
        onChange={(event, value) => handleOnPageChange( value - 1)} />
    </Stack>
  )
}

export default CustomPagination;