import { createSlice } from "@reduxjs/toolkit";
import { attApiSlice, CountryAttemptApi } from "./attempts-api-slice";


const initialState: CountryAttemptApi = {
  period: {
    to: "",
    from: ""
  },
  countries: {}
}

const attemptSlice = createSlice({
  name: 'attempt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      attApiSlice.endpoints.fetchAttempts.matchFulfilled,
      (state, { payload }) => {
        state.period = payload.period
        state.countries = payload.countries
      }
    )
  }
})

export default attemptSlice.reducer;
