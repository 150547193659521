import  makeStyles  from '@mui/styles/makeStyles';
import {ITheme} from "../../../../themes/ITheme";

export const defaultBorderWidth = 1;

export const commonCss = (): Function => makeStyles((theme: ITheme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary
    }
  },
  autocompleteReset: {
    width: 0,
    minWidth: '30px'
  },
  stretcherY: {
    height: '100vh'
  },
  stretcherX: {
    width: '100vw'
  },
  fillerY: {
    height: '100%'
  },
  fillerX: {
    width: '100%',
    padding: '0',
  },
  centerText: {
    textAlign: 'center'
  },
  relative: {
    position: 'relative'
  },
  noPadding: {
    padding: '0'
  },
  zeroWidth: {
    width: 0
  },
  opacityReset: {
    opacity: '1'
  },
  marginReset: {
    margin: 0
  },
  hide: {
    display: 'none !important'
  },
  defaultBorder: {
    border: `${defaultBorderWidth}px solid ${theme.palette.grey[800]}`
  },
  defaultBorderRadius: {
    borderRadius: theme.shape.borderRadius
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    },
    backgroundColor: theme.palette.grey[100]
  },
  absolutelyCentered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  flexCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  'container--reset-minWidth': {
    minWidth: '0px'
  },
  'container__icon--add-margin': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button: {
    height: '36px',
    backgroundColor: 'theme.palette.primary.main'
  },
  menuButton: {
    color: theme.palette.secondary.light,
    border: '1px solid white'
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px'
  },
  textColorDark: {
    color: theme.palette.primary.dark
  },
  contrastText: {
    color: theme.palette.primary.contrastText
  },
  linkColorDark: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  textBold: {
    fontWeight: `600 !important` as any
  },
  toolbarButton: {
    '& button': {
      border: `1px solid ${theme.palette.primary.contrastText}`,
      height: '36px',
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.background.default
    }
  },
  padding: {
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3)
  }
}));
