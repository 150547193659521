import React from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import DataContainer from '../components/dashboard/DataContainer';
import DataReload from '../features/DataReload/components/DataReload';
import { statsContainerUseStyles } from './pages-styles';

const StatsContainer: React.FunctionComponent<{}> = (props): React.ReactElement => {
  const classes = statsContainerUseStyles()();

  return (
    <Grid
      container
      sx={{ margin: 0 }}
      // direction="row"
      // alignItems="stretch"
      spacing={2}
      className={clsx(classes.container, classes.gridContainer)}
    >
      <Grid sx={{ padding: 0, width: '94%' }} >
        <DataContainer modalLeftDisplacement={100} />
      </Grid>
      <DataReload active={true} interval={parseInt(process.env.REACT_APP_METRIC_RELOAD_INTERVAL || '60000', 10)} />
    </Grid>
  );
};

export default StatsContainer;
