import React, { useState } from 'react';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';
import DevicesIcon from '@mui/icons-material/Devices';
import { TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';
import { DeviceApiObj } from 'src/store/features/user/devicesSlice';

const useStyles = () : Function => makeStyles((theme : Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  front: {
    'z-index': theme.zIndex.modal
  },
  inputReset: {
    color: theme.palette.text.primary
  },
  buttonIcon: {
    marginRight: '4px',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  buttonLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  header: {
    color: theme.palette.text.primary,
  }
}));

type PopupSelectorProps = {
  buttonLabel: string;
  dialogLabel: string;
  textLabel: string;
  handleSubmit: Function;
  style?: {};
  className?: string;
  options: DeviceApiObj[];
};

//wrapper component for Material autocomplete, takes labels, list of available options and callback as props
const PopupSelector: React.FunctionComponent<PopupSelectorProps> = ({
  buttonLabel,
  dialogLabel,
  textLabel,
  handleSubmit,
  options,
  ...props
}: PopupSelectorProps): React.ReactElement => {
  const classes = useStyles()();
  const [open, setOpen] = useState(false);
  const sensor = useAppSelector((state => state.deviceBase.selectedDevice));
  const { t } = useTranslation();

  const handleClickOpen = (event: React.MouseEvent): void => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    event.preventDefault();
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setOpen(false);
  };

  const onChangeHandler = (ev: React.SyntheticEvent, value: DeviceApiObj | null) => {
    if(value) handleSubmit(value);
    setOpen(false);
  }

  return (
    <div className={props.className}>
      <Button onClick={handleClickOpen} color="primary" variant="outlined">
        <DevicesIcon className={classes.buttonIcon} />
        <Typography className={classes.buttonLabel}>{buttonLabel}</Typography>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogLabel}</DialogTitle>
        <DialogContent>
          <Autocomplete
            sx={{ margin: '0.5rem' }}
            classes={
              { listbox: classes.header, input: classes.header, popper: classes.front } as Record<
                string,
                string
              >
            }
            options={options}
            value={sensor}
            getOptionLabel={(option) => option.name? option.name : ''}
            isOptionEqualToValue={(option, value) => {
              if(value.name === '') return true; else return option.name === value.name
            }}
            groupBy={(option) => option.location}
            onChange={onChangeHandler}
            renderInput={(innerProps: TextFieldProps): React.ReactElement => (
              <TextField {...innerProps} label={textLabel} variant="outlined" fullWidth />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('shared.actions.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopupSelector;
