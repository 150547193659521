import { createSlice } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../../../../../../store/features/common/common';

export interface ReportsApiObj {
  name: string;
  date: string;
  url: string;
}

const initialState = createInitialRequestState<ReportsApiObj[], {}>({}, {}, []);

const commonRequestReducers = createRequestReducers<ReportsApiObj[]>();

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    reportsSetRequestInProgress: commonRequestReducers.setRequestInProgress,
    reportsSetRequestError: commonRequestReducers.setRequestError,
    reportsSetRequestFulfilled: commonRequestReducers.setRequestFulfilled
  }
});

export const { reportsSetRequestInProgress, reportsSetRequestError, reportsSetRequestFulfilled } =
  reportsSlice.actions;

export default reportsSlice.reducer;

