import React, { useCallback } from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import PaginationNumbers from '../../../components/UI/PaginationNumbers';
import { paginationUseStyles } from './table_pagination-styles';

interface ExtendedTablePaginationActionsProps extends TablePaginationActionsProps {
  showArrowsText?: boolean;
}

const TablePaginationActions: React.FunctionComponent<ExtendedTablePaginationActionsProps> = (
  props: ExtendedTablePaginationActionsProps
) => {
  const classes = paginationUseStyles()();
  const { count, page, rowsPerPage, onPageChange, showArrowsText = false } = props;

  const handleBackButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      onPageChange(event, page - 1);
    },
    [onPageChange, page]
  );

  const handleNextButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      onPageChange(event, page + 1);
    },
    [onPageChange, page]
  );

  return (
    <div className={clsx(classes.container)}>
      <IconButton className={clsx(classes.links)} onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
        {showArrowsText && <span>Previous {rowsPerPage}</span>}
      </IconButton>
      <div className={clsx(classes.pageLinksContainer)}>
        <PaginationNumbers count={count} rowsPerPage={rowsPerPage} page={page} onChangePage={onPageChange} />
      </div>
      <IconButton
        className={clsx(classes.links)}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        {showArrowsText && <span>Next {rowsPerPage}</span>}
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;
