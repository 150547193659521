import React, { useCallback } from 'react';
import TimeScaleSelector from './TimeScaleSelector';
import { Timescale } from '../../../interfaces/services';
import { setTimePeriod } from '../../Devices/store/device-slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { timescale2ISOdates } from 'src/features/sensor/services/timeCalculations/timeScaleTranslation';
import { setTimeScalePeriod } from 'src/features/sensor/features/DataReload/store/time-scale-slice';
import {initiateStack } from 'src/features/sensor/features/DataReload/store/period-stack-slice'

export interface TimescaleContainerProps {
  label: string;
  dialogLabel: string;
  customButtonLabel: string;
  ampm?: boolean;
  dateFormat?: string;
}

const TimescaleContainer: React.FunctionComponent<TimescaleContainerProps> = (props): React.ReactElement => {
  const time = useAppSelector(state => state.deviceBase.timePeriod);
  const dispatch = useAppDispatch();

  const handleTimeScaleChange = useCallback(
    (timescale: Timescale): void => {
      if (timescale) {
        const timeParams = timescale2ISOdates(timescale);
        dispatch(setTimePeriod(timescale));
        dispatch(setTimeScalePeriod(timeParams));
        timeParams.res = timescale.value
        dispatch(initiateStack(timeParams));
      }
    },
    [dispatch]
  );

  return (
    <TimeScaleSelector
      {...props}
      handleChange={handleTimeScaleChange}
      timePeriod={{ value: time?.value || 'h' }}
    />
  );
};

export default TimescaleContainer;
