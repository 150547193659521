import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Theme, ThemeOptions } from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette';
import theme from 'src/themes/defaults/light/theme'
import { createTheme } from '@mui/material/styles';
import { ApiError, StateApiObj } from '../../../features/sensor/interfaces/services';
import { AppThunk } from '../../store';
import { createDefaultTheme, createViewSettings, updateThemePalette } from '../../../themes/themeApi';
import {
  ChartTimeAggregats,
  setCustomSettings,
  ChartTimeMetricsAggregats,
  setMapColors,
  setMetricsChartColors,
  setAttemptsChartColors
} from './customViewSettingsSlice';

export type InitThemeInfo = Omit<StateApiObj<{}>, 'data' | 'inbound' | 'outbound'>;

export interface ThemeState {
  theme: any;
  init: InitThemeInfo;
  chartTimeAggregats: ChartTimeAggregats;
  chartTimeMetricsAggregats: ChartTimeMetricsAggregats;
}

//ToDo refactor and describe
//used for grouping by time to round up date to nearest value
//check services/timeCalcumations for more
export const createDefaultChartTimeValues = (): ChartTimeAggregats => ({
  hourValue: 60,
  dayValue: 10,
  weekValue: 1,
  monthValue: 1,
  threeMonthsValue: 1,
  sixMonthsValue: 1,
  yearValue: 1,
  customValue: {
    timeUnit: 'hour',
    count: 1
  }
});

export const createDefaultChartMetricsTimeValues = (): ChartTimeMetricsAggregats => ({
  hourValue: 60,
  dayValue: 10,
  weekValue: 1,
  monthValue: 1,
  threeMonthsValue: 1,
  sixMonthsValue: 1,
  yearValue: 1,
  customValue: {
    timeUnit: 'hour',
    count: 1
  }
});

const initialState: ThemeState = {
  theme: createTheme(theme),
  init: {
    error: { isError: false, errorMsg: '' },
    inProgress: false,
    done: false
  },
  chartTimeAggregats: createDefaultChartTimeValues(),
  chartTimeMetricsAggregats: createDefaultChartMetricsTimeValues()
};

export type ThemeType = 'light' | 'dark';

const themeSettings = createSlice({
  name: 'themeSettings',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<ThemeOptions>): void {
      state.theme = action.payload;
    },
    setInitThemeInProgress(state, action: PayloadAction<boolean>): void {
      state.init.inProgress = action.payload;
      state.init.error = { isError: false, errorMsg: '' };
    },
    setInitThemeDone(state, action: PayloadAction<boolean>): void {
      state.init.done = action.payload;
    },
    setThemeError(state, action: PayloadAction<ApiError>): void {
      state.init.inProgress = false;
      state.init.done = true;
      state.init.error = action.payload;
    },
    setLoadingComplete(state, action: PayloadAction<ThemeOptions>): void {
      state.theme = action.payload;
      state.init.inProgress = false;
      state.init.done = true;
    },
    setChartTimeAggregats(state, action: PayloadAction<any>): void {
      state.chartTimeAggregats = action.payload;
    },
    setChartTimeMetricsAggregats(state, action: PayloadAction<any>): void {
      state.chartTimeMetricsAggregats = action.payload;
    }
  }
});

export const {
  setTheme,
  setInitThemeInProgress,
  setInitThemeDone,
  setThemeError,
  setLoadingComplete,
  setChartTimeAggregats,
  setChartTimeMetricsAggregats
} = themeSettings.actions;

export default themeSettings.reducer;

// initiating the theme for the app
export const initTheme =
  (themeName: ThemeType): AppThunk =>
  async (dispatch): Promise<void> => {
    // console.log('initTheme');
    dispatch(setInitThemeInProgress(true));
    try {
      // crate and save theme, config, timeAggregates, set done param to true
      const theme = await createDefaultTheme(themeName);
      const customSettings = await createViewSettings(themeName);
      dispatch(setCustomSettings(customSettings));
      //TODO load all custom settings via 1 dispatch, this is suboptimal
      dispatch(setMapColors(customSettings.dashboard.map.colors)); //only this works so far
      dispatch(setMetricsChartColors(customSettings.dashboard.metricsChart.colors));
      dispatch(setAttemptsChartColors(customSettings.dashboard.attemptsChart.colors));

      dispatch(setChartTimeAggregats(customSettings.dashboard.attemptsChart.timeAggregats));
      dispatch(setChartTimeMetricsAggregats(customSettings.dashboard.metricsChart.timeAggregatsMetrics));
      dispatch(setLoadingComplete(theme));
    } catch (e) {
      const initThemeError: Error = e as Error;
      dispatch(setThemeError({ isError: true, errorMsg: initThemeError.message }));
    }
  };

// changing the colors of the map and graphs
export const changeTheme =
  (theme: Theme, pallete: PaletteColor): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setTheme(updateThemePalette(theme, pallete)));
    } catch (e) {
      const initThemeError = e as Error;
      dispatch(setThemeError({ isError: true, errorMsg: initThemeError.message }));
    }
  };

// swapping theme between light and dark
export const swapTheme =
  (themeName: ThemeType): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const theme = await createDefaultTheme(themeName);
      const customSettings = await createViewSettings(themeName);

      dispatch(setTheme(theme));

      // map colors
      dispatch(setMapColors(customSettings.dashboard.map.colors)); // workaround: TODO fix map color switch

      //chart colors
      dispatch(setAttemptsChartColors(customSettings.dashboard.attemptsChart.colors));
      dispatch(setMetricsChartColors(customSettings.dashboard.metricsChart.colors));
    } catch (e) {
      const initThemeError = e as Error;
      dispatch(setThemeError({ isError: true, errorMsg: initThemeError.message }));
    }
  };
