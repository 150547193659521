
import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
// import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { capitalize } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableData } from 'src/features/sensor/features/DataGrid/components/IpDataGrid';
import { useAppSelector } from 'src/store/hooks';
import { ipListStyles, listPaginationStyles } from 'src/features/sensor/features/DataGrid/assets/ipList-styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination';
import { toUpper } from 'lodash';

interface IpAttemptsListProps {
  cardData: TableData[];
  total: number;
  handlePageChange: (page: number) => void;
}


const IpAttemptsList: React.FC<IpAttemptsListProps> = ({cardData, handlePageChange, total}) => {

  const themeSelected = useAppSelector(state => state.themeSettings.theme);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const classes = ipListStyles()(themeSelected);
  const rowsPerPage = parseInt(process.env.REACT_APP_IP_ROWS_PER_PAGE!);
  const pageCount = Math.ceil(total/rowsPerPage);
  const listStyles = listPaginationStyles(themeSelected);
  

  const handleOnPageChange = (ev: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    handlePageChange(value);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {cardData.length > 0 ? cardData.map((card) => (
          <div 
            key={card.id} >
            <ListItem alignItems="flex-start">
            <ListItemText
              primaryTypographyProps={{ fontSize: '1rem', fontWeight: '600' }}
              primary={capitalize(t('shared.date')) + ' ' + card.date}
              secondary={
                <span className={classes.listContainer}>
                  <Typography
                    sx={{ fontWeight: '600' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {capitalize(t('shared.time')) + ': ' + card.time}
                  </Typography>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {`${capitalize(t('dashboard.core.threat'))} ${toUpper(t('dashboard.abbreviations.ip'))}: ${card.source}`}
                  </Typography>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {`${capitalize(t('dashboard.core.target'))} ${toUpper(t('dashboard.abbreviations.ip'))}: ${card.destination}`}
                  </Typography>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {capitalize(t('dashboard.core.source_port')) + ': ' + card.sourcePort}
                  </Typography>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {capitalize(t('dashboard.core.destination_port')) + ': ' + card.destinationPort}
                  </Typography>
                  <Typography
                    // sx={{ textTransform: 'lowercase' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    
                  >
                    {t('dashboard.core.protocol')}: <span style={{ textTransform: 'lowercase' }}>{card.protocol}</span>
                  </Typography>
                  <Typography
                    // sx={{ fontWeight: '600' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {capitalize(t('dashboard.core.country')) + ': ' + card.country}
                  </Typography>
                </span>
              }
            />
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
          )) : 'No data'}
      </List>
      <Stack sx={{ width: '100%' }}>
        <Pagination
          sx={listStyles}
          count={pageCount} 
          page={page + 1} 
          onChange={(ev, value) => handleOnPageChange(ev, value - 1)} />
      </Stack>
    </Box>
  )
}

export default IpAttemptsList;
