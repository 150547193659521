import React from "react";
import "./App.css";
// @ts-ignore
import { FlagsProvider } from "react-feature-flags";
import Preloader from "./components/Preloader";
import { useCustomTheme } from "./features/sensor/hooks/viewHooks";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

function App(): React.ReactElement {

	const flags = [
		{ name: "themeSettings", isActive: false },
		{ name: "outboundAttempts", isActive: false },
	];

	const theme = useCustomTheme();

	return (
		<FlagsProvider value={flags}>
			<ThemeProvider theme = {theme} > 
				<StyledEngineProvider injectFirst>
					<Preloader />
				</StyledEngineProvider>
			</ThemeProvider>
		</FlagsProvider>
	);
}

export default App;
