import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';
// import colorCodes from "src/themes/defaults/colorCodes";


export const baseSerialChartStyles = () : Function => makeStyles((theme : Theme) => ({
  circle: {
    width: "30px",
    height: "30px",
    background:  "#007C88",
    borderRadius: '50%',
    cursor: 'pointer',
    top: '50px',
    right: '50px',
    zIndex: 1,
    position: 'absolute'
  },
  line: {
    alignContent: 'center',
    width: "12px",
    height: '15px',
    borderBottom: '2px solid white',
    margin: 'auto',
    position: 'relative',
    cursor: 'pointer'
  }

}));
