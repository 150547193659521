import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timescale } from 'src/features/sensor/interfaces/services';
import { DeviceApiObj } from 'src/store/features/user/devicesSlice';

export interface sensor{
  hostname: string;
  name: string;
  location: string;
  type: string;
  country: string;
}
export interface DeviceBaseState {
  loadInProgress: boolean;
  selectedDevice: DeviceApiObj | null;
  dataReloadingActive: boolean;
  timestamp: number;
  timePeriod: Timescale;
  isSpinnerActive: boolean;
  deviceList: DeviceApiObj[]
}

const initialState: DeviceBaseState = {
  loadInProgress: false,
  selectedDevice: null,
  dataReloadingActive: false,
  timestamp: Date.now(),
  timePeriod: { value: 'h' },
  isSpinnerActive: false,
  deviceList: []
};

const deviceSlice = createSlice({
  name: 'deviceSlice',
  initialState,
  reducers: {
    setDevice(state, action: PayloadAction<DeviceApiObj>) {
        state.selectedDevice = action.payload;
    },
    setTimePeriod(state, action: PayloadAction<Timescale>) {
      state.timePeriod = action.payload;
    },
    setDataReload(state, action: PayloadAction<boolean>) {
      state.dataReloadingActive = action.payload;
    },
    setDeviceList(state, action: PayloadAction<sensor[]>){
      state.deviceList = action.payload
    }
  }
});

export const { setDevice, setTimePeriod, setDataReload, setDeviceList } = deviceSlice.actions;
export default deviceSlice.reducer;
