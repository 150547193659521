import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import cache from "../../../../../services/memoryCache/cache";

export interface SystemStatsAPI {
  inboundAttempts: number;
  outboundAttempts: number;
  totalAttackers: number;
  totalAttempts: number;
  totalThreatList: number;
}

export const statsApiSlice = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_STATS as string,
    prepareHeaders(headers) {
      const token = cache.get('token');
      headers.set('Authorization', `${token.tokenType} ${token.accessToken}`);
      
      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchSystemStats: builder.query<SystemStatsAPI, string | void>({
        query(queryParams) {
          return `${queryParams}`;
        },
        keepUnusedDataFor: 20,
      })
    }
  }
});

export const { useFetchSystemStatsQuery } = statsApiSlice;