/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useCallback, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { capitalize, Typography, useMediaQuery } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { toUpper } from 'lodash';
import { useTranslation } from 'react-i18next';
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import Tooltip from '../../../../components/UI/Tooltip';
import { commonCss } from '../../../../assets/css/commonCss';
import {
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
  SECONDS_PER_MONTH,
  SECONDS_PER_WEEK,
  SECONDS_PER_YEAR
} from '../../../../types/units';
import { MetricsApiObj } from '../../store/metrics-api-slice';
import { getLatestData } from '../../utils/chartDescriptorUtils'
import { useAppSelector, useAppDispatch } from '../../../../../../store/hooks'
import { getChartResolution } from '../../../ChartIP/chart-utils/chart-utils'
import { useFetchMetricsQuery } from '../../store/metrics-api-slice'
import { chartDescriptorUseStyles } from './chartDescriptor-styles';

interface MetricsTableData {
  title: string;
  value: any;
}

const SystemInfoChartDescriptor: FunctionComponent = () => {
	const deviceData = useAppSelector(state => state.deviceBase);
  const timeScale = useAppSelector(state => state.timePeriod);
  const dispatch = useAppDispatch();
  const resolution = getChartResolution(deviceData.timePeriod.value);
  const queryParams = `?from=${timeScale.from}&to=${timeScale.to}&hostname=${deviceData.selectedDevice?.hostname}&resolution=${resolution}`;
  const status = useAppSelector(state => state.HealthStatus)
	const { data, error} = useFetchMetricsQuery(queryParams);

  useEffect(() => {
		if(error){
			// const processedMessage = processMessage(error)
			dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.healthChart.error'))}))
		}
	}, [error]);

  let latestData : MetricsApiObj = useMemo(() => {
    return {
      activeMemory: 0,
      currentFirewallStates: 0,
      diskFree: "",
      load_1: 0,
      uptime: 0,
      tlfVersion: "",
      time: "",
      latestUpdate: "",
      freeMemory: 0,
      activationDate : "",
    }
  }, []);

  if (data && data.length > 0) {
    latestData = getLatestData(data);
  }

  let formatedDate = "";
  if(status) {
    let date = new Date(status.activationDate)
    formatedDate = date.getFullYear().toString() + "-" +date.getMonth().toString() + "-" + date.getDay().toString();
  }

  const classes = chartDescriptorUseStyles()();
  const { t } = useTranslation();
  const commonClasses = commonCss()();
  const mobileBreakpoint = useMediaQuery(`(max-width: 900px)`);
  const middleMobileBreakpoint = useMediaQuery(`(max-width: 750px)`);



  // converts time from seconds to eye-friendly unit + translates the output to eng / nl
  const convertTime = useCallback(
    (timeInSeconds: number): string => {
      const errorCondition: boolean = timeInSeconds < 0;
      const secondsOneCondition: boolean = timeInSeconds > 0 && timeInSeconds < 2;
      const secondsCondition: boolean = timeInSeconds >= 2 && timeInSeconds < SECONDS_PER_MINUTE;
      const minutesOneCondition: boolean =
        timeInSeconds >= SECONDS_PER_MINUTE && timeInSeconds < SECONDS_PER_MINUTE * 2;
      const minutesCondition: boolean =
        timeInSeconds >= SECONDS_PER_MINUTE * 2 && timeInSeconds < SECONDS_PER_HOUR;
      const hoursOneCondition: boolean =
        timeInSeconds >= SECONDS_PER_HOUR && timeInSeconds < SECONDS_PER_HOUR * 2;
      const hoursCondition: boolean =
        timeInSeconds >= SECONDS_PER_HOUR * 2 && timeInSeconds < SECONDS_PER_DAY;
      const daysOneCondition: boolean =
        timeInSeconds >= SECONDS_PER_DAY && timeInSeconds < SECONDS_PER_DAY * 2;
      const daysCondition: boolean = timeInSeconds >= SECONDS_PER_DAY * 2 && timeInSeconds < SECONDS_PER_WEEK;
      const weeksOneCondition: boolean =
        timeInSeconds >= SECONDS_PER_WEEK && timeInSeconds < SECONDS_PER_WEEK * 2;
      const weeksCondition: boolean =
        timeInSeconds >= SECONDS_PER_WEEK * 2 && timeInSeconds < SECONDS_PER_MONTH;
      const monthsOneCondition: boolean =
        timeInSeconds >= SECONDS_PER_MONTH && timeInSeconds < SECONDS_PER_MONTH * 2;
      const monthsCondition: boolean =
        timeInSeconds >= SECONDS_PER_MONTH * 2 && timeInSeconds < SECONDS_PER_YEAR;
      const yearsOneCondition: boolean =
        timeInSeconds >= SECONDS_PER_YEAR && timeInSeconds < SECONDS_PER_YEAR * 2;
      const yearsCondition: boolean = timeInSeconds >= SECONDS_PER_YEAR * 2;

      const calculateTime = (timeInSeconds: number, divider: number): number =>
        Math.floor(timeInSeconds / divider);

      if (secondsOneCondition) {
        return `${timeInSeconds} ${t('dashboard.healthChart.timePeriods.second')}`;
      } else if (secondsCondition) {
        return `${timeInSeconds} ${t('dashboard.healthChart.timePeriods.seconds')}`;
      } else if (minutesOneCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_MINUTE)} ${t(
          'dashboard.healthChart.timePeriods.minute'
        )}`;
      } else if (minutesCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_MINUTE)} ${t(
          'dashboard.healthChart.timePeriods.minutes'
        )}`;
      } else if (hoursOneCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_HOUR)} ${t(
          'dashboard.healthChart.timePeriods.hour'
        )}`;
      } else if (hoursCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_HOUR)} ${t(
          'dashboard.healthChart.timePeriods.hours'
        )}`;
      } else if (daysOneCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_DAY)} ${t(
          'dashboard.healthChart.timePeriods.day'
        )}`;
      } else if (daysCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_DAY)} ${t(
          'dashboard.healthChart.timePeriods.days'
        )}`;
      } else if (weeksOneCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_WEEK)} ${t(
          'dashboard.healthChart.timePeriods.week'
        )}`;
      } else if (weeksCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_WEEK)} ${t(
          'dashboard.healthChart.timePeriods.weeks'
        )}`;
      } else if (monthsOneCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_MONTH)} ${t(
          'dashboard.healthChart.timePeriods.month'
        )}`;
      } else if (monthsCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_MONTH)} ${t(
          'dashboard.healthChart.timePeriods.months'
        )}`;
      } else if (yearsOneCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_YEAR)} ${t(
          'dashboard.healthChart.timePeriods.year'
        )}`;
      } else if (yearsCondition) {
        return `${calculateTime(timeInSeconds, SECONDS_PER_YEAR)} ${t(
          'dashboard.healthChart.timePeriods.years'
        )}`;
      } else if (errorCondition) {
        return toUpper(t('shared.na'));
      } else {
        return `${timeInSeconds} ${t('dashboard.healthChart.timePeriods.seconds')}`;
      }
    },
    [t]
  );

  // template of data row - actually data column - title + value
  const dataRowMemo = (title: string, value: string, idx: number) => (
    <div
      key={title}
      className={clsx(
        middleMobileBreakpoint ? classes.mobileCellDistance : classes.normalCellDistance,
        classes.dataRow,
        idx === dataToGenerate.length - 1 && classes.dataRowLastCell
      )}
    >
        <Typography
        component="h3"
        variant={mobileBreakpoint ? 'body1' : 'subtitle1'}
        className={clsx(commonClasses.textBold, classes.dataCell, classes.mobileCellDivider)}
        >
        {title}
        </Typography>
        <Typography
        variant={'body2'}
        className={clsx(commonClasses.textColorDark, commonClasses.textBold, )}
        >
          {value}
        </Typography>
    </div>
  );

  // converts memory from kB to MB /GB
  const convertMemory = useCallback((bytes: number): string => {
    if (bytes >= 10000) {
      const mBytes: number = bytes / 1000000;
      if (mBytes >= 1000) {
        const gBytes: number = mBytes / 1000;
        return `${gBytes.toFixed(2)} GB`;
      } else {
        return `${mBytes.toFixed(2)} MB`;
      }
    } else {
      return `${bytes} B`;
    }
  }, []);

  // data to be injected to template
  const dataToGenerate: MetricsTableData[] = useMemo(
    () => [
      {
        title: capitalize(t('dashboard.healthChart.widget.active')),
        value: latestData?.activeMemory ? convertMemory(latestData.activeMemory) : toUpper(t('shared.na'))
      },
      {
        title: capitalize(t('dashboard.healthChart.widget.free_memory')),
        value: latestData?.freeMemory ? convertMemory(latestData.freeMemory) : toUpper(t('shared.na'))
      },
      {
        title: capitalize(t('dashboard.healthChart.widget.disk')),
        value: latestData?.diskFree ? `${latestData.diskFree}%` : toUpper(t('shared.na'))
      },
      {
        title: capitalize(t('dashboard.healthChart.widget.load')),
        value: latestData?.load_1 ? `${latestData.load_1.toFixed(2)}` : toUpper(t('shared.na'))
      },
      {
        title: capitalize(t('dashboard.healthChart.widget.uptime')),
        value: latestData?.uptime ? convertTime(latestData.uptime) : toUpper(t('shared.na'))
      },
      {
        title: capitalize(t('dashboard.healthChart.widget.latestUpdate')),
        value: latestData?.latestUpdate ? latestData.latestUpdate : toUpper(t('shared.na'))
      },
      {
        title: capitalize(t('dashboard.healthChart.widget.tlfVersion')),
        value: latestData?.tlfVersion ? latestData.tlfVersion : toUpper(t('shared.na'))
      },
      {
        title: capitalize(t('dashboard.healthChart.widget.activationDate')),
        value: formatedDate !== "" ? formatedDate: toUpper(t('shared.na'))
      }
    ],
    [latestData, convertMemory, convertTime, formatedDate, t]
  );

  return (
    <div className={classes.container}>
      <div className={clsx(classes.headerContainer)}>
        <div className={clsx(classes.imageWrapper)}>
          <SettingsIcon />
        </div>
        <Typography
          component="h3"
          variant={mobileBreakpoint ? 'body1' : 'h5'}
          className={clsx(commonClasses.textColorDark, classes.header)}
        >
          {toUpper(t('dashboard.healthChart.widget.title'))}
        </Typography>
        <Tooltip
          positionClassName={classes.tooltip}
          size={'SMALL'}
          text={t('shared.tooltips.health_chart_table')}
          placement={'bottom'}
        />
      </div>
      <div className={classes.dataWrapper}>
        {dataToGenerate.map(
          (dataItem: MetricsTableData, idx: number): React.ReactElement =>
            dataRowMemo(dataItem.title, dataItem.value, idx)
        )}
      </div>
    </div>
  );
};

export default React.memo(SystemInfoChartDescriptor);
