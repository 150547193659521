import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from 'src/store/hooks';
import { Button } from '@mui/material';


const TokenExpirationModal: React.FC = () => {

  const tokenExpired = useAppSelector(state => state.tokenExpiration.tokenIsExpiring);

  React.useEffect(() => {
      //open the modal
      setOpen(true)
    }, [tokenExpired])

    const { logout,  } = useAuth0();
    const [open, setOpen] = React.useState(false);
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        '&:focus-visible': {
          // Add your styles for focus-visible state here
          outline: 'none', // Example outline style
        }
      };

      const handleClose = () => {
        tokenExpired && logout();
      }

    return (
      tokenExpired &&
        <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="unstyled-modal-description"
        >
        <Box sx={style}>
            <Typography id="unstyled-modal-description" sx={{ mt: 2 }}>
            Your token is about to expire, please log in again.
            </Typography>
            <Button onClick={handleClose}>Ok</Button>
        </Box>
        </Modal>
    );
};

export default TokenExpirationModal;