import React from 'react';
import { Paper, Typography } from '@mui/material';
import { HeaderContainerProps } from '../interfaces/components';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';

const useStyles = (headerHeight: string, blockHeader: boolean): Function =>
  makeStyles((theme : Theme) => ({
    wrapper: {
      width: '100%',
      border: 'none',
      padding: 0,
      margin: 0,
      overflowY: 'auto',
    },
    header: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      position: blockHeader ? 'static' : 'absolute',
      zIndex: 0
    },
    container: {
      position: 'relative',
      marginBottom: theme.spacing(1)
    },
    headerText: {
      color: theme.palette.secondary.contrastText,
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: theme.spacing(2)
    },
    borderRadiusReset: {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    }
  }));

const HeaderContainer: React.FunctionComponent<HeaderContainerProps> = ({
  headerHeight,
  headerText,
  content,
  blockHeader = true,
  ...props
}: HeaderContainerProps) => {
  const classes = useStyles(headerHeight, blockHeader)();

  return (
    <Paper
      classes={{ rounded: classes.borderRadiusReset }}
      className={clsx(props.className, classes.container)}
    >
      <div className={clsx(classes.header, classes.wrapper)} style={{ height: headerHeight }}>
        <Typography component="h2" variant="h6" className={clsx(classes.headerText)}>
          {headerText}
        </Typography>
      </div>
      <div className={classes.wrapper}>{content}</div>
    </Paper>
  );
};

export default HeaderContainer;
