import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';
import { ITheme } from '../../../../../../../../themes/ITheme';

export const mapDescriptionUseStyles = (): Function =>
makeStyles((theme: ITheme) => ({
  wrapper: {
    justifyContent: 'center',
    [theme.breakpoints.up(1200)]: {
      width: 'unset',
      flexGrow: 3,
    }
  },
  container: {
    height: '100%',
    padding: theme.spacing(2)
  },
  gridContainer: {
    display: 'flex',
    width: 'fit-Content',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      margin: `${theme.spacing(1)} ${theme.spacing(1)}`
    }
  },
  gridItemWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },
  textContainer: {
    display: 'flex',
    color: theme.palette.primary.main,
    width: '110%',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
  },
  textWidthContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '10%',
    },
    width: '110%',
    alignItems: 'center'
  },
  attackTypesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: 0,
      height: 50
    }
  },
  // big numbers
  dataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: '1rem',
    [theme.breakpoints.up(600)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up(1900)]: {
      fontSize: '2rem',
    },
  },
  divider: {
    borderTop: `5px solid ${theme.palette.primary.main}`
  },
  tooltip: {
    marginLeft: 3,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      marginLeft: "60px",
    }
  },
  tooltipIP: {
    marginLeft: 3,
    display: 'flex',
    alignItems: 'center'
  },
  columnDisplay: {
    display: 'flex',
    flexDirection: 'column'
  },
  rowDisplay: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  attemptsWithTooltipContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 3,

  },
  ipLabel: {
    display: 'flex',
    height: '50px',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    [theme.breakpoints.up(600)]: {
      fontSize: '1.5rem',
      justifyContent: 'flex-start',
    },
  }
}));

export const mapWidgetUseStyles = () : Function => makeStyles((theme : Theme) => ({
  mapContainer: {
    minHeight: '130px',
    '& div': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));
