import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { configureAppStore } from "./store/store";

const store = configureAppStore();

// if (process.env.NODE_ENV !== 'production') {
//   //eslint-disable-next-line
//   (global as any).$RefreshReg$ = () => {};
//   //eslint-disable-next-line
//   (global as any).$RefreshSig$ = () => () => {};
// }

ReactDOM.render(
  <Auth0Provider     
    domain={process.env.REACT_APP_AUTH0_DOMAIN!}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      scope: process.env.REACT_APP_AUTH0_SCOPE!,
  }}>
  <Provider store={ store }>
    <App />
  </Provider>
  </Auth0Provider>
, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
