import React from 'react';
import AttemptsByCountryDataGrid from '../../MapAttempts/components/AttemptsByCountryDataGrid';
import { ClassNameOnly } from '../../../interfaces/components';
import { AttemptsFilterObj } from '../../../interfaces/components';

export interface AttemptsDetailsProps extends ClassNameOnly {
  filter?: AttemptsFilterObj;
}

const AttemptsDetails: React.FunctionComponent<AttemptsDetailsProps> = ({
  filter
}: AttemptsDetailsProps): React.ReactElement => {


  return (
    <>
      <AttemptsByCountryDataGrid filterValue={filter?.filterValue}/>
    </>
  );
};

export default AttemptsDetails;
