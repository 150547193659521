import {
  initThemeInfoSelector,
  mapAnimationTypeSelector,
  customThemeSelector,
  mapInfoSelector,
  attemptsChartInfoSelector,
  metricsChartInfoSelector,
  attemptsInfoSelector,
  statusInfoSelector,
  viewTypeSelector,
  isViewSwitcherShownSelector
} from '../../../store/features/view/selectors/themeSelectors';
import { useSelector } from 'react-redux';

export function useInitThemeInfo(): ReturnType<typeof initThemeInfoSelector> {
  return useSelector(initThemeInfoSelector);
}

export function useMapAnimationType(): ReturnType<typeof mapAnimationTypeSelector> {
  return useSelector(mapAnimationTypeSelector);
}

export function useCustomTheme(): ReturnType<typeof customThemeSelector> {
  return useSelector(customThemeSelector);
}

export function useMapInfo(): ReturnType<typeof mapInfoSelector> {
  return useSelector(mapInfoSelector);
}

export function useAttemptsChartInfo(): ReturnType<typeof attemptsChartInfoSelector> {
  return useSelector(attemptsChartInfoSelector);
}

export function useMetricsChartInfo(): ReturnType<typeof metricsChartInfoSelector> {
  return useSelector(metricsChartInfoSelector);
}

export function useAttemptsInfo(): ReturnType<typeof attemptsInfoSelector> {
  return useSelector(attemptsInfoSelector);
}

export function useStatusSettings(): ReturnType<typeof statusInfoSelector> {
  return useSelector(statusInfoSelector);
}

export function useViewTypeStatus(): ReturnType<typeof viewTypeSelector> {
  return useSelector(viewTypeSelector);
}

export function useIsViewSwitcherShown(): ReturnType<typeof isViewSwitcherShownSelector> {
  return useSelector(isViewSwitcherShownSelector);
}
