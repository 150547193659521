import  makeStyles  from '@mui/styles/makeStyles';

export const mainMenuUseStyles = (open: boolean) : Function => makeStyles((theme : any) => ({
  verticallyCenteredNoFlex: {
    position: 'absolute',
    margin: '0px',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  linkReset: {
    textDecoration: 'none',
    color: 'inherit',
    '&:focus': {
      textDecoration: 'none'
    },
    '&:visited': {
      textDecoration: 'none'
    },
    '&:link': {
      textDecoration: 'none'
    },
    '&:active': {
      textDecoration: 'none'
    },
    display: 'block',
    width: '100%',
  },
  menuMargin: {
    marginTop: open ? theme.spacing(8) : theme.spacing(31),
  },
  iconBackground: {
    color: 'inherit'
  },
  logoutButton: {
    cursor: 'pointer'
  },
  menuListContainer: {
    flex: 1,
    transition: 'width 0.5s ease',
  }
}));
