import React from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import AttemptsByTimeContainer from '../../features/AttemptsByTime/attemptsByTimeSection/AttemptsByTimeContainer';
import AttemptsByCountryContainer from '../../features/MapAttempts/components/attemptsByCountrySection/AttemptsByCountryContainer';
import StatusContainer from '../../features/SystemHealth/components/StatusContainer';
import {commonCss} from "../../assets/css/commonCss";
import SystemInfoContentBox from './systemInfoSection/systemInfoContentBox/SystemInfoContentBox';
import { useAppSelector } from '../../../../store/hooks';
import { useStyles } from './data-container-styles';
export interface DataContainerProps {
  modalLeftDisplacement?: number;
}

const DataContainer: React.FunctionComponent<DataContainerProps> = ({
  modalLeftDisplacement = 0
}: DataContainerProps): React.ReactElement => {
  // ToDo: Add the logic to create the graphs dinamically 
  
  const commonClasses = commonCss()();
  const viewStatus = useAppSelector(state => state.customViewSettings.isBasicView);
  
  const classes = useStyles()();

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        className={clsx('')}
      >
        <div className={classes.attByLocationContainer}>
          <Grid item xs={12} className={classes.marginContainer}>
            <StatusContainer />
          </Grid>
          <Grid item className={clsx('classes.gridContainer')} >
            <div className={commonClasses.padding} >
              <AttemptsByCountryContainer/>

              <AttemptsByTimeContainer />
            </div>
          </Grid>
        </div>
        <div className={classes.metricsContainer}>
          <Grid item xs={12} className={clsx('classes.gridContainer')}>
            <div className = {commonClasses.padding}>
              <SystemInfoContentBox displacement={90} iconSize={0} isActive={viewStatus} />
            </div>
          </Grid>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default DataContainer;
