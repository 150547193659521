import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, MenuItem, Box, Typography, Menu } from '@mui/material';
import { DropdownButtonProps } from '../../interfaces/components';
import clsx from 'clsx';
import { commonCss } from '../../assets/css/commonCss';
import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';

const useStyles = () : Function => makeStyles((theme : Theme) => ({
  menuItemsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  dropdownSelectionContainer: {
    marginRight: '10px'
  },
  dropdownMenuList: {
    backgroundColor: theme.palette.background.default,
    width: '150px'
  },
  dropdownButtonIcon: {
    width: '20px',
    height: '20px',
    marginRight: '5px'
  },
  dropdownButton: {
    height: '36px',
    minWidth: '155px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '55px'
    },
  },
  dropdownMenuItems: {
    color: theme.palette.grey[900]
  },
  dropdownMenu: {
    zIndex: 1300
  },
  dropdownPaper: {
    borderRadius: '5px'
  }
}));

const DropdownButton: React.FunctionComponent<React.PropsWithChildren<
  DropdownButtonProps & React.HTMLAttributes<HTMLDivElement>
>> = ({
  dropButtonRef,
  buttonLabel,
  buttonIconSrc,
  buttonIcon,
  handleValueChange,
  disabledKey,
  hideOnSmallDevices,
  showContextAsLabel,
  buttonStyle,
  ...props
}: React.PropsWithChildren<
  DropdownButtonProps & React.HTMLAttributes<HTMLDivElement>
>): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles()();
  const [dropdownButtonLabel, setDropdownButtonLabel] = useState('');
  const commonClasses = commonCss()();

  useEffect(() => {
    setDropdownButtonLabel(buttonLabel);
  }, [buttonLabel]);

  const handleButtonClick = (): void => {
    setIsOpen(!isOpen);
  };

  const handleContextClick = (value: string): void => {
    setDropdownButtonLabel(value);
  };

  const handleClickAway = (): void => {
    setIsOpen(false);
  };

  return (
    <div className={clsx(classes.dropdownSelectionContainer, props.className)}>
      {/* alignItems="flex-end" */}
      <Grid container direction="column" >
        <Grid item xs={12}>
          <Button
            color = "primary"
            size="small"
            variant="outlined"
            ref={dropButtonRef}
            onClick={handleButtonClick}
            className= {commonClasses.toolbarButton} //{classes.dropdownButton}
            //style={buttonStyle} don't know the use of this commented it out for now
          >
            <div className={commonClasses.iconWrapper}>{buttonIcon}</div>
            <Box display={hideOnSmallDevices ? { xs: 'none', md: 'block', lg: 'block' } : {}}>
              <Typography variant="body2" color="inherit" noWrap>
                {dropdownButtonLabel}
              </Typography>
            </Box>
            <ArrowDropDownIcon />
          </Button>
          <Menu
            className={classes.dropdownMenu}
            open={isOpen}
            anchorEl={dropButtonRef.current}
            disablePortal
            onClose={handleClickAway}
          >
            <div className={classes.dropdownMenuList}>
              {React.Children.map(props.children, (child, index: number) => {
                if (child) {
                  return (
                    <MenuItem
                      disabled={(child as React.ReactElement).key === disabledKey}
                      className={classes.dropdownMenuItems}
                      // alignItems="center"
                      key={index}
                      onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
                        handleValueChange(String((child as React.ReactElement).key));
                        setIsOpen(false);
                        showContextAsLabel && handleContextClick(event.currentTarget.innerText);
                      }}
                    >
                      {child}
                    </MenuItem>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </Menu>
        </Grid>
      </Grid>
    </div>
  );
};

export default DropdownButton;
