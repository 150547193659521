import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { color } from '@amcharts/amcharts4/core';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { commonCss } from '../../../assets/css/commonCss';
import { notWellDefined } from '../../../interfaces/common';
import { IndicatorStatusDescriptor, getIndicatorClassForStatus } from '../utils/systHealth-utils';
import { StatusValue } from '../../Devices/store/device/statusSlice';
import MenuIndicator from './MenuIndicator';
import { circDoubleCompoUseStyles, AnimationClassObj } from '../assets/systemHealth-styles';
import { DeviceApiObj } from 'src/store/features/user/devicesSlice';

export interface CircularDoubleIndicatorStatusDescriptor extends IndicatorStatusDescriptor {
  labelLeft: string;
  labelRight: string;
  labelBottom: string;
  statusLeft: notWellDefined<StatusValue>;
  statusRight: notWellDefined<StatusValue>;
  statusBottom: notWellDefined<StatusValue>;
}

export interface CircularDoubleIndicatorProps {
  desc: string;
  size: number;
  statusDescriptor: CircularDoubleIndicatorStatusDescriptor;
  indicatorSize: number;
  heartBeat?: { enabled: boolean; speed: number };
  showSideInfo?: boolean;
  colorSheme: keyof PaletteColor;
  device: DeviceApiObj | null;
}

// size and indicatorSize are hardcoded
const CircularDoubleIndicator: React.FunctionComponent<CircularDoubleIndicatorProps> = ({
  desc,
  size,
  indicatorSize,
  statusDescriptor,
  heartBeat,
  showSideInfo = false,
  colorSheme,
  device
}: CircularDoubleIndicatorProps): React.ReactElement => {
  const theme = useTheme();
  const commonClasses = commonCss()();

  //create styles for left half circle
  const { statusClassObj: statusLeftClass, label: valueLeft } = getIndicatorClassForStatus(
    statusDescriptor?.statusLeft,
    statusDescriptor,
    theme,
    colorSheme
  );

  //create styles for right half circle
  const { statusClassObj: statusRightClass, label: valueRight } = getIndicatorClassForStatus(
    statusDescriptor?.statusRight,
    statusDescriptor,
    theme,
    colorSheme
  );

  const animationObjLeft: AnimationClassObj = {
    '0%': { boxShadow: `0px 0px 7px 3px ${color(statusLeftClass.color).lighten(0.25).hex}` },
    '50%': { boxShadow: `0px 0px 1px 1px ${color(statusLeftClass.color).lighten(0.25).hex}` },
    '100%': { boxShadow: `0px 0px 7px 3px ${color(statusLeftClass.color).lighten(0.25).hex}` }
  };
  //console.log(statusLeftClass.color, color(statusLeftClass.color).hex, color(statusLeftClass.color).lighten(10));
  const animationObjRight: AnimationClassObj = {
    '0%': { boxShadow: `0px 0px 7px 3px ${color(statusRightClass.color).lighten(0.25).hex}` },
    '50%': { boxShadow: `0px 0px 1px 1px ${color(statusRightClass.color).lighten(0.25).hex}` },
    '100%': { boxShadow: `0px 0px 7px 3px ${color(statusRightClass.color).lighten(0.25).hex}` }
  };

  const classes = circDoubleCompoUseStyles(
    heartBeat?.speed || 5,
    statusLeftClass,
    statusRightClass,
    animationObjLeft,
    animationObjRight
  )();

  return (
    <Box>
      <div className={classes.container}>
        {showSideInfo && (
          <Box className={clsx(classes.indicatorTooltip, classes.indicatorTooltipLeft)}>
            <Typography variant="body2" color="inherit" noWrap style={{ flexGrow: 1 }}>
              {statusDescriptor.labelLeft}
            </Typography>
            <Typography component="h1" variant="h6" color="inherit" noWrap style={{ flexGrow: 1 }}>
              {valueLeft}
            </Typography>
          </Box>
        )}
        <div className={clsx(classes.indicatorContainerWrapper)}>
          <Typography
            component="div"
            variant="h6"
            noWrap
            style={{ flexGrow: 1 }}
            className={clsx(classes.topDescription, commonClasses.textColorDark)}
          >
            {desc}
          </Typography>
          <div
            className={clsx(classes.indicatorContainer)}
            style={{ width: `${size}px`, height: `${size}px` }}
          >
            <div
              className={clsx(
                classes.leftIndicator,
                classes.indicatorStatusLeft,
                classes.indicator,
                heartBeat?.enabled && classes.indicatorBrandingHeartBeatLeft
              )}
              style={{ borderWidth: `${indicatorSize}px` }}
            />
            <div
              className={clsx(
                classes.rightIndicator,
                classes.indicatorStatusRight,
                classes.indicator,
                heartBeat?.enabled && classes.indicatorBrandingHeartBeatRight
              )}
              style={{ borderWidth: `${indicatorSize}px` }}
            />
            <div className={classes.indicatorBranding} />
          </div>
          <MenuIndicator
            label={statusDescriptor.labelBottom}
            statusDescriptor={{ status: statusDescriptor?.statusBottom, ...statusDescriptor }}
            colorSheme={colorSheme}
            device={device}
          />
        </div>
        {showSideInfo && (
          <Box className={clsx(classes.indicatorTooltip, classes.indicatorTooltipRight)}>
            <Typography variant="body2" color="primary" noWrap style={{ flexGrow: 1 }}>
              {statusDescriptor.labelRight}
            </Typography>
            <Typography component="h1" variant="h6" color="primary" noWrap style={{ flexGrow: 1 }}>
              {valueRight}
            </Typography>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default CircularDoubleIndicator;
