import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoginContainer from '../../pages/LoginContainer';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../../layouts/Dashboard';
import { Redirect, Route, Switch } from 'react-router';

const AppRouter: React.FunctionComponent<{}> = (): React.ReactElement => {

  return (
    <Router>
      <Switch>
        <Route path="/login" component={LoginContainer} />
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route path="*">
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
