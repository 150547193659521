import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../common/common';
import { Token, BaseApiData, DictionaryEntry, StateApiObj } from '../../../features/sensor/interfaces/services';
import { AppThunk } from '../../store';
import { getCustomerInfoApi } from '../../../features/sensor/services/remoteApi/clientApi';
import { notWellDefined } from '../../../features/sensor/interfaces/common';
import { DeviceApiObj } from './devicesSlice';

export type UserRole = DictionaryEntry;

export interface CdcUser extends BaseApiData {
  email: string;
  roles: UserRole[];
}

export interface CdcUserEnhanced extends CdcUser {
  isAdmin: boolean;
}

export interface ClientDataApi extends BaseApiData {
  cdcUsers: CdcUser[];
  devices: DeviceApiObj[];
}

export type ClientState = StateApiObj<ClientDataApi> & {
  currentUser: notWellDefined<CdcUserEnhanced>;
};

const createCdcUser = (user: notWellDefined<CdcUser>): CdcUserEnhanced => ({
  id: user?.id || '',
  name: user?.name || '',
  email: user?.email || '',
  updated: user?.updated || '',
  created: user?.created || '',
  description: user?.description || '',
  roles: user?.roles || [],
  isAdmin: false
});

const setCdcUser = (userName: string, cdcUsers: CdcUser[]): CdcUserEnhanced => {
  const user = cdcUsers.find((cdcUser) => cdcUser.email !== '' && cdcUser.email === userName);
  const userInfo: CdcUserEnhanced = createCdcUser(user);
  const adminRole = userInfo.roles.find((role) => role.name === 'ROLE_ADMIN');
  userInfo.isAdmin = !!adminRole;
  return userInfo;
};

const initialState: ClientState = {
  ...createInitialRequestState<ClientDataApi, {}>({}, {}, {}),
  currentUser: null
};

const commonRequestReducers = createRequestReducers<ClientDataApi>();

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clientSetRequestInProgress: commonRequestReducers.setRequestInProgress,
    clientSetRequestError: commonRequestReducers.setRequestError,
    clientSetRequestFulfilled: commonRequestReducers.setRequestFulfilled,
    setCurrentUser(state, action: PayloadAction<CdcUserEnhanced>): void {
      state.currentUser = action.payload;
    },
    setCurrentUserByName(state, action: PayloadAction<string>): void {
      if (state.data && state.data.cdcUsers) {
        state.currentUser = setCdcUser(action.payload, state.data.cdcUsers);
      }
    }
  }
});

export const {
  clientSetRequestInProgress,
  clientSetRequestError,
  clientSetRequestFulfilled,
  setCurrentUser,
  setCurrentUserByName
} = clientSlice.actions;

export default clientSlice.reducer;

export const fetchCustomerInfo =
  (userName: string, token: Token): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(clientSetRequestInProgress(true));
    try {
      const result = await getCustomerInfoApi(token);
      dispatch(clientSetRequestFulfilled(result));
      dispatch(setCurrentUserByName(userName));
    } catch (e) {
      if (e instanceof Error) dispatch(clientSetRequestError({ isError: true, errorMsg: e.message }));
    }
  };
