import { Theme } from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette';
import createMuiTheme from '@mui/material/styles/createTheme';
import { ITheme, IThemeOptions } from './ITheme';
import typography from './defaults/typography';
import { CustomSettings, MapColors, MapType } from '../store/features/view/customViewSettingsSlice';
import { ThemeType } from '../store/features/view/themeSlice';

export const overrideMapSettings = (customSettings: CustomSettings): CustomSettings => {
  const userMapType: MapType | undefined = (localStorage.getItem('map.type') as MapType) || undefined;
  const userMapPalleteRaw = localStorage.getItem('map.colors') || undefined;
  let userMapPallete: MapColors | undefined;
  try {
    userMapPallete = userMapPalleteRaw ? JSON.parse(userMapPalleteRaw) : undefined;
  } catch (e) {
    //log issue
    userMapPallete = undefined;
  }
  return {
    ...customSettings,
    dashboard: {
      ...customSettings.dashboard,
      map: {
        ...customSettings.dashboard.map,
        type: userMapType || customSettings.dashboard.map.type,
        colors: { ...customSettings.dashboard.map.colors, ...userMapPallete }
      }
    }
  };
};

//TODO replace defaults in paths with env folder names
//custom settings loader
export const getCustomSettingsProto = async (themeType: ThemeType): Promise<CustomSettings> => {
  //try catch (e) {return customSettingsProto;} to trick static analysis - can't resolve module at build time
  const customSettingsModuleName = 'customSettings'; //TODO make seperate file for constants like these
  const customSettingsModule = await import(`./defaults/${themeType}/${customSettingsModuleName}`);
  return customSettingsModule.default;
};

//function giveMapColors

// why?
export const overridePrimaryColors = (theme: Theme): Theme => {
  const userPalleteRaw = localStorage.getItem('theme.palette');
  let userPallete: PaletteColor | undefined;
  try {
    userPallete = userPalleteRaw ? JSON.parse(userPalleteRaw) : undefined;
  } catch (e) {
    //log issue
    userPallete = undefined;
  }
  return { ...theme, palette: { ...theme.palette, primary: { ...theme.palette.primary, ...userPallete } } };
};

export const createViewSettings = async (themeType: ThemeType): Promise<CustomSettings> => {
  const customSettings = await getCustomSettingsProto(themeType);
  return customSettings; //overrideMapSettings(customSettings);
};

//custom theme loader
// TODO: change return interface type
//TODO replace defaults in paths with env folder names
export const getThemeProto = async (themeType: ThemeType): Promise<ITheme> => {
  let extraThemeSettings = await import(`./defaults/${themeType}/extraThemeSettings`);
  extraThemeSettings = { extraThemeSettings: extraThemeSettings.default };
  const typographyTotal = { typography: typography };
  const theme = await import(`./defaults/${themeType}/theme`);

  return { ...theme.default, ...typographyTotal, ...extraThemeSettings };
};

// TODO: figure out whether the promise interface can be ITheme
export const createDefaultTheme = async (themeType: ThemeType): Promise<Theme> => {
  const themeProto = await getThemeProto(themeType);
  return createMuiTheme(themeProto as IThemeOptions); // overridePrimaryColors(createMuiTheme(themeProto));
};

export const updateThemePalette = (theme: Theme, palette?: PaletteColor): Theme => {
  const updatedTheme = { ...theme };
  if (palette) updatedTheme.palette.primary = palette;
  return updatedTheme;
};
