import {
  selectedDeviceSelector,
  timeScaleSelector,
  dataReloadingInfoSelector,
  totalStatsDataSelector,
  statusDataSelector,
  reportsDataSelector,
  targetCountryCodeSelector,
  totalStatsApiErrorSelector,
  statusErrorSelector,
  isSpinnerActiveSelector
} from '../features/Devices/store/device/selectors/deviceSelectors';
import { useSelector } from 'react-redux';
import { DeviceApiObj } from '../../../store/features/user/devicesSlice';
import { Timescale } from '../interfaces/services';
import {
  ChartTimeAggregats,
  ChartTimeMetricsAggregats
} from '../../../store/features/view/customViewSettingsSlice';

export type InnerTransformFunction = (value: any) => any;
export type TransformFunc = (
  timePeriod: Timescale,
  timeAggregate?: ChartTimeAggregats
) => InnerTransformFunction;
export type TransformFuncMetrics = (
  timePeriod: Timescale,
  timeAggregate?: ChartTimeMetricsAggregats
) => InnerTransformFunction;

//ToDo separate hooks with logic form selectors
export function useSelectedDevice(): DeviceApiObj | null {
  return useSelector(selectedDeviceSelector);
}

export function useTimeScale(): ReturnType<typeof timeScaleSelector> {
  return useSelector(timeScaleSelector);
}

export function useDataReloadingInfo(): ReturnType<typeof dataReloadingInfoSelector> {
  return useSelector(dataReloadingInfoSelector);
}

export function useTotalStatsData(): ReturnType<typeof totalStatsDataSelector> {
  return useSelector(totalStatsDataSelector);
}

export function useStatusData(): ReturnType<typeof statusDataSelector> {
  return useSelector(statusDataSelector);
}

export function useReporstData(): ReturnType<typeof reportsDataSelector> {
  return useSelector(reportsDataSelector);
}

export function useTotalStatsError(): ReturnType<typeof totalStatsApiErrorSelector> {
  return useSelector(totalStatsApiErrorSelector);
}

export function useStatusError(): ReturnType<typeof statusErrorSelector> {
  return useSelector(statusErrorSelector);
}

export function useTargetCountryCode(): ReturnType<typeof targetCountryCodeSelector> {
  return useSelector(targetCountryCodeSelector);
}

export function useIsSpinnerActive(): ReturnType<typeof isSpinnerActiveSelector> {
  return useSelector(isSpinnerActiveSelector);
}
