import { useAppSelector } from "src/store/hooks"
import { commonCss } from "src/features/sensor/assets/css/commonCss";
import clsx from 'clsx';
import { dashboardCss } from "src/features/sensor/assets/css/dashboardCss";
import InputDeviceSelector from "src/features/sensor/components/UI/InputDeviceSelector";
import PopupSelector from "src/features/sensor/components/UI/PopupSelector";
import { useTranslation } from 'react-i18next';
import { DeviceApiObj } from "src/store/features/user/devicesSlice";

export interface DeviceSelectorProps {
    handleSubmit: (sensor: DeviceApiObj) => void;
    devicesList: DeviceApiObj[];
  }

const DeviceSelector : React.FunctionComponent<DeviceSelectorProps> = ({
    handleSubmit,
    devicesList,
  }: DeviceSelectorProps): React.ReactElement =>{

    const isAdmin = useAppSelector(state => state.auth0Slice.isAdmin)
    const isDashboardUser = useAppSelector(state => state.auth0Slice.isDashboardUser)
    const commonClasses = commonCss()();
    const classes = dashboardCss()();
    const { t } = useTranslation();

    return(
        isAdmin ? <div className={clsx(commonClasses.toolbarButton, classes.title, classes.toolbarItemMargin)}>
                    <InputDeviceSelector />
                  </div> 
                  : isDashboardUser ? 
                  <PopupSelector
                    options={devicesList || []}
                    textLabel={t('dashboard.menu.selectDevice')}
                    buttonLabel={t('dashboard.menu.selectDevice')}
                    dialogLabel={`${t('dashboard.menu.chooseDesired')} ${t('dashboard.abbreviations.cdc')}`}
                    style={{ width: 300 }}
                    handleSubmit={handleSubmit}
                    className={clsx(classes.toolbarItemMargin, classes.title, commonClasses.toolbarButton)}
                /> : <> </>
    )
}

export default DeviceSelector