import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { ITheme } from "../../../../../themes/ITheme";
import { Theme } from "@mui/material/styles";

export interface AnimationClassObj {
	"0%": { boxShadow: string };
	"50%": { boxShadow: string };
	"100%": { boxShadow: string };
}

const hideBorder = "transparent !important";

export const circDoubleCompoUseStyles = (
	heartbeatSpeed: number,
	statusClassObjLeft: CSSProperties,
	statusClassObjRight: CSSProperties,
	animationClassObjLeft: AnimationClassObj,
	animationClassObjRight: AnimationClassObj
): Function =>
	makeStyles((theme: ITheme) => ({
		container: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
			justifyContent: "center",
		},
		indicatorContainerWrapper: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
		},
		// circle in the middle with indicatorBranding in it
		indicatorContainer: {
			width: "200px",
			height: "200px",
			position: "relative",
			borderRadius: "50%",
		},
		indicator: {
			height: "100%",
			width: "100%",
			borderStyle: "solid",
			position: "absolute",
			borderRadius: "50%",
			zIndex: theme.zIndex.appBar,
			backgroundColor: "transparent !important",
		},
		indicatorStatusLeft: statusClassObjLeft,
		indicatorStatusRight: statusClassObjRight,
		leftIndicator: {
			left: "0px",
			transform: "rotate(45deg)",
			borderRightColor: hideBorder,
			borderTopColor: hideBorder,
		},
		rightIndicator: {
			right: "0px",
			transform: "rotate(-45deg)",
			borderLeftColor: hideBorder,
			borderTopColor: hideBorder,
		},

		// puts the image in the middle
		indicatorBranding: {
			position: "absolute",
			top: "50%",
			left: "50%",
			width: "55%",
			height: "100%",
			transform: "translate(-50%, -50%)",
			background: `center / contain no-repeat url(${theme.extraThemeSettings?.logo.logoIcon})`,
			borderRadius: "50%",
		},
		indicatorTooltip: {
			backgroundColor: theme.palette.grey[600],
			borderRadius: theme.shape.borderRadius,
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingRight: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			color: theme.palette.secondary.main,
			position: "relative",
		},
		indicatorTooltipLeft: {
			marginRight: theme.spacing(2),
			"&::after": {
				content: '""',
				position: "absolute",
				top: "50%",
				right: "0px",
				transform: "translate(100%, -50%)",
				borderWidth: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(
					1
				)} 0`,
				borderColor: `transparent transparent transparent ${theme.palette.grey[600]}`,
				borderStyle: "solid",
			},
		},
		indicatorTooltipRight: {
			marginLeft: theme.spacing(2),
			"&::before": {
				content: '""',
				position: "absolute",
				top: "50%",
				left: "0px",
				transform: "translate(-100%, -50%)",
				borderWidth: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(
					1
				)} 0`,
				borderColor: `transparent ${theme.palette.grey[600]} transparent transparent`,
				borderStyle: "solid",
			},
		},
		topDescription: {
			width: "100%",
			textAlign: "center",
			padding: theme.spacing(1),
			[theme.breakpoints.down("xs")]: {
				marginTop: "10px",
			},
		},
		indicatorBrandingHeartBeatLeft: {
			//opacity: 1,
			animation: `$heartBeatLeft ${heartbeatSpeed}s infinite`,
		},
		"@keyframes heartBeatLeft": {
			...animationClassObjLeft,
		},
		indicatorBrandingHeartBeatRight: {
			//opacity: 1,
			animation: `$heartBeatRight ${heartbeatSpeed}s infinite`,
		},
		"@keyframes heartBeatRight": {
			...animationClassObjRight,
		},
	}));

export const menuIndicatorUseStyles = (
	statusClassObj: CSSProperties
): Function =>
	makeStyles((theme: any) => ({
		indicatorContainer: {
			padding: theme.spacing(2, 0),
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				padding: theme.spacing(2, 0),
			},
			[theme.breakpoints.up("sm")]: {
				padding: theme.spacing(2, 0),
			},
			[theme.breakpoints.up("md")]: {
				padding: theme.spacing(2, 0),
			},
		},
		indictorLabel: {
			paddingLeft: "5px",
		},
		indicator: {
			padding: "5px",
			borderRadius: "50%",
			boxShadow: "0px 0px 6px 2px",
		},
		indicatorStatus: statusClassObj,
		indicatorWrapper: {
			paddingRight: theme.spacing(2),
		},
	}));

export const statusContainerUseStyles = (): Function =>
	makeStyles((theme: Theme) => ({
		wrapper: {
			width: "90%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			[theme.breakpoints.down(1200)]: {
				// marginBottom: '100px',
				// justifyContent: 'center',
				marginBottom: "70px",
			},
			[theme.breakpoints.down(810)]: {
				paddingTop: "0px",
				marginTop: "20px",
				// flexDirection: 'column',
			},
			[theme.breakpoints.down(740)]: {
				flexDirection: "column",
			},
			backgroundColor: theme.palette.background.default,
		},
		arrowLeft: {
			[theme.breakpoints.down(1200)]: {
				order: 2,
				flex: "initial",
				flexGrow: 0.38,
			},
			[theme.breakpoints.down(810)]: {
				width: "50%",
				flexGrow: 1,
			},
			[theme.breakpoints.down(740)]: {
				width: "80%",
			},
		},
		arrowRight: {
			[theme.breakpoints.down(1200)]: {
				order: 3,
				flex: "initial",
				flexGrow: 0.38,
			},
			[theme.breakpoints.down(810)]: {
				flexGrow: 1,
				width: "50%",
			},
			[theme.breakpoints.down(740)]: {
				width: "80%",
			},
		},
		circleIndicator: {
			[theme.breakpoints.down(1200)]: {
				order: 1,
				flex: "initial",
				width: "100%",
			},
		},
		tooltip: {
			position: "absolute",
			right: -7,
			top: -7,
			[theme.breakpoints.down("sm")]: {
				right: -10,
				top: -10,
			},
		},
	}));

export const statusDescriptorUseStyles = (
	statusClassObj: CSSProperties
): Function =>
	makeStyles((theme: any) => ({
		container: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
			justifyContent: "space-between",
			width: "100%",
			height: "100%",
			padding: "40px",
			// padding: theme.spacing(6),
			color: theme.palette.primary.contrastText,
			boxSizing: "border-box",
		},
		containerDecriptor: {
			display: "inline-block",
		},
		containerDecriptorLeft: {
			textAlign: "left",
		},
		containerDecriptorRight: {
			textAlign: "right",
		},
		statusText: {
			padding: `2px ${theme.spacing(1)} 2 ${theme.spacing(1)}`,
			borderRadius: theme.shape.borderRadius,
			backgroundColor: `${theme.palette.common.white} !important`,
		},
		statusUndefined: {
			color: theme.palette.grey[600],
		},
		status: statusClassObj,
	}));

export const toastUseStyles = (): Function =>
	makeStyles((theme: any) => ({
		container: {
			transition: "3s",
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			alignContent: "center",
			position: "absolute",
			bottom: "0",
			left: "0",
			right: "0",
			zIndex: -1,
			textTransform: "none",
		},
		message: {
			width: "100%",
			textAlign: "center",
			color: theme.palette.error.contrastText,
			backgroundColor: theme.palette.error.main,
			textTransform: "none",
		},
		visible: {
			transition: "3s",
			transform: "translateY(100%)",
		},
	}));
