/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useEffect, useState } from "react";
import {
	SerialChartType,
} from "../hooks/chartHooks";
import { debouncedResize } from "../../../services/helpers/helpers";
import { useAppSelector, useAppDispatch} from "../../../../../store/hooks";
import { getChartResolution } from '../../../features/ChartIP/chart-utils/chart-utils';
import { useFetchAggregateAttemptsQuery } from '../../../features/ChartIP/store/aggregate-api-slice';
import { injectDataIntoSeries, createXYChartBase, XyChart, changeLegend, changeColumnsWidth,checkMinPeriod,MIN_PERIOD, roundDate, weekOffsetFrom, weekOffsetTo} from "../../../features/ChartIP/chart-utils/chart-utils";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import { setTimeScalePeriod } from "src/features/sensor/features/DataReload/store/time-scale-slice";
import { setTimePeriod } from "src/features/sensor/features/Devices/store/device-slice";
import clsx from 'clsx';


// import { setSpinnerActive } from '../../../store/features/device/deviceBaseSilce';
import { calculateCustomDays } from "../../../features/TimePeriodSelection/utils/timeSelect-utils";
import { pushToPeriodStack, popPeriodFromStack } from "src/features/sensor/features/DataReload/store/period-stack-slice";
import { baseSerialChartStyles } from "./BaseSerialChart-styles";

interface BaseSerialChartProps {
	type?: SerialChartType;
	id?: string;
	isChartStacked?: boolean;
	addPadding?: boolean;
	className?: string;
	outbound?: boolean;
}

const BaseSerialChart: React.FC<BaseSerialChartProps> = ({
	id,
	isChartStacked = false,
	addPadding = true,
	type = "DATA",
	outbound,
	...props
}: BaseSerialChartProps): React.ReactElement => {

	const deviceData = useAppSelector(state => state.deviceBase);
  	const timeScale = useAppSelector(state => state.timePeriod);
	let periodSelected = deviceData.timePeriod.value;
  	if (periodSelected === 'custom') {
		periodSelected = calculateCustomDays(timeScale);
	}
	const resolution = getChartResolution(periodSelected);
	const dispatch = useAppDispatch();
	const classes = baseSerialChartStyles()()

  	const queryParams = `?from=${timeScale.from}&to=${timeScale.to}&hostname=${deviceData.selectedDevice?.hostname}&resolution=${resolution}&filter=direction:${outbound? 'outbound' : 'inbound'}`;
	const { data, error } = useFetchAggregateAttemptsQuery(queryParams);
	//container that holds a chart DOM element
	const divRefStacked = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();
	const period = useAppSelector(state => state.periodStackSlice.lastPeriod);
	const stack = useAppSelector(state => state.periodStackSlice.stack);


	const chartId: string = id ?? "chartdivCol";
	const chartOpts = {
		xAxisTitle: capitalize(t("dashboard.attemptsChart.time_period")),
		yAxisTitle: capitalize(t("dashboard.attemptsChart.number_of_attempts")),
		valueY: "value",
		dateX: "id",
		name: "IP",
		type: "COLUMN",
		outbound : outbound,
		chartTitle : outbound ? capitalize(t("dashboard.core.outbounds")) : capitalize(t("dashboard.core.inbounds"))
	};

	const buttonClick = () => {
		dispatch(popPeriodFromStack())

		if(chart){
		  chart.chart.cursor.behavior = "selectX";
		  chart.chart.cursor.events.on('selectended', zoomEnded)
		}
	}

	useEffect(() => {

		if(period){
			dispatch(setTimeScalePeriod(period))
			// dispatch(setTimePeriod({value : period.res ? period.res : 'custom', selectedDates : {from: new Date(period.from), to: new Date(period.to)}}))
			// dispatch(setTimePeriod({value : 'custom', selectedDates : period}))

			if(chart) {
				const xAxis = chart.chart.xAxes.getIndex(0);
				if(xAxis){
				  chart.chart.cursor.behavior = "selectX";
				  chart.chart.cursor.events.on('selectended', zoomEnded)
				}
			}
		}
	}, [period]);

	const [chart, setChart] = useState<XyChart>();

	  function zoomEnded(ev : any){

		let range = ev.target.xRange;
		let axis = ev.target.chart.xAxes.getIndex(0);
		let from = axis.positionToDate(
			axis.toAxisPosition(range.start)
		  )
		  let to = axis.positionToDate(
			axis.toAxisPosition(range.end)
		  )

		if(axis.baseInterval.timeUnit === 'week'){
			from.setDate(weekOffsetFrom(from))
			to.setDate(weekOffsetTo(to))
		}

		from = roundDate(from, axis.baseInterval.timeUnit, axis.baseInterval.count)
		to = roundDate(to, axis.baseInterval.timeUnit, axis.baseInterval.count)

		ev.target.chart.cursor.selection.hide()		

		if(to.getTime() - from.getTime() === MIN_PERIOD) return

		if(checkMinPeriod(from, to, axis.baseInterval.count)){
			ev.target.chart.cursor.behavior = "none";
			ev.target.chart.cursor.events.off('selectended')
		}



		const period = {from : from.toISOString(), to: to.toISOString()}

		dispatch(pushToPeriodStack(period))

		dispatch(setTimeScalePeriod(period))
		dispatch(setTimePeriod({value : 'custom', selectedDates : {from: new Date(period.from), to: new Date(period.to)}}))
	  }

	useEffect(() => {
		if(error){
			dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.attemptsChart.error'))}))
		}
	}, [error]);

	useEffect(() => {
		  const chart = createXYChartBase(chartOpts, divRefStacked.current as HTMLDivElement)
		  setChart(chart)
		  const xAxis = chart.chart.xAxes.getIndex(0);
		  if(xAxis){
			chart.chart.cursor.behavior = "selectX";
			chart.chart.cursor.events.on('selectended', zoomEnded)
		  }
	}, []);

	useEffect(() => {
		if(chart){
			changeLegend(chart, chartOpts)
			setChart(chart)
		}

	}, [t]);



	useEffect(() => {
		let isMounted = true;
		if (chart && !chart?.chart.isDisposed() &&  data && isMounted) {
			const attemptsList = data.attempts.map(attempt => {
				return {
					IP: attempt.attempts,
					value: attempt.attempts,
					id: new Date(attempt.timestamp)
				}
			});



			// Filter the results with no value
			const filteredObj = attemptsList.filter(attempt => attempt.value > 0);

			if(filteredObj.length === 1){
					chart.chart.cursor.behavior = "none";
					chart.chart.cursor.events.off('selectended')
			}

			injectDataIntoSeries(chart, filteredObj, chartOpts, timeScale.from, timeScale.to, outbound);
			if(filteredObj.length < 4) changeColumnsWidth(chart)
		}


		return () => {
			isMounted = false;
			chart?.chart.isDisposed();
		}
	}, [data, chart]);

	const handleResize = useCallback((): void => {
		if (divRefStacked.current) {
			divRefStacked.current.style.height = `${Math.floor(
				divRefStacked.current.offsetWidth / 5
			)}px`;
		}
	}, []);

	useEffect(() => {
		handleResize();
		const clear = debouncedResize(handleResize);
		return (): void => clear();
	}, [handleResize]);

	return (
		<React.Fragment>
			{stack.length > 1 ? <div className={clsx(classes.circle)} onClick={buttonClick} ><div className={clsx(classes.line)} ></div></div> : ''}
			<div id={chartId} className={props.className} ref={divRefStacked} data-testid="baseSerialChart"/>
		</React.Fragment>
	);
};

export default React.memo(BaseSerialChart);
