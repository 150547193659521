/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useContext, useCallback } from 'react';
import { debouncedResize } from '../../../../../../services/helpers/helpers';
import { useTargetCountryCode } from '../../../../../../hooks/deviceHooks';
import { mapDataContext } from '../../../MapDataProvider';
import { mapWidgetUseStyles } from './mapDescription-styles';

const MapWidget: React.FunctionComponent<{}> = ({ ...props }): React.ReactElement => {
  const divRef = useRef<HTMLDivElement>(null);
  const classes = mapWidgetUseStyles()();
  const { setDivRef, setTargetCountryCode } = useContext(mapDataContext);
  
  const targetCountryCode = useTargetCountryCode();

  const handleResize = useCallback((): void => {
    if (divRef.current) {
      divRef.current.style.height = `${Math.floor(divRef.current.offsetWidth / 2)}px`;
    }
  }, []);
  useEffect(() => {
    handleResize();
    const clear = debouncedResize(handleResize);
    return (): void => clear();
  }, [handleResize]);


  useEffect(() => {
    divRef.current && setDivRef(divRef.current);
  }, [divRef]);

  useEffect(() => {
    setTargetCountryCode(targetCountryCode || '')
  }, [targetCountryCode]);

  return <div id="map" className={classes.mapContainer} ref={divRef} />;
};

export default React.memo(MapWidget);
