import React, { ReactElement, useCallback } from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';
import { PaginationNumbersProps } from '../../interfaces/components';

const useStyles = () : Function =>  makeStyles((theme : Theme) => ({
  links: {
    color: theme.palette.primary.dark,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.text.secondary
    }
  },
  pageLink: {
    height: '40px',
    width: '40px',
    padding: 5
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
      '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.secondary
      }
  }
}));

const PaginationNumbers = React.memo(
  ({ count, rowsPerPage, page, onChangePage }: PaginationNumbersProps): ReactElement => {
    const classes = useStyles()();
    const pagesIndexes: number[] = [];
    //pagination dots appear treashold
    const allPagesVisibleLimit = 9;
    const visibleStartPages = 3;

    const handleLastPageNumber = useCallback(
      (pageNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        onChangePage(event, pageNumber);
      },
      [onChangePage]
    );

    // model of a single page number
    const pagesModel = (data: number[]): ReactElement => (
      <React.Fragment>
        {data.map((pagesIndex: number) => (
          <IconButton
            key={pagesIndex}
            className={`${clsx(classes.links, classes.pageLink, pagesIndex === page && classes.active)}`}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
              handleLastPageNumber(pagesIndex, event)
            }
          >
            {pagesIndex + 1}
          </IconButton>
        ))}
      </React.Fragment>
    );

    for (let i = 0; i < Math.ceil(count / rowsPerPage); i++) {
      pagesIndexes.push(i);
    }
    // handler that will leave first, last and middle (optional) 3 pages visible, the rest is replaced with dots
    if (pagesIndexes.length <= allPagesVisibleLimit) {
      return pagesModel(pagesIndexes);
    } else {
      const firstPages = pagesIndexes.slice(0, visibleStartPages);
      const lastPages = pagesIndexes.slice(pagesIndexes.length - visibleStartPages, pagesIndexes.length);
      const middlePages = pagesIndexes.slice(pagesIndexes[page - 1], pagesIndexes[page + 2]);
      const fourthPage = pagesIndexes.slice(pagesIndexes[page + 1], pagesIndexes[page + 2]);
      const fifthPage = pagesIndexes.slice(pagesIndexes[page], pagesIndexes[page + 2]);
      const preEndBorder = pagesIndexes.slice(
        pagesIndexes.length - (visibleStartPages + 2),
        pagesIndexes.length - visibleStartPages
      );
      const endBorder = pagesIndexes.slice(
        pagesIndexes.length - (visibleStartPages + 1),
        pagesIndexes.length - visibleStartPages
      );
      return (
        <React.Fragment>
          {pagesModel(firstPages)}
          {page === visibleStartPages - 1 && pagesModel(fourthPage)}
          {page === visibleStartPages && pagesModel(fifthPage)}
          {page >= visibleStartPages + 2 && ' ... '}
          {page >= visibleStartPages + 1 &&
            page <= pagesIndexes.length - (visibleStartPages + 2) &&
            pagesModel(middlePages)}
          {page <= pagesIndexes.length - (visibleStartPages + 3) && ' ... '}
          {page === pagesIndexes.length - (visibleStartPages + 1) && pagesModel(preEndBorder)}
          {page === pagesIndexes.length - visibleStartPages && pagesModel(endBorder)}
          {pagesModel(lastPages)}
        </React.Fragment>
      );
    }
  }
);

export default PaginationNumbers;
