import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Timescale {
  from: string;
  to: string;
}

const initialState: Timescale = {
  from: "",
  to: "",
}

const timeSlice = createSlice({
  name: "period",
  initialState,
  reducers: {
    setTimeScalePeriod(state, action: PayloadAction<Timescale>) {
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
  }
})

export const { setTimeScalePeriod } = timeSlice.actions;
export default timeSlice.reducer;