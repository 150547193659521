import { createSelector } from '@reduxjs/toolkit';
import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { RootState } from '../../../rootReducer';
import { InitThemeInfo } from '../themeSlice';
import {
  MapAnimationType,
  MapOptions,
  AttemptsChartOptions,
  AttemptsSettings,
  StatusSettings,
  MetricsChartOptions
} from '../customViewSettingsSlice';

const initThemeInfo = (state: RootState): InitThemeInfo => state.themeSettings.init;
const mapAnimationType = (state: RootState): MapAnimationType =>
  state.customViewSettings.dashboard.map.animation.type;
const mapInfo = (state: RootState): MapOptions => state.customViewSettings.dashboard.map;
const attemptsChartInfo = (state: RootState): AttemptsChartOptions =>
  state.customViewSettings.dashboard.attemptsChart;
const customTheme = (state: RootState): Theme => state.themeSettings.theme;
const attemptsInfo = (state: RootState): AttemptsSettings => state.customViewSettings.dashboard.attempts;
const metricsChartInfo = (state: RootState): MetricsChartOptions =>
  state.customViewSettings.dashboard.metricsChart;
const statusInfo = (state: RootState): StatusSettings => state.customViewSettings.dashboard.status;
const viewType = (state: RootState): boolean => state.customViewSettings.isBasicView;
const isViewSwitcherShown = (state: RootState): boolean =>
  state.customViewSettings.dashboard.attemptsChart.isViewTypeSwitchShown;

export const initThemeInfoSelector = createSelector(initThemeInfo, (themeInfo) => themeInfo);

export const mapAnimationTypeSelector = createSelector(mapAnimationType, (mapType) => mapType);

export const customThemeSelector = createSelector(customTheme, (theme) => createTheme(theme));

export const mapInfoSelector = createSelector(mapInfo, (mapOpts) => mapOpts);

export const attemptsInfoSelector = createSelector(attemptsInfo, (attemptsInfoObj) => attemptsInfoObj);

export const attemptsChartInfoSelector = createSelector(
  attemptsChartInfo,
  (attemptsCharOpts) => attemptsCharOpts
);

export const metricsChartInfoSelector = createSelector(
  metricsChartInfo,
  (metricsChartOpts) => metricsChartOpts
);

export const statusInfoSelector = createSelector(statusInfo, (statusInfoOpts) => statusInfoOpts);

export const viewTypeSelector = createSelector(viewType, (viewTypeStatus) => viewTypeStatus);

export const isViewSwitcherShownSelector = createSelector(
  isViewSwitcherShown,
  (isViewSwitcherShownStatus) => isViewSwitcherShownStatus
);

// Time period aggregators for attempts
const hourTimeAggregator = (state: RootState) => state.themeSettings.chartTimeAggregats.hourValue;
const dayTimeAggregator = (state: RootState) => state.themeSettings.chartTimeAggregats.dayValue;
const weekTimeAggregator = (state: RootState) => state.themeSettings.chartTimeAggregats.weekValue;
const monthTimeAggregator = (state: RootState) => state.themeSettings.chartTimeAggregats.monthValue;
const threeMonthsTimeAggregator = (state: RootState) =>
  state.themeSettings.chartTimeAggregats.threeMonthsValue;
const sixMonthsTimeAggregator = (state: RootState) => state.themeSettings.chartTimeAggregats.sixMonthsValue;
const yearTimeAggregator = (state: RootState) => state.themeSettings.chartTimeAggregats.yearValue;
const customTimeAggregator = (state: RootState) => state.themeSettings.chartTimeAggregats.customValue;

export const hourAggregatorSelector = createSelector(hourTimeAggregator, (hourValue) => hourValue);

export const dayAggregatorSelector = createSelector(dayTimeAggregator, (dayValue) => dayValue);

export const weekAggregatorSelector = createSelector(weekTimeAggregator, (weekValue) => weekValue);

export const monthAggregatorSelector = createSelector(monthTimeAggregator, (monthValue) => monthValue);

export const threeMonthsAggregatorSelector = createSelector(
  threeMonthsTimeAggregator,
  (threeMonthsValue) => threeMonthsValue
);

export const sixMonthsAggregatorSelector = createSelector(
  sixMonthsTimeAggregator,
  (sixMonthsValue) => sixMonthsValue
);

export const yearAggregatorSelector = createSelector(yearTimeAggregator, (yearValue) => yearValue);

export const customAggregatorSelector = createSelector(customTimeAggregator, (customValue) => customValue);

export const timeAggregatorSelector = createSelector(
  [
    hourTimeAggregator,
    dayTimeAggregator,
    weekTimeAggregator,
    monthTimeAggregator,
    threeMonthsTimeAggregator,
    sixMonthsTimeAggregator,
    yearTimeAggregator,
    customTimeAggregator
  ],
  (hourValue, dayValue, weekValue, monthValue, threeMonthsValue, sixMonthsValue, yearValue, customValue) => ({
    hourValue,
    dayValue,
    weekValue,
    monthValue,
    threeMonthsValue,
    sixMonthsValue,
    yearValue,
    customValue
  })
);

// Time period aggregators for metrics
const hourTimeAggregatorMetrics = (state: RootState) =>
  state.themeSettings.chartTimeMetricsAggregats.hourValue;
const dayTimeAggregatorMetrics = (state: RootState) => state.themeSettings.chartTimeMetricsAggregats.dayValue;
const weekTimeAggregatorMetrics = (state: RootState) =>
  state.themeSettings.chartTimeMetricsAggregats.weekValue;
const monthTimeAggregatorMetrics = (state: RootState) =>
  state.themeSettings.chartTimeMetricsAggregats.monthValue;
const threeMonthsTimeAggregatorMetrics = (state: RootState) =>
  state.themeSettings.chartTimeMetricsAggregats.threeMonthsValue;
const sixMonthsTimeAggregatorMetrics = (state: RootState) =>
  state.themeSettings.chartTimeMetricsAggregats.sixMonthsValue;
const yearTimeAggregatorMetrics = (state: RootState) =>
  state.themeSettings.chartTimeMetricsAggregats.yearValue;
const customTimeAggregatorMetrics = (state: RootState) =>
  state.themeSettings.chartTimeMetricsAggregats.customValue;

export const hourAggregatorMetricsSelector = createSelector(
  hourTimeAggregatorMetrics,
  (hourValue) => hourValue
);

export const dayAggregatorMetricsSelector = createSelector(dayTimeAggregatorMetrics, (dayValue) => dayValue);

export const weekAggregatorMetricsSelector = createSelector(
  weekTimeAggregatorMetrics,
  (weekValue) => weekValue
);

export const monthAggregatorMetricsSelector = createSelector(
  monthTimeAggregatorMetrics,
  (monthValue) => monthValue
);

export const threeMonthsAggregatorMetricsSelector = createSelector(
  threeMonthsTimeAggregatorMetrics,
  (threeMonthsValue) => threeMonthsValue
);

export const sixMonthsAggregatorMetricsSelector = createSelector(
  sixMonthsTimeAggregatorMetrics,
  (sixMonthsValue) => sixMonthsValue
);

export const yearAggregatorMetricsSelector = createSelector(
  yearTimeAggregatorMetrics,
  (yearValue) => yearValue
);

export const customAggregatorMetricsSelector = createSelector(
  customTimeAggregatorMetrics,
  (customValue) => customValue
);

export const timeAggregatorMetricsSelector = createSelector(
  [
    hourTimeAggregatorMetrics,
    dayTimeAggregatorMetrics,
    weekTimeAggregatorMetrics,
    monthTimeAggregatorMetrics,
    threeMonthsTimeAggregatorMetrics,
    sixMonthsTimeAggregatorMetrics,
    yearTimeAggregatorMetrics,
    customTimeAggregatorMetrics
  ],
  (hourValue, dayValue, weekValue, monthValue, threeMonthsValue, sixMonthsValue, yearValue, customValue) => ({
    hourValue,
    dayValue,
    weekValue,
    monthValue,
    threeMonthsValue,
    sixMonthsValue,
    yearValue,
    customValue
  })
);
