/* eslint-disable react-hooks/exhaustive-deps */
import { Toolbar, Typography, useMediaQuery } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ITheme } from '../../../../themes/ITheme';
import { useClieantAvailableDevices, useClientInfo } from '../../hooks/userHooks';
import DeviceSelector from '../../pages/DeviceSelector';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setDevice } from '../../features/Devices/store/device-slice';
import { DeviceApiObj } from 'src/store/features/user/devicesSlice';
import { formatDate } from '../../features/TimePeriodSelection/utils/timeSelect-utils';
import TimeScale from '../../features/TimePeriodSelection/components/TimeScaleContainer';
import { t } from 'i18next';
import CustomSwitchContainer from '../../../sensor/layouts/CustomSwitchContainer';
import { capitalize } from 'lodash';
import { setViewType } from '../../../../store/features/view/customViewSettingsSlice';
import Toast from '../../features/SystemHealth/components/Toast';
import TokenExpirationModal from 'src/features/TokenExpiration/components/TokenExpirationModal';


// const drawerWidth = 64;

const useStyles = makeStyles((theme: ITheme) => ({
  toolbar: {
    paddingRight: 0, // keep right padding when drawer closed
    padding: 16,
    backgroundColor: theme.extraThemeSettings?.header.background,
    display: 'flex',
    justifyContent: 'space-between'
  },
  menuButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 0,
    [theme.breakpoints.up(1279)]: {
      marginRight: 36,
      display: 'none',
    }
  },
  toolbarMobileWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  companyName: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  dashboardTitle: {
    flexGrow: 1,
    top: '10%',
    left: '50%',
    transform: 'translate(-50%,0%)',
  },
  absolutelyCentered: {
    position : 'absolute'
  },
  deviceSelectionButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  deviceName:{
    fontSize: "small", position: 'absolute',
    top: '23%',
    left: '50%',
    transform: 'translate(-50%,80%)',
    textTransform: 'none',
  },
  period:{
    fontSize: "small", position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%,80%)',
    textTransform: 'none',
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 220;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - 65px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  color: theme.palette.secondary.contrastText,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AppBarUIProps {
  open: boolean;
  openCloseDrawer: () => void;
}

export default function AppBarUI({ open }: AppBarUIProps) {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const clientInfo = useClientInfo();
  const availableDevices = useClieantAvailableDevices();
  const devicesList = useAppSelector(state => state.deviceBase.deviceList);
  const isAdmin = useAppSelector(state => state.auth0Slice.isAdmin);
  const isDashboardUser = useAppSelector(state => state.auth0Slice.isDashboardUser);
  const deviceName = useAppSelector(state => state.deviceBase.selectedDevice?.hostname);
  const period = useAppSelector(state => state.timePeriod);
  const isSwitcherShown = false;
  const mobileBreakpoint = useMediaQuery(`(max-width: 600px)`);

  const changeDevice = useCallback(
    (device: DeviceApiObj): void => {
      const selectedDevice = {
        hostname: device.hostname,
        name: device.name,
        location: device.location,
        country: device.country
      };
      dispatch(setDevice(selectedDevice));
    },
    [availableDevices]
  );

  const handleViewTypeSwitch = useCallback((event: React.FormEvent, checked: boolean): void => {
    if (checked) {
      dispatch(setViewType(false));
    } else {
      dispatch(setViewType(true));
    }
  }, []);

  return (
    <AppBar position='fixed' open={open}>
      <Toolbar className={classes.toolbar}>
        {/* left side of top menu */}
        <Typography className={classes.menuButton}>
        </Typography>
        <div className={classes.toolbarMobileWrapper}>
          <Typography
            className={classes.companyName}
            component="span"
            variant="h6"
            color="inherit"
            noWrap
          >
            {clientInfo?.name}
          </Typography>
          <DeviceSelector handleSubmit={changeDevice} devicesList={devicesList}/>
        </div>

        {/* dashboard title (in the centre) */}
        {isAdmin || isDashboardUser ?
          <div style={{display: 'flex', flexDirection : 'column'}}>
            <Typography
              component="h1"
              variant="h6"
              noWrap
              className={clsx(classes.dashboardTitle, classes.absolutelyCentered, classes.deviceSelectionButton)}
            >
              {t('dashboard.title')}
            </Typography>
            <Typography
              component="h5"
              variant="h6"
              noWrap
              className={clsx(classes.deviceName, classes.deviceSelectionButton)}
            >
              {deviceName}
            </Typography>
            {formatDate(period.from) !== ""? 
            <Typography
              component="h5"
              variant="h6"
              noWrap
              className={clsx(classes.period, classes.deviceSelectionButton)}
            >
              {formatDate(period.from)} to {formatDate(period.to)}
            </Typography> : null}
          </div> 
        : null}

        {/* right side of top menu*/}
        {isAdmin || isDashboardUser ?
        <TimeScale
          label={t('dashboard.menu.setTime')}
          customButtonLabel={`${t('shared.custom')} +`}
          dialogLabel={t('dashboard.menu.setTime')}
          ampm={false}
          dateFormat={'DD/MM/YYYY'}
        /> : null}

        {isSwitcherShown && (
          <CustomSwitchContainer
            labelOn={capitalize(
              t(mobileBreakpoint ? 'dashboard.menu.view_advanced_short' : 'dashboard.menu.view_advanced')
            )}
            labelOff={capitalize(
              t(mobileBreakpoint ? 'dashboard.menu.view_standard_short' : 'dashboard.menu.view_standard')
            )}
            height={mobileBreakpoint ? 20 : 30}
            width={mobileBreakpoint ? 50 : 155}
            handleChange={handleViewTypeSwitch}
            mobileBreakpoint={mobileBreakpoint}
          />
        )}
      </Toolbar>
      <Toast />
      <TokenExpirationModal />
    </AppBar>
  )
}
