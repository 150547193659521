import { Action, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

// import deviceBaseReducer from './features/device/deviceBaseSilce';
import reportsReducer from '../features/sensor/features/Devices/store/device/reportsSlice';
import statusReducer from '../features/sensor/features/Devices/store/device/statusSlice';
import totalStatsReducer from '../features/sensor/features/Devices/store/device/totalStats';
import errorSlice from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import authReducer from './features/user/authSlice';
import clientReducer from './features/user/clientSlice';
import userSettingsReducer from './features/user/userSettingsSlice';
import themeReducer from './features/view/themeSlice';
import customViewSettingsReducer from './features/view/customViewSettingsSlice';
import attemptsUpdateSlice from '../features/sensor/features/Devices/store/device/attemptsUpdateSlice';
import metricsUpdateSlice from '../features/sensor/features/Devices/store/device/metricsUpdateSlice';
import periodStackSlice from 'src/features/sensor/features/DataReload/store/period-stack-slice';
import { statusAPISlice } from '../features/sensor/features/SystemHealth/store/status-api-slice';
import { statsApiSlice } from '../features/sensor/features/SystemHealth/store/stats-api-slice';
import { attApiSlice } from '../features/sensor/features/MapAttempts/store/attempts-api-slice';
import { metricsApiSlice } from '../features/sensor/features/Metrics/store/metrics-api-slice';
import { aggregateApiSlice } from '../features/sensor/features/ChartIP/store/aggregate-api-slice';
import { sensorApiSlice } from 'src/features/sensor/features/Devices/store/device-api-slice';
import { ipAttempsApi } from '../features/sensor/features/DataGrid/store/ipAttempts-api-slice';
import ipAttemptSlice from '../features/sensor/features/DataGrid/store/ipAttempts-slice';
import auth0Slice from '../features/Authentication/store/auth-slice'
import deviceSlice from '../features/sensor/features/Devices/store/device-slice';
import attemptSlice from '../features/sensor/features/MapAttempts/store/attempts-slice';
import timeSlice from '../features/sensor/features/DataReload/store/time-scale-slice';
import statsSlice from '../features/sensor/features/SystemHealth/store/stats-slice';
import statusSlice from '../features/sensor/features/SystemHealth/store/status-slice';
import tokenExpirationSlice from 'src/features/TokenExpiration/store/token-expiration-slice';
import menuSlice from 'src/features/mainMenu/store/menu-slice';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  ipAttempts : ipAttemptSlice,
  periodStackSlice : periodStackSlice,
  auth0Slice: auth0Slice,
  deviceBase: deviceSlice,
  error: errorSlice,
  timePeriod: timeSlice,
  reports: reportsReducer,
  status: statusReducer,
  stats: statsSlice,
  HealthStatus: statusSlice,
  totalStats: totalStatsReducer,
  auth: authReducer,
  client: clientReducer,
  userSettings: userSettingsReducer,
  themeSettings: themeReducer,
  customViewSettings: customViewSettingsReducer,
  attempts: attemptSlice,
  attemptsUpdate: attemptsUpdateSlice,
  metricsUpdate: metricsUpdateSlice,
  tokenExpiration: tokenExpirationSlice,
  menuSlice: menuSlice,
  [sensorApiSlice.reducerPath] : sensorApiSlice.reducer,
  [statusAPISlice.reducerPath]: statusAPISlice.reducer,
  [statsApiSlice.reducerPath]: statsApiSlice.reducer,
  [attApiSlice.reducerPath]: attApiSlice.reducer,
  [aggregateApiSlice.reducerPath]: aggregateApiSlice.reducer,
  [ipAttempsApi.reducerPath]: ipAttempsApi.reducer,
  [metricsApiSlice.reducerPath] : metricsApiSlice.reducer
});


export const configureAppStore = (preloadedState?: PreloadedState<RootState | {}>) => {

  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        statusAPISlice.middleware,
        statsApiSlice.middleware,
        attApiSlice.middleware,
        aggregateApiSlice.middleware,
        ipAttempsApi.middleware,
        metricsApiSlice.middleware,
        sensorApiSlice.middleware
      );
    },
  });
}

// Using typescript inference to figure it out as much as possible
// export type RootState = ReturnType<typeof rootReducer>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof configureAppStore>;
export type AppDispatch = AppStore['dispatch'];

// This will go away when we start refactoring the components depending on this 
// exported type
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
