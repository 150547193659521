import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../rootReducer';
import { notWellDefined } from '../../../../features/sensor/interfaces/common';
import { DeviceApiObj } from '../devicesSlice';
import { ApiError } from '../../../../features/sensor/interfaces/services';
import { PreferenceParameters } from '../userSettingsSlice';
import { selectedDeviceSelector } from '../../../../features/sensor/features/Devices/store/device/selectors/deviceSelectors';
import { ClientDataApi } from '../clientSlice';

const isAuthenticated = (state: RootState): boolean => state.auth.userLogged;
const isAuthCallInProgress = (state: RootState): boolean => state.auth.inProgress;
const isClientCallInProgress = (state: RootState): boolean => state.client.inProgress;
const isSettingsCallInProgress = (state: RootState): boolean => state.userSettings.inProgress;
const hasDefaultDevice = (state: RootState): notWellDefined<string> => state.userSettings.data?.defaultDevice;
const clieantAvailableDevices = (state: RootState): DeviceApiObj[] | undefined => state.client.data?.devices;
//TODO: useUserAvailableDevices
const authApiError = (state: RootState): ApiError => state.auth.error;
const clientApiError = (state: RootState): ApiError => state.client.error;
const userSettingsApiError = (state: RootState): ApiError => state.userSettings.error;
const userSettings = (state: RootState): notWellDefined<PreferenceParameters> => state.userSettings.data;
const clientInfo = (state: RootState): notWellDefined<ClientDataApi> => state.client.data;
const isAdmin = (state: RootState): notWellDefined<boolean> => state.client.currentUser?.isAdmin;
const selectedLanguage = (state: RootState): notWellDefined<string> => state.userSettings.data?.language;

export interface AuthError {
  error: Error;
  isAuthError: boolean;
  isClientError: boolean;
  isSettingsError: boolean;
}

export const authApiErrorSelector = createSelector(authApiError, error => {
  return error;
});

export const clientApiErrorSelector = createSelector(clientApiError, error => {
  return error;
});

export const userSettingsApiErrorSelector = createSelector(userSettingsApiError, error => {
  return error;
});

export const isAuthInProgressSelector = createSelector(
  [isAuthCallInProgress, isClientCallInProgress, isSettingsCallInProgress],
  (authCall, clientCall, settingsCall) => {
    return authCall || clientCall || settingsCall;
  }
);

export const isAuthenticatedSelector = createSelector(
  [isAuthenticated, isAuthCallInProgress, isClientCallInProgress, isSettingsCallInProgress],
  (logged, authCall, clientCall, settingsCall) => {
    return logged && !authCall && !clientCall && !settingsCall;
  }
);

export const authErrorSelector = createSelector(
  [authApiError, clientApiError, userSettingsApiError],
  (authError, clientError, settingsError): AuthError => {
    return {
      error: new Error(`auth: ${authError.errorMsg}\n client: ${clientError.errorMsg}\n settings: ${settingsError.errorMsg}`),
      isAuthError: authError.isError,
      isClientError: clientError.isError,
      isSettingsError: settingsError.isError
    };
  }
);

export const hasDefaultDeviceSelector = createSelector(hasDefaultDevice, device => {
  return device;
});

export const clieantAvailableDevicesSelector = createSelector(clieantAvailableDevices, devices => {
  return [...(devices || [])].sort((a, b) => {
    if (a.location < b.location) {
      return -1;
    }
    if (a.location > b.location) {
      return 1;
    }
    return 0;
  });
});

export const userSettingsSelector = createSelector(userSettings, settings => {
  return settings;
});

export const combinedAuthInfoSelector = createSelector(
  [isAuthInProgressSelector, isAuthenticatedSelector, authErrorSelector],
  (authInProgress, authenticated, apiError) => {
    return { authInProgress, authenticated, apiError };
  }
);

export const combinedUserInfoSelector = createSelector(
  [userSettingsSelector, selectedDeviceSelector, clieantAvailableDevicesSelector],
  (settings, selectedDevice, availableDevices) => {
    return { settings, selectedDevice, availableDevices };
  }
);

export const clientInfoSelector = createSelector(clientInfo, clientObj => {
  return clientObj;
});

export const isAdminSelector = createSelector(isAdmin, admin => {
  return admin;
});

export const selectedLanguageSelector = createSelector(selectedLanguage, language => {
  return language;
});
