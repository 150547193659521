import { createSelector } from '@reduxjs/toolkit';
import { notWellDefined } from '../../../../../interfaces/common';
import { ApiError } from '../../../../../interfaces/services';
import { RootState } from '../../../../../../../store/rootReducer';
import { SingleUpdateDataError } from './deviceSelectors';

const getTimeToUpdateAttempts = (state: RootState): string => state.attemptsUpdate.data;
// const getTimeToUpdateMetrics = (state: RootState): string => state.dataUpdate.itsTimeToUpdateMetrics;
const getAttemptsStartDate = (state: RootState): notWellDefined<Date> =>
  state.attemptsUpdate.attemptsStartDate;
const getAttemptsFloorDate = (state: RootState): notWellDefined<Date> =>
  state.attemptsUpdate.attemptsFloorDate;
const getAttemptsRequestInProgress = (state: RootState): boolean => state.attemptsUpdate.inProgress;
export const getAttemptsRequestError = (state: RootState): ApiError => state.attemptsUpdate.error;

// const getMetricsStartDate = (state: RootState): Date => state.dataUpdate.metricsStartDate;
// const getMetricsFloorDate = (state: RootState): Date => state.dataUpdate.metricsFloorDate;
// const getSpinnerState = (state: RootState): Boolean => state.dataUpdate.isSpinnerActive;
// const getResolution = (state: RootState): String => state.dataUpdate.resolution;
//const dataUpdateInProgress;

export const getAttemptsDatesSelector = createSelector(
  [getAttemptsStartDate, getAttemptsFloorDate],
  (
    attemptsStartDate,
    attemptsFloorDate
  ): { attemptsStartDate: notWellDefined<Date>; attemptsFloorDate: notWellDefined<Date> } => {
    return { attemptsStartDate, attemptsFloorDate };
  }
);

export const getTimeToUpdateAttemptsSelector = createSelector(
  [getTimeToUpdateAttempts],
  (timeToUpdateAttempts): string => {
    return timeToUpdateAttempts;
  }
);

export const getAttemptsRequestInProgressSelector = createSelector(
  [getAttemptsRequestInProgress],
  (attemptsRequestInProgress): boolean => {
    return attemptsRequestInProgress;
  }
);

export const getAttemptsRequestErrorSelector = createSelector(
  [getAttemptsRequestError],
  (attemptsRequestError): ApiError => {
    return attemptsRequestError;
  }
);

export const getAttemptsStartDateSelector = createSelector(
  [getAttemptsStartDate],
  (attemptsStartDate): notWellDefined<Date> => {
    return attemptsStartDate;
  }
);

export const getAttemptsFloorDateSelector = createSelector(
  [getAttemptsFloorDate],
  (attemptsFloorDate): notWellDefined<Date> => {
    return attemptsFloorDate;
  }
);

export const getAttemptsApiErrorSelector = createSelector(
  getAttemptsRequestErrorSelector,
  (attemptsRequestErrorSelector): SingleUpdateDataError => {
    return {
      error: new Error(`totalStats: ${attemptsRequestErrorSelector.errorMsg}\n`),
      isError: attemptsRequestErrorSelector.isError,
      errorMessage: 'dashboard.sync.attempts'
    };
  }
);
