/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { MapImageSeries } from '@amcharts/amcharts4/maps';
import {
  MapObj,
  clearMap,
  createMap,
  changeBubbleSize,
  configureHeatLegend,
  updateDataSerie,
  prepareData,
  animateMap,
  addOnClickHandler,
  changeAnimationColor,
  changeLegendVisibility,
  disableZoom
} from '../../../services/mapServices/mapSpecificFunctions';
import { notWellDefined } from '../../../interfaces/common';
import { AccumulatedObj, CalculatedData } from '../../../interfaces/services';
import { MapType, MapColors, MapAnimationType } from '../../../../../store/features/view/customViewSettingsSlice';
import { AttemptsFilterObj } from '../../../interfaces/components';
import { useMapInfo } from '../../../hooks/viewHooks';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { useFetchAttemptsQuery } from 'src/features/sensor/features/MapAttempts/store/attempts-api-slice';
import { buildMapData } from 'src/features/sensor/features/MapAttempts/map-utils/data-structure';
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import { capitalize } from "lodash";
import { useTranslation } from 'react-i18next';



export interface MapDataContext {
  mapObj: notWellDefined<MapObj>;
  setDivRef: (divRef: HTMLDivElement) => void;
  setTargetCountryCode: (targetCountryCode: string) => void;
}

export type MapData = CalculatedData<AccumulatedObj>;

const initState: MapDataContext = {
  mapObj: null,
  setDivRef: (divRef: HTMLDivElement) => null,
  setTargetCountryCode: (targetCountryCode: string) => null
};

export const mapDataContext = createContext<MapDataContext>(initState);

interface MapDataProviderProps {
  children: React.ReactNode;
  onClickRefProp?: React.MutableRefObject<notWellDefined<(filter: AttemptsFilterObj) => void>>;
}

// all the map configuration and initialization takes place here
// functions are taken from mapSpecificFunctions
const MapDataProvider: React.FunctionComponent<MapDataProviderProps> = ({ children, onClickRefProp }) => {
  const { Provider } = mapDataContext;
  const [mapObj, setMap] = useState<notWellDefined<MapObj>>(null);
  const [mapType, setMapType] = useState<MapType>('bubble');
  const [divRef, setDivRef] = useState<notWellDefined<HTMLDivElement>>(null);
  const { t } = useTranslation();

  const [mapColors, setMapColors] = useState<notWellDefined<MapColors>>(null);
  const [mapData, setMapData] = useState<notWellDefined<MapData>>(null);
  const [mapAnimationType, setMapAnimationType] = useState<notWellDefined<MapAnimationType>>(null);
  const [numberOfAnimatedLines, setNumberOfAnimatedLines] = useState<notWellDefined<number>>(0);
  const [targetCountryCode, setTargetCountryCode] = useState<notWellDefined<string>>(null);
  const mobileBreakpoint = useMediaQuery(`(max-width: ${'900px'})`);
  const disableZoomBreakpoint = useMediaQuery(`(max-width: ${'400px'})`);
  const dispatch = useAppDispatch();
  const mapOpts = useMapInfo();
  // Add valid map-license from the corresponding environment variable
  
  const deviceSelected = useAppSelector(state => state.deviceBase.selectedDevice);
  const timeScale = useAppSelector(state => state.timePeriod);
  const stats = useAppSelector(state => state.stats)
  
  const statsParams = `?from=${timeScale.from}&to=${timeScale.to}&hostname=${deviceSelected?.hostname}`;
  const { data, error } = useFetchAttemptsQuery(statsParams);

  const cityAttempts = useMemo(() => {
    if (data) {
      return buildMapData(data);
    }
    return {};
  }, [data]);
  
  const mapRawData = cityAttempts;

  useEffect(() => {
		if(error){
			// const processedMessage = processMessage(error)
			dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.attemptsChart.error'))}))
		}
	}, [error]);


  //Use effect to remove the legend and there is no attempts
  useEffect(() => {
    if(stats.totalAttempts === 0) {
      changeLegendVisibility(mapObj?.heatLegend, false)
    }else{
      changeLegendVisibility(mapObj?.heatLegend, true)
    }
	}, [stats.totalAttempts]);
  
  //initiate map basing on provided div ref
  useEffect((): void => {
    if (divRef && mapOpts && typeof mobileBreakpoint === 'boolean') {
      const newMapObj = createMap(divRef as HTMLElement, mapOpts, mobileBreakpoint);
      setMap(newMapObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef, mapOpts, mapType, mobileBreakpoint]);

  //respond on screen changes
  useEffect((): void => {
    if(disableZoomBreakpoint && mapObj){
      disableZoom(mapObj.map)
    }

    if (mapObj?.type === 'bubble') {
      changeBubbleSize(mapObj?.dataSerie as MapImageSeries, mobileBreakpoint);
    }
  }, [mobileBreakpoint, mapObj, disableZoomBreakpoint]);

  useEffect(
    () => (): void => {
      mapObj && clearMap(mapObj.container, mapObj.map);
      setMap(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //respond on map options change
  useEffect((): void => {
    if (mapOpts) {
      setMapType(mapOpts.type);
      setMapColors(mapOpts.colors);
      setMapAnimationType(mapOpts.animation.type);
      setNumberOfAnimatedLines(mapOpts.numberOfAnimatedLines);
    }
  }, [mapOpts]);

  //respond on raw map data change
  useEffect((): void => {
    if (mapRawData && mapColors) {
      const rawData = mapRawData.attempts || [];
      const maxValue = mapRawData.max?.value || 0;
      const minValue = mapRawData.min?.value || 0;
      
      setMapData({ data: prepareData(rawData, mapColors, maxValue), maxValue, minValue });
    }
  }, [mapRawData, mapColors]);

  //add onClick handler for map objects if provided
  useEffect((): void => {
    if (mapObj && onClickRefProp?.current) {
      addOnClickHandler(mapObj.dataSerie, mapObj.type, onClickRefProp.current);
    }
  }, [onClickRefProp, mapObj]);

  //respond on prepared map data change
  useEffect((): void => {
    if (mapObj && mapData) {
      
      configureHeatLegend(mapObj.heatLegend, mapData.minValue, mapData.maxValue);
      updateDataSerie(mapObj.dataSerie, mapData.data);
    }
    if (mapObj && mapData?.data && mapData?.data.length === 0) {
      mapObj.lineSerie.mapLines.clear();
    }
  }, [mapData, mapObj]);

  //redraw animations
  useEffect((): void => {
    if (
      mapObj &&
      mapData &&
      mapData.data.length > 0 &&
      mapAnimationType &&
      mapColors &&
      targetCountryCode &&
      typeof numberOfAnimatedLines === 'number'
      ) {
      animateMap(
        mapObj.lineSerie,
        mapAnimationType,
        mapData.data,
        mapColors,
        targetCountryCode,
        numberOfAnimatedLines
      );
    }
  }, [mapData, mapObj, mapAnimationType, numberOfAnimatedLines, targetCountryCode, mapColors]);

  //set new animation colors
  useEffect((): void => {
    if (mapObj && mapColors) changeAnimationColor(mapObj.lineSerie, mapColors.animation);
  }, [mapObj, mapColors]);

  const mapContext: MapDataContext = {
    mapObj,
    setDivRef,
    setTargetCountryCode
  };

  return <Provider value={mapContext}>{children}</Provider>;
};

export default React.memo(MapDataProvider);

export const { Consumer } = mapDataContext;
