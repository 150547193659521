import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';

export const customDatesUseStyles = () : Function => makeStyles((theme: Theme) => ({
  verticalSeparator: {
    margin: `${theme.spacing(2)} 0px ${theme.spacing(2)} 0px`
  },
  //TODO ??!!
  test: {
    color: 'red !important'
  }
}));

export const scaleSelectorUseStyles = () : Function => makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    },
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
  selectorContainer: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(5)
    }
  },
  toggleButton: {
    border: 'none',
    padding: `0px ${theme.spacing(3)} 0px ${theme.spacing(3)}`,
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      color: theme.palette.grey[700]
    }
  },
  toggleButtonDropdown: {
    border: 'none !important',
    //padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(2)}px`,
    height: 'auto',
    padding: theme.spacing(1/2),
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      color: theme.palette.grey[700]
    }
  },
  toggleButtonSelected: {
    backgroundColor: 'transparent !important',
    textDecoration: 'underline',
    fontWeight: 'bold'
  },
  customDatesButton: {
    minWidth: '110px',
    position: 'relative',
    //backgroundColor: blueGray[800],
    margin: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
    color: theme.palette.grey[800],
    '&:hover': {
      //backgroundColor: blueGray[700],
    }
  },
  customDatesDesc: {
    display: 'none',
    position: 'absolute',
    bottom: '0px',
    transform: 'translate(-50%, 120%)',
    left: '50%',
    marginTop: theme.spacing(1),
    color: theme.palette.grey[600],
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontSize: 0,
    backgroundColor: theme.palette.background.default,
    '& > span': {
      display: 'block'
    },
    '& .customDatesDescSub': {
      display: 'inline-block',
      width: '50%',
      textAlign: 'center'
    }
  },
  alwaysVisible: {
    display: 'block !important'
  },
  selectedDatesValidation: {
    color: 'red'
  },
  verticalSeparator: {
    margin: `${theme.spacing(2)} 0px ${theme.spacing(2)} 0px`
  },
  smallButtonsContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  toggleButtonGroupRootOverride: {
    backgroundColor: 'transparent'
  },
  toggleButtonGroupGroupedOverride: {
    padding: '0px'
  }
}));
