import {  Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';
import React from 'react';
import { PipeDataManipulation } from './hooks/chartHooks';

import FirewallStateChart from '../../features/Metrics/Components/FirewallStateChart'

const useStyles = () : Function => makeStyles((theme : Theme) => ({
	chartContainer: {
		minHeight: "250px",
		marginBottom: "15px",
	},
}));

interface AttemptsWidgetProps {
  id?: string;
  active?: boolean;
  pipeDataManipulation?: Partial<PipeDataManipulation>;
  isChartStacked?: boolean;
}

export const SystemInfoChartWidget: React.FunctionComponent<AttemptsWidgetProps> = ({
  id = 'systemInfoChart',
  active = true,
  isChartStacked = false,
  pipeDataManipulation
}: AttemptsWidgetProps): React.ReactElement => {
  const classes = useStyles()();

  return (<FirewallStateChart className={classes.chartContainer}/>);
};

export default React.memo(SystemInfoChartWidget);
