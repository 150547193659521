import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import cache from "../../../../../services/memoryCache/cache";


export type StatusValue = 'OK' | 'NOK' | 'WARN';

export interface SystemInfoApiObj {
  activationDate: any;
  deviceHealth: string;
  externalProtection: StatusValue;
  hostname: string;
  internalProtection: StatusValue;
  monitored?: StatusValue;
  timestamp: string;
}

export const statusAPISlice = createApi({
  reducerPath: 'statusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HEALTH as string,
    prepareHeaders(headers) {
      const token = cache.get('token');

      if (token) {
        headers.set('Authorization', `${token.tokenType} ${token.accessToken}`);
      }

      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchSystemHealth: builder.query<SystemInfoApiObj, string | void>({
        query(hostname) {
          return `?hostname=${hostname}`;
        }
      })
    }
  }
});

export const { useFetchSystemHealthQuery } = statusAPISlice;