import { Callback } from '../../interfaces/services';
import { debounce } from 'debounce';


export function debouncedResize(func: (ev?: Event) => void, cb?: Function): () => void {
  const debounced = debounce(func, 250);
  window.addEventListener('resize', debounced);
  return (): void => {
    debounced.clear();
    window.removeEventListener('resize', debounced);
    cb && cb();
  };
}

export function execCallback<T>(cb?: Callback<T>, err?: Error, value?: T): void {
  if (cb && typeof cb === 'function') cb(err, value);
}
