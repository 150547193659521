import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery
} from "@mui/material";
import clsx from "clsx";
import { capitalize } from "lodash";
import AttemptsDetails from './AttemptsDetails';
import { ClassNameOnly } from "../../../interfaces/components";
import { getDefaultValue } from '../utils/modal-helpers';
import { getCountryById } from '../../../services/mapServices/mapDictionary';
import { AttemptsFilterObj } from "../../../interfaces/components";
import HeaderContainer from '../../../layouts/HeaderContainer';
import { attemptsDetailsUseStyles } from './attemptsDetails-styles';

export interface AttemptsDetailsModalProps extends ClassNameOnly {
  modalLeftDisplacement?: number;
  setOpenRef: React.MutableRefObject<
    ((filter: AttemptsFilterObj) => void) | undefined
  >;
  disableOnMobile: boolean;
  mobileThreshold?: string;
}

const AttemptsDetailsModal: React.FunctionComponent<AttemptsDetailsModalProps> =
  ({
    setOpenRef,
    modalLeftDisplacement = 0,
    disableOnMobile,
    mobileThreshold,
    className,
  }: AttemptsDetailsModalProps): React.ReactElement => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<AttemptsFilterObj | undefined>();
    const classes = attemptsDetailsUseStyles(modalLeftDisplacement)();
    const hideModalBreakpoint = useMediaQuery(`(max-width: ${mobileThreshold || '670px'})`);

    const handleClose = () => {
      setOpen(false);
    }

    React.useEffect(() => {

      if (!(disableOnMobile && hideModalBreakpoint)) {
        setOpenRef.current = (filter: AttemptsFilterObj): void => {

          setSelectedFilter(filter);
          setOpen(true);
        };
      }
      return (): void => {
        setOpenRef.current = undefined;
      };
    }, [setOpenRef, setOpen, hideModalBreakpoint, disableOnMobile]);

    const modalTitle = React.useMemo(() => {
      if (selectedFilter) {
        if (
          selectedFilter.filterValue === 'IP' ||
          selectedFilter.filterValue === 'URL' ||
          selectedFilter.filterValue === 'HASH'
        ) {
          return getDefaultValue(selectedFilter.filterValue);
        } else {
          return getDefaultValue(getCountryById(selectedFilter.filterValue));
        }
      }
      return '';
    }, [selectedFilter]);

    

    return (
      <Dialog
        classes={{ paper: clsx(classes.modalDisplacement) }}
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"lg"}
        className={className}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          { <HeaderContainer
          content={<AttemptsDetails filter={selectedFilter} />}
          headerHeight={'55px'}
          headerText={modalTitle}
        /> }
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtons }}>
          <Button onClick={handleClose} color="primary">
            {capitalize(t("shared.actions.close"))}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default AttemptsDetailsModal;
