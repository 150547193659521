import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const intitialState = {
  isHeaderVisible: true,
}

const menuSlice = createSlice({
  name: 'menu',
  initialState: intitialState,
  reducers: {
    setHeaderVisibility(state, action: PayloadAction<boolean>) {
      state.isHeaderVisible = action.payload;
    }
  }
});

export const { setHeaderVisibility } = menuSlice.actions;
export default menuSlice.reducer;
