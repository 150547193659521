import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { PageMessageProps } from '../../interfaces/components';
import { commonCss } from '../../assets/css/commonCss';
import clsx from 'clsx';

const PageMessage: React.FunctionComponent<PageMessageProps> = ({
  message,
  height,
  ...props
}: PageMessageProps): React.ReactElement => {
  const commonClasses = commonCss()();

  const givenHeight: string = useMemo(() => {
    if (height) {
      return typeof height === 'number' ? `${height}px` : height;
    } else {
      return '100%';
    }
  }, [height]);

  return (
    <div className={clsx(commonClasses.fillerX, commonClasses.flexCentered)} style={{ height: givenHeight }}>
      <div>
        <Typography variant={'h3'} className={commonClasses.textColorDark}>
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default PageMessage;
