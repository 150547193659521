import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export type timeValues = 'h' | 'd' | 'w' | 'm' | '3m' | '6m' | 'y' | 'custom';

export interface TimescaleStack {
    stack : Timescale [];  
    lastPeriod? : Timescale;
  }

export interface Timescale {
  from: string;
  to: string;
  res? : timeValues
}

const initialState: TimescaleStack = {
    stack : [],
    lastPeriod : undefined,
}

const periodStackSlice = createSlice({
  name: "periodStack",
  initialState,
  reducers: {
    pushToPeriodStack(state, action: PayloadAction<Timescale>) {
      const stateObj = state.stack[state.stack.length - 1]
      if(stateObj.from !== action.payload.from && stateObj.to !== action.payload.to){
        state.stack.push(action.payload)
        state.lastPeriod = action.payload
      }

    },
    popPeriodFromStack(state){
        state.stack.pop();
        state.lastPeriod = state.stack[state.stack.length - 1];
    },
    initiateStack(state, action : PayloadAction<Timescale>) {
        state.stack = [];
        state.stack.push(action.payload);
        state.lastPeriod = action.payload
    }
}
})

export const { pushToPeriodStack,  popPeriodFromStack, initiateStack} = periodStackSlice.actions;
export default periodStackSlice.reducer;