import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cache from 'src/services/memoryCache/cache';

export interface SensorApiObj {
    id: string;
    name: string;
    hostname: string;
    location: string;
    country: string;
    type: string;
    serial: string;
    createdDate: string;
    updatedDate: string;
}

export const sensorApiSlice = createApi({
    reducerPath: 'sensorsApi',
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_CYBERSENSORS as string,
      prepareHeaders: (headers) => {
        const token = cache.get('token');
        headers.set('Authorization', `${token.tokenType} ${token.accessToken}`);
        return headers;
      },
    }),
    tagTypes: ['Sensors'],
    endpoints(builder) {
      return {
        fetchSensors: builder.query<SensorApiObj[], void | string>({
          query: (queryParams) => queryParams? `${queryParams}` : '',
          providesTags: (result) => 
          result 
            ?
              [
                ...result.map(({id}) => ({ type: 'Sensors', id } as const)),
                { type: 'Sensors', id: 'SensorsList' },
              ]
            :
              [{ type: 'Sensors', id: 'SensorsList' }]
        }),
      };
    },
  });

export const {useFetchSensorsQuery} = sensorApiSlice