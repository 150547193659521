/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { 
  DataGrid,
  GridColDef
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../../../store/hooks';
import { format as formatDate, parseISO } from 'date-fns';
import CustomPagination from '../../Pagination/components/CustomPagination';
import {  buildGridColumnsWithoutSource } from '../../DataGrid/grid-utils/table-utils';
import {  calculateNewOffset } from '../../DataGrid/grid-utils/table-utils'
import { buildGridStyles } from '../../DataGrid/styles/grid-styles';
import { useFetchIpAttemptsQuery } from '../../DataGrid/store/ipAttempts-api-slice';
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import { capitalize } from "lodash";

  interface TableData {
    id: string;
    date: string;
    threat: string;
    target: string;
    source: number;
    destination: number;
    protocol: string
  }
  interface AttemptsByCountryDataGridProps{
    name?: string;
    filterValue?: string;
  }
  

const AttemptsByCountryDataGrid: React.FC<AttemptsByCountryDataGridProps> = ( filter ) =>{

  const { t } = useTranslation();
  const rowsPerPage = parseInt(process.env.REACT_APP_COUNTRY_ROWS_PER_PAGE!);
  const [offset, setOffset] = useState(0);
  const themeSelected = useAppSelector(state => state.themeSettings.theme);
  const deviceSelected = useAppSelector(state => state.deviceBase.selectedDevice);
  const timeScalePeriod = useAppSelector(state => state.timePeriod);
  const countryCode = filter.filterValue;
  const queryParams = `?from=${timeScalePeriod.from}&to=${timeScalePeriod.to}&hostname=${deviceSelected?.hostname}&limit=${rowsPerPage}&offset=${offset}&countryCode=${countryCode}`;
  const { data , error} = useFetchIpAttemptsQuery(queryParams);
  const dispatch = useAppDispatch();

  useEffect(() => {
		if(error){
			// const processedMessage = processMessage(error)

			dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.attemptsChart.error'))}))

		}
	}, [error]);

  const columns: GridColDef[] = buildGridColumnsWithoutSource(t);

  let rows: TableData[] = [];

  if (data) {
    const attempts = data.attempts.map((attempt, index) => {
      return {
        id: attempt.timestamp + index,
        date: new Date(attempt.timestamp).toLocaleDateString(),
        time: formatDate(parseISO(attempt.timestamp), 'HH:mm:ss'),
        threat: attempt.threat,
        target: attempt.target,
        source: attempt.details.sourcePort,
        destination: attempt.details.destinationPort,
        protocol: attempt.details.protocol
      }
    });
    rows = [...attempts];
  }

  const customStyles = buildGridStyles()(themeSelected);

  const handlePageChange = (
    newPage: number,
  ) => {
    const page = newPage + 1;
    if (page > 1) {
      const newOffset = calculateNewOffset(page, rowsPerPage)
      setOffset(newOffset);
    } else {
      setOffset(0);
    }
  };

  return (
    <Box 
      sx={customStyles} >
      <DataGrid
        sx={{
          border: 0,
        }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          columns={columns}
          autoHeight
          rows={rows}
          rowCount={data ? data.total : 0}
          paginationMode="server"
          pagination
          pageSize={rowsPerPage}
          onPageChange={handlePageChange}
          getRowClassName={() =>'row-justify'}
          components={{
            Pagination: CustomPagination,
            LoadingOverlay: LinearProgress,
          }}
        />
    </Box>
  )   
}

export default AttemptsByCountryDataGrid;