import { createSlice } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../../../../../../store/features/common/common';

export type StatusValue = 'OK' | 'NOK' | 'WARN';

export interface SystemInfoApiObj {
  activationDate: any;
  deviceHealth: string;
  externalProtection: StatusValue;
  hostname: string;
  internalProtection: StatusValue;
  monitored?: StatusValue;
  timestamp: string;
}

const initialState = createInitialRequestState<SystemInfoApiObj, {}>({}, {}, {});

const commonRequestReducers = createRequestReducers<SystemInfoApiObj>();

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    statusSetRequestInProgress: commonRequestReducers.setRequestInProgress,
    statusSetRequestError: commonRequestReducers.setRequestError,
    statusSetRequestFulfilled: commonRequestReducers.setRequestFulfilled
  }
});

export const {
  statusSetRequestInProgress,
  statusSetRequestError,
  statusSetRequestFulfilled
} = statusSlice.actions;

export default statusSlice.reducer;
