import  makeStyles  from '@mui/styles/makeStyles';
import React from "react";
import {
	PipeDataManipulation,
} from "./hooks/chartHooks";
import { AttemptsTimestampGroupedObj } from "../../features/MapAttempts/definitions/attemptsDefinitions";
// import { AttemptType } from "../../features/AttemptsByTime/attemptsByTimeSection/AttemptsByTimeContainer";
import BaseSerialChart from "./base/BaseSerialChart";


const useStyles = () : Function => makeStyles(() => ({
	chartContainer: {
		minHeight: "250px",
		marginBottom: "15px",
	},
}));

interface AttemptsWidgetProps {
	id?: string;
	active?: boolean;
	outbound?: boolean;
	pipeDataManipulation?: Partial<
		PipeDataManipulation<any, AttemptsTimestampGroupedObj>
	>;
	isChartStacked?: boolean;
}

export const AttemptsChartWidget: React.FunctionComponent<AttemptsWidgetProps> =
	({
		id,
		active,
		isChartStacked = false,
		outbound
	}: AttemptsWidgetProps): React.ReactElement => {
		const classes = useStyles()();

		return (
			<BaseSerialChart
				id={id}
				isChartStacked={isChartStacked}
				className={classes.chartContainer}
				outbound={outbound}
			/>
		);
	};
export default React.memo(AttemptsChartWidget);
