import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import cache from "../../../../../services/memoryCache/cache";


export interface MetricsApiObj {
    activeMemory: number;
    currentFirewallStates: number;
    freeMemory: number;
    latestUpdate: string;
    load_1: number;
    load_5?: number;
    load_15?: number;
    maximumFirewallStates?: number;
    pfsenseVersion?: string;
    diskFree?: string;
    squidOk?: boolean;
    pfOk?: boolean;
    temperature?: number;
    time: string;
    tlfVersion: string;
    totalBlacklistIp?: number;
    totalBlacklistUrl?: number;
    totalBlacklistHash?: number;
    uptime: number;
    activationDate?: any;
  }

export const metricsApiSlice = createApi({
  reducerPath: 'metricsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_METRICS as string,
    prepareHeaders(headers) {
      const token = cache.get('token');
      headers.set('Authorization', `${token.tokenType} ${token.accessToken}`);
      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchMetrics: builder.query<MetricsApiObj[], string | void>({
        query(queryParams) {
          return `${queryParams}`;
        }
      })
    }
  }
})

export const { useFetchMetricsQuery } = metricsApiSlice;




