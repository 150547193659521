import  makeStyles  from '@mui/styles/makeStyles';

export const ipGridUseStyles = () : Function => makeStyles((theme : any) => ({
  headerText: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    whiteSpace: 'initial',
    margin: '10px 0px 10px 14px',
    boxSizing: 'border-box',
    fontSize: '1.5rem',
    letterSpacing: '0em',
    fontWeight: 600,
  },
  imageWrapper: {
    color: theme.palette.primary.dark,
    height: theme.spacing(3),
    paddingRight: theme.spacing(1)
  },
  tooltip: {
    marginLeft: 3
  },
}));
