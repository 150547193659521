import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';

export const attemptsContentBoxUseStyles = () : Function => makeStyles((theme : Theme) => ({
    container: {
        [theme.breakpoints.up('xl')]: {
            marginBottom : '2.5%',
          },
        [theme.breakpoints.down('xl')]: {
            marginBottom : '5.5%',
          },
        [theme.breakpoints.down('lg')]: {
            marginBottom : '20%',
          },
        [theme.breakpoints.down(800)]: {
            marginBottom : '20%',
          },
        [theme.breakpoints.down(700)]: {
            marginBottom : '30%',
          },
    },
  }));
  