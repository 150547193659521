

import { capitalize } from '@mui/material';
import { toUpper } from 'lodash';
import { 
  GridColDef
} from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import Tooltip from '@mui/material/Tooltip';

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params.value}>
    <span>{params.value}</span>
  </Tooltip>
)
const renderRedcell = (params: any, outbound? : boolean) => (
    <Tooltip title={params.value}>
      <span style={{color: 'red'}}>{params.value}</span>
    </Tooltip>
)

export const buildGridColumns = (t: TFunction<"translation", undefined>, outbound? : boolean) => {
    const columns: GridColDef[] = [
      { 
        field: 'date', 
        headerName: capitalize(t('shared.date')),
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
      },
      { 
        field: 'time',
        headerName: capitalize(t('shared.time')),
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center'
      },
      { 
        field: 'source',
        headerName: `${capitalize(t('dashboard.core.source'))} ${toUpper(t('dashboard.abbreviations.ip'))}`,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
        renderCell: outbound? renderCellWithTooltip : renderRedcell
      },
      { 
        field: 'destination',
        headerName: `${capitalize(t('dashboard.core.destination'))} ${toUpper(t('dashboard.abbreviations.ip'))}`,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
        renderCell: outbound? renderRedcell : renderCellWithTooltip
      },
      { 
        field: 'sourcePort',
        headerName: capitalize(t('dashboard.core.source_port')),
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5
      },
      // ${capitalize(t('dashboard.core.port'))}
      { 
        field: 'destinationPort',
        headerName: `${capitalize(t('dashboard.core.destination_port'))}`,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5
      },
      { 
        field: 'protocol',
        headerName: capitalize(t('dashboard.core.protocol')),
        cellClassName: 'super-app-theme--cell',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5
      },
      { 
        field: 'country',
        headerName: capitalize(t('dashboard.core.country')),
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
        renderCell: renderCellWithTooltip
      },
    ];

    return columns;
}
export const buildGridColumnsWithoutSource = (t: TFunction<"translation", undefined>) => {
    const columns: GridColDef[] = [
      { 
        field: 'date', 
        headerName: capitalize(t('shared.date')),
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
      },
      { 
        field: 'time', 
        headerName: capitalize(t('shared.time')),
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center'
      },
      { 
        field: 'threat', 
        headerName: `${capitalize(t('dashboard.core.threat'))} ${toUpper(t('dashboard.abbreviations.ip'))}`,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
      },
      { 
        field: 'target', 
        headerName: `${capitalize(t('dashboard.core.target'))} ${toUpper(t('dashboard.abbreviations.ip'))}`,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
      },

      { 
        field: 'destination', 
        headerName: `${capitalize(t('dashboard.core.destination'))} ${capitalize(t('dashboard.core.port'))}`,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5
      },
      { 
        field: 'protocol', 
        headerName: capitalize(t('dashboard.core.protocol')),
        headerClassName: 'super-app-theme--header',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 0.5
      }
    ];

    return columns;
}

export const calculateNewOffset = (page : number, rowsPerPage : number) =>{
  return (page * rowsPerPage) - rowsPerPage;
}
