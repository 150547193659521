/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useCallback, useEffect} from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize, toUpper } from 'lodash';
import clsx from 'clsx';
import { useStatusSettings } from '../../../hooks/viewHooks';
import Tooltip from '../../../components/UI/Tooltip';
import { getDefaultValue } from '../../modals/utils/modal-helpers';
import ArrowContainer from '../../../layouts/ArrowContainer';
import CircularDoubleIndicator, { CircularDoubleIndicatorStatusDescriptor } from './CircularDoubleIndicator';
import StatusDescriptor, { StatusDescriptorObj } from './StatusDescriptor';
import {commonCss} from "../../../assets/css/commonCss";
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import { useFetchSystemHealthQuery } from '../store/status-api-slice';
import { useFetchSystemStatsQuery } from '../store/stats-api-slice';
import { useAppSelector, useAppDispatch} from '../../../../../store/hooks';
import { statusContainerUseStyles } from '../assets/systemHealth-styles';
import { setDataReload } from '../../Devices/store/device-slice';




const StatusContainer: React.FC = (): React.ReactElement => {
  
  const { t } = useTranslation();
  const classes = statusContainerUseStyles()();
  const commonClasses = commonCss()();
  const mediaXm = useMediaQuery('(max-width: 960px)');
  const dispatch = useAppDispatch();
  
  //get device data from the store
  // get the data from a deviceBase slice reducer
  const deviceSelected = useAppSelector(state => state.deviceBase.selectedDevice);
  const dataReload = useAppSelector(state => state.deviceBase.dataReloadingActive);
  
  const { data, error, refetch } = useFetchSystemHealthQuery(deviceSelected?.hostname);
  
  const { heartbeat, colorScheme } = useStatusSettings();
  
  // Get data params to fetch attempts by timePeriod
  const timeScale = useAppSelector(state => state.timePeriod);
  const statsParams = `?from=${timeScale.from}&to=${timeScale.to}&hostname=${deviceSelected?.hostname}`;
  // get data from statsApiSlice
  const statsData = useFetchSystemStatsQuery(statsParams);
  
  const handleRefetch = () => {
      refetch();
  }

  // Check if there is an error fetching the data and 
  // dispatch an error message to the store and don't 
  // render the component
  // useEffect(() => {
  //   console.log('Error fetching system health data', isError);
  // }, [isError]);

  useEffect(() => {
    if (dataReload) {
      handleRefetch();
      dispatch(setDataReload(false));
    }
  }, [dataReload]);

  useEffect(() => {
		if (error) {
			// const processedMessage = processMessage(error)
			dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.status.error'))}))
		}
    
		if (statsData.error) {
			// const processedMessage = processMessage(error)
      dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.status.error'))}))
		}
	}, [error, statsData.error]);
  
  const circularStatusObj = useMemo(
    (): CircularDoubleIndicatorStatusDescriptor => ({
      labelOk: capitalize(t('dashboard.status.active')),
      labelNOk: capitalize(t('dashboard.status.offline')),
      labelWarn: capitalize(t('dashboard.status.warning')),
      labelUnknown: toUpper(t('shared.na')),
      labelBottom: capitalize(t('dashboard.status.monitored')),
      labelLeft: capitalize(`${t('dashboard.shared.internal')}:`),
      labelRight: capitalize(`${t('dashboard.shared.external')}:`),
      statusLeft: data?.externalProtection || null,
      statusRight: data?.internalProtection || null,
      statusBottom: data?.monitored || null
    }),
    [data, t]
  );
  
  //labels
  const createStatusObj = useCallback(
    (label : any): StatusDescriptorObj => ({
      labelOk: capitalize(t('dashboard.status.active')),
      labelNOk: capitalize(t('dashboard.status.offline')),
      labelWarn: capitalize(t('dashboard.status.warning')),
      labelUnknown: toUpper(t('shared.na')),
      labelLeft: label,
      labelRight: capitalize(t('dashboard.shared.blocked'))
    }),
    [t]
  );
  
  return (
    <Grid container className={clsx( classes.wrapper, commonClasses.padding)} spacing={2} alignItems="center" alignContent="center">
      <Grid item xs={mediaXm ? 12 : 6} lg={5} className={classes.arrowLeft}>
        <ArrowContainer height={80} flattenLeft>
          <>
            <Tooltip
              positionClassName={clsx(classes.tooltip)}
              size={'SMALL'}
              text={t('shared.tooltips.arrow_external')}
              placement={'bottom'}
            />
            <StatusDescriptor
              value={statsData.isLoading ? ('Loading...') : (statsData.data!.inboundAttempts)}
              status={data?.externalProtection}
              statusDescriptor={createStatusObj(capitalize(t('dashboard.status.externalProtection')))}
              colorSheme={colorScheme}
            />
          </>
        </ArrowContainer>
      </Grid>
      <Grid item xs={12} lg={2} className={classes.circleIndicator}>
        <CircularDoubleIndicator
          data-testid="circleIndicator"
          // If indicator size is hardcoded why wee need it as a prop
          indicatorSize={10}
          desc={getDefaultValue(deviceSelected?.location)}
          // If size is a hardcoded value why we need to pass this as prop?
          size={100} 
          statusDescriptor={circularStatusObj}
          heartBeat={heartbeat}
          colorSheme={colorScheme}
          device={deviceSelected}
        />
      </Grid>
      <Grid item xs={mediaXm ? 12 : 6} lg={5} className={classes.arrowRight}>
        <ArrowContainer height={80} flattenLeft  >
          <>
            <Tooltip
              positionClassName={clsx(classes.tooltip)}
              size={'SMALL'}
              text={t('shared.tooltips.arrow_internal')}
              placement={'bottom'}
            />
            <StatusDescriptor
              statusDescriptor={createStatusObj(capitalize(t('dashboard.status.internalProtection')))}
              value={statsData.isLoading ? 'Loading...' : statsData.data!.outboundAttempts}
              status={data?.internalProtection}
              colorSheme={colorScheme}
            />
          </>
        </ArrowContainer>
      </Grid>
      
    </Grid>
  );
};

export default StatusContainer;
