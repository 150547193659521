import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
// import { notWellDefined } from '../../../interfaces/common';
import { setDataReload } from '../../Devices/store/device-slice';
import { useDataReloadingInfo } from 'src/features/sensor/hooks/deviceHooks';
import { timescale2ISOdates } from '../../../services/timeCalculations/timeScaleTranslation';
import { setTimeScalePeriod } from '../store/time-scale-slice';

type Timer = ReturnType<typeof setInterval>;

export interface DataReloadProps {
  // updateFn: Function;
  // opts: notWellDefined<{}>;
  active: boolean;
  interval: number | string;
  cb?: () => void;
}

//generic component to invoke updateFn periodically with given opts
const DataReload: React.FunctionComponent<DataReloadProps> = ({
  active,
  interval,
}: DataReloadProps): null => {
  const intervalId = useRef<Timer | null>();
  const dispatch = useDispatch();
  const { timePeriod } = useDataReloadingInfo();
  
  // ToDo: Connect this reloading process to the spinner component 

  useEffect(() => {
    
      intervalId.current && clearInterval(intervalId.current);
      const dataReloadInterval: Timer | null = setInterval(() => {
        const timeParams = timescale2ISOdates(timePeriod);
        dispatch(setTimeScalePeriod(timeParams));
        dispatch(setDataReload(active));
      }, interval as number);
      intervalId.current = dataReloadInterval;
    
    return (): void => {
      intervalId.current && clearInterval(intervalId.current);
    };
  }, [active, interval, dispatch, timePeriod]);

  return null;
};

export default DataReload;
