import React, { useRef, useCallback, useMemo, useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CalendarTodayOutlined as CalendarTodayOutlinedIcon } from '@mui/icons-material';
import { capitalize } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { differenceInMinutes } from 'date-fns';
import DropdownButton from '../../../components/UI/DropdownButton';
import CustomDatesModalSelector from './CustomDatesModalSelector';
import { Timescale } from '../../../interfaces/services';
import { commonCss } from '../../../assets/css/commonCss';
import { scaleSelectorUseStyles } from '../assets/timeSelection-styles';


const addLastPrefix = (t: TFunction, label: string, useLastPrefix: boolean): string =>
  useLastPrefix ? `${t('shared.timeDescriptors.last')}  ${label}` : label;

export interface TimeScaleSelectorProps {
  label: string;
  customButtonLabel: string;
  handleChange: Function;
  timePeriod: Timescale;
  dialogLabel: string;
  ampm?: boolean;
  dateFormat?: string;
  useLastPrefix?: boolean;
}

const TimeScaleSelector: React.FunctionComponent<TimeScaleSelectorProps> = ({
  label,
  customButtonLabel,
  dialogLabel,
  handleChange,
  timePeriod,
  ampm,
  dateFormat,
  useLastPrefix = false
}: TimeScaleSelectorProps): React.ReactElement => {
  const classes = scaleSelectorUseStyles()();
  const commonClasses = commonCss()();

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState<boolean>(false);

  const translateName = useCallback(
    (name: string, count: number): string =>
      addLastPrefix(t, `${capitalize(t(name, { count }))}`, useLastPrefix),
    [t, useLastPrefix]
  );

  //buttons definition, count used only for translation purposes
  const timeButtons = useMemo(
    () => [
      { id: 'h', name: 'shared.timeDescriptors.hour', count: 1 },
      { id: 'd', name: 'shared.timeDescriptors.hourCount', count: 24 },
      { id: 'w', name: 'shared.timeDescriptors.week', count: 1 },
      { id: 'm', name: 'shared.timeDescriptors.month', count: 1 },
      { id: '3m', name: 'shared.timeDescriptors.monthCount', count: 3 },
      { id: '6m', name: 'shared.timeDescriptors.monthCount', count: 6 },
      { id: 'y', name: 'shared.timeDescriptors.year', count: 1 }
    ],
    []
  );

  //callback to set selected value
  const handleTimeScaleChange = useCallback(
    (event: React.SyntheticEvent | null, value?: string): void => {
      event && event.preventDefault();
      value && value !== 'custom' && handleChange({ value });
      value && value === 'custom' && setOpen(true);
    },
    [handleChange]
  );

  const handleValueChange = useCallback(
    (value: string): void => {
      handleTimeScaleChange(null, value);
    },
    [handleTimeScaleChange]
  );

  //set custom time period
  const handleCustomSubmit = useCallback(
    (value: Timescale): void => {
      if (value && value.selectedDates && open) {
        const timeDifference: number = differenceInMinutes(
          value.selectedDates?.to,
          value.selectedDates?.from
        );
        if (timeDifference > 0) {
          setOpen(false);
          handleChange(value);
        } else {
          setOpen(false);
          setIsInfoDialogOpen(true);
        }
      }
    },
    [open, handleChange]
  );

  const handleClose = (): void => {
    handleChange({ value: 'h' });
    setOpen(false);
  };

  const handleCloseInfoDialog = (
    event: React.MouseEvent,
    reason?: 'backdropClick' | 'escapeKeyDown'
  ): void => {
    event.preventDefault();
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setIsInfoDialogOpen(false);
  };

  const dropdownButtonRef = useRef();

  const getDropDownLabel = useCallback((): string => {
    if (timePeriod.value === 'custom') {
      return customButtonLabel;
    }
    const selectedButton = timeButtons.find((button) => button.id === timePeriod.value);
    return selectedButton ? translateName(selectedButton.name, selectedButton.count) : label;
  }, [customButtonLabel, label, timeButtons, timePeriod.value, translateName]);

  //generate buttons according to definition
  const children = useMemo((): React.ReactElement[] => {
    const TimescaleButtons: React.ReactElement[] = timeButtons.map((timeButton, index) => (
      <ToggleButton
        key={timeButton.id}
        value={timeButton.id}
        classes={{
          root: classes.toggleButtonDropdown,
          selected: classes.toggleButtonSelected
        }}
        disableRipple
      >
        {translateName(timeButton.name, timeButton.count)}
      </ToggleButton>
    ));
    const customButton = (
      <ToggleButton
        key={'custom'}
        value={'custom'}
        classes={{
          root: classes.toggleButtonDropdown,
          selected: classes.toggleButtonSelected
        }}
        disableRipple
      >
        {customButtonLabel}
      </ToggleButton>
    );
    TimescaleButtons.push(customButton);
    return TimescaleButtons;
  }, [
    timeButtons,
    classes.toggleButtonDropdown,
    classes.toggleButtonSelected,
    customButtonLabel,
    translateName
  ]);

  //error information in case custom dates are provided wrongly
  const InfoDialog = (): React.ReactElement => (
    <Dialog open={isInfoDialogOpen} onClose={handleCloseInfoDialog}>
      <DialogTitle>Info</DialogTitle>
      <DialogContent>
        <Typography>The dates were selected incorrectly</Typography>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary">
            {capitalize(t('shared.actions.ok'))}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  return (
    <React.Fragment>
      <ToggleButtonGroup
        exclusive
        onChange={handleTimeScaleChange}
        value={timePeriod.value}
        classes={{
          root: classes.toggleButtonGroupRootOverride,
          grouped: classes.toggleButtonGroupGroupedOverride
        }}
      >
        <DropdownButton
          dropButtonRef={dropdownButtonRef}
          buttonLabel={getDropDownLabel()}
          handleValueChange={handleValueChange}
          disabledKey={''}
          children={children}
          hideOnSmallDevices
          showContextAsLabel
          className={commonClasses.toolbarButton}
          buttonIcon={<CalendarTodayOutlinedIcon fontSize="small" />}
        />
      </ToggleButtonGroup>
      <CustomDatesModalSelector
        dialogLabel={dialogLabel}
        dateFormat={dateFormat}
        ampm={ampm}
        timePeriod={timePeriod}
        handleSubmit={handleCustomSubmit}
        handleClose={handleClose}
        open={open}
      />
      <InfoDialog />
    </React.Fragment>
  );
};

export default TimeScaleSelector;
