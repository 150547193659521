/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { dashboardCss } from '../features/sensor/assets/css/dashboardCss';
import StatsContainer from '../features/sensor/pages/StatsContainer';
import { useAppSelector } from 'src/store/hooks';
import MemoizedCrisAppWrapper from '../pages/MemoizedCrisAppWrapper';
import DrawerUI from 'src/features/sensor/components/UI/DrawerUI';
import AppBarUI from 'src/features/sensor/components/UI/AppBarUI';

const Dashboard: React.FunctionComponent<{}> = (): React.ReactElement => {
  
  const isCris = useAppSelector(state => state.auth0Slice.isCris);
  const isDashboardUser= useAppSelector(state => state.auth0Slice.isDashboardUser);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const headerVisibility = useAppSelector(state => state.menuSlice.isHeaderVisible);

  const classes = dashboardCss()();

  const toggleMenuHandler = (): void => {
    setIsDrawerOpen((isOpen) => !isOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        {/* left menu */}
        <DrawerUI open={isDrawerOpen} openCloseDrawer={toggleMenuHandler} />
        {/* Menu above */}
        {headerVisibility ? 
          <AppBarUI openCloseDrawer={toggleMenuHandler} open={isDrawerOpen} />
        : null}
        <main className={headerVisibility ? classes.content : classes.cris_content}>
          <Switch>
            {isDashboardUser ?
              <Route exact path="/dashboard">
                <StatsContainer />
              </Route>
              : 
              <Route exact path="/dashboard">
                <h1 style={{display : 'flex', justifyContent: 'center'}}>Welcome</h1>
              </Route>
            }
            {isCris ? 
              <Route path="/cris" >
                <MemoizedCrisAppWrapper />
              </Route> 
            : null}
          </Switch>
        </main>
      </Router>
    </div>
  );
};

export default Dashboard;
