import { createSlice } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../../../../../../store/features/common/common';

export interface StatsApiObj {
  inboundAttempts: number;
  outboundAttempts: number;
  totalAttackers: number;
  totalAttempts: number;
  totalHashes: number;
  totalIps: number;
  totalUrls: number;
}

const initialState = createInitialRequestState<StatsApiObj, {}>({}, {}, {});

const commonRequestReducers = createRequestReducers<StatsApiObj>();

const totalStatsSlice = createSlice({
  name: 'totalStats',
  initialState,
  reducers: {
    totalStatsSetRequestInProgress: commonRequestReducers.setRequestInProgress,
    totalStatsSetRequestError: commonRequestReducers.setRequestError,
    totalStatsSetRequestFulfilled: commonRequestReducers.setRequestFulfilled
  }
});

export const {
  totalStatsSetRequestInProgress,
  totalStatsSetRequestError,
  totalStatsSetRequestFulfilled
} = totalStatsSlice.actions;

export default totalStatsSlice.reducer;
