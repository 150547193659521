import React, { ReactElement } from 'react';

export interface LogoProps {
  logoClass: string;
  containerClass: string;
}

const Logo: React.FunctionComponent<LogoProps> = ({ ...props }: LogoProps): ReactElement => {
  return (
    <div className={props.containerClass}>
      <div className={props.logoClass}> </div>
    </div>
  );
};

export default Logo;
