import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewTypeStatus } from '../../../hooks/viewHooks';
import AttemptsContentBox from './chartContentBox/AttemptsContentBox';
import { AttemptsChartWidget } from '../../../components/charts/AttemptsChartWidget';

export type AttemptType = 'IP' | 'URL' | 'HASH';

export interface ChartBlockedTableContainerData {
  title: string;
  tooltipText: string;
  dataToDisplay: AttemptType;
  chartTooltipText?: string;
  setSize?: React.Dispatch<React.SetStateAction<number | undefined>>;
  outbound?: boolean;
}

const AttemptsByTimeContainer = (): React.ReactElement => {
  
  const isBasicView: boolean = useViewTypeStatus();
  const { t } = useTranslation();

  const basicViewChartMemo: React.ReactElement = useMemo(() => {
    return <AttemptsChartWidget id={`basicViewAttempts`} active />;
  }, []);

  const basicViewData: ChartBlockedTableContainerData = useMemo(
    (): ChartBlockedTableContainerData => {
      return {
        title: t('dashboard.attemptsChart.blocked_ip'),
        tooltipText: t('shared.tooltips.table_blocked_ip'),
        chartTooltipText: t('shared.tooltips.attempts_chart_ip'),
        dataToDisplay: 'IP'
      };
    },
    [t]
  );

  const { tooltipText, title, dataToDisplay, chartTooltipText } = basicViewData;
  const basicViewChartTooltip: string = isBasicView
    ? t('shared.tooltips.attempts_chart_stacked')
    : (chartTooltipText as string);

  //show-hide preprepared components
  const basicViewMemo = useMemo(
    (): React.ReactElement => (
      <AttemptsContentBox
        chartItem={basicViewChartMemo}
        isBasicView={false}
        displacement={80}
        iconSize={0}
        tooltipText={tooltipText}
        title={title}
        chartTooltipText={basicViewChartTooltip}
        dataToDisplay={dataToDisplay}
      />
    ),
    [basicViewChartMemo, tooltipText, title, dataToDisplay, basicViewChartTooltip]
  );

  return (
    <React.Fragment>
      <div>
        {basicViewMemo}
      </div>
    </React.Fragment>
  );
};

export default React.memo(AttemptsByTimeContainer);
