/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { 
  DataGrid,
  GridColDef
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import CustomPagination from '../../Pagination/components/CustomPagination';
import { buildGridColumns } from '../grid-utils/table-utils'
import { buildGridStyles } from '../styles/grid-styles';
import { Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { toUpper } from 'lodash';
import { Block as BlockIcon } from '@mui/icons-material';
import Tooltip from '../../../components/UI/Tooltip';
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';
import { ipGridUseStyles } from './ipDataGrid-styles';
import { capitalize } from "lodash";
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

export interface TableData {
  id: string;
  date: string;
  source: string;
  destination: string;
  sourcePort: number;
  destinationPort: number;
  protocol: string
  time: string;
  country: string;
}

interface IpDataGridProps {
  tableData: TableData[];
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  handlePageChange: (newPage: number) => void;
  total: number;
  outbound? : boolean;
  tooltipText: string;
}


const IpDataGrid: React.FC<IpDataGridProps> = ({tableData, isLoading, error, handlePageChange, total, outbound, tooltipText}) => {

  const classes = ipGridUseStyles()();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeSelected = useAppSelector(state => state.themeSettings.theme);
  const title = t('dashboard.attemptsChart.blocked_ip');

  const mobileBreakpoint = useMediaQuery('(max-width: 768px)');

  const columns: GridColDef[] = buildGridColumns(t, outbound);

  let rows = tableData;

  const customStyles = buildGridStyles()(themeSelected);

  useEffect(() => {
		if(error){
			dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.attemptsChart.error'))}))
		}
	}, [error]);

  return (
    <Box 
    className={customStyles.root} >
      <div className={clsx(classes.headerText)}>
        <div className={clsx(classes.imageWrapper)}>
          <BlockIcon />
        </div>
        <Typography
          component="h3"
          variant={mobileBreakpoint ? 'body1' : 'h5'}
          className={clsx(classes.headerText)}
        >
          {toUpper(title)}
        </Typography>
        <Tooltip
          positionClassName={classes.tooltip}
          size={'SMALL'}
          text={tooltipText}
          placement={'bottom'}
        />
      </div>
      <DataGrid
        sx={{
          border: 0,
        }}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        columns={columns}
        autoHeight
        rows={rows}
        rowCount={total}
        paginationMode="server"
        pagination
        pageSize={15}
        onPageChange={handlePageChange}
        getRowClassName={() =>'row-justify'}
        components={{
          Pagination: CustomPagination,
          LoadingOverlay: LinearProgress,
        }}
        loading={isLoading} />
    </Box>
  )
}

export default IpDataGrid;
