import  makeStyles  from '@mui/styles/makeStyles';

export const blockedTableUseStyles = () : Function => makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  detailsButton: {
    marginTop: 25,
    marginBottom: 25,
    alignSelf: 'center'
  },
  emptyListSpace: {
    paddingBottom: 84
  }
}));
