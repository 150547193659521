import { createSelector } from '@reduxjs/toolkit';
import { notWellDefined } from '../../../../../interfaces/common';
import { ApiError } from '../../../../../interfaces/services';
import { RootState } from '../../../../../../../store/rootReducer';

const getTimeToUpdateMetrics = (state: RootState): string => state.metricsUpdate.data;
// const getTimeToUpdateMetrics = (state: RootState): string => state.dataUpdate.itsTimeToUpdateMetrics;
const getMetricsStartDate = (state: RootState): notWellDefined<Date> => state.metricsUpdate.metricsStartDate;
const getMetricsFloorDate = (state: RootState): notWellDefined<Date> => state.metricsUpdate.metricsFloorDate;
const getMetricsRequestInProgress = (state: RootState): boolean => state.metricsUpdate.inProgress;
export const getMetricsRequestError = (state: RootState): ApiError => state.metricsUpdate.error;

export const getMetricsDatesSelector = createSelector(
  [getMetricsStartDate, getMetricsFloorDate],
  (
    metricsStartDate,
    metricsFloorDate
  ): { metricsStartDate: notWellDefined<Date>; metricsFloorDate: notWellDefined<Date> } => {
    return { metricsStartDate, metricsFloorDate };
  }
);

export const getTimeToUpdateMetricsSelector = createSelector(
  [getTimeToUpdateMetrics],
  (timeToUpdateMetrics): string => {
    return timeToUpdateMetrics;
  }
);

export const getMetricsRequestInProgressSelector = createSelector(
  [getMetricsRequestInProgress],
  (metricsRequestInProgress): boolean => {
    return metricsRequestInProgress;
  }
);

export const getMetricsRequestErrorSelector = createSelector(
  [getMetricsRequestError],
  (metricsRequestError): ApiError => {
    return metricsRequestError;
  }
);

export const getMetricsStartDateSelector = createSelector(
  [getMetricsStartDate],
  (metricsStartDate): notWellDefined<Date> => {
    return metricsStartDate;
  }
);

export const getMetricsFloorDateSelector = createSelector(
  [getMetricsFloorDate],
  (metricsFloorDate): notWellDefined<Date> => {
    return metricsFloorDate;
  }
);
