import React from 'react';
import { useSizeCallback } from '../../../../../hooks/utilityHooks';
import MapDescriptor from './descriptor/MapDescriptor';
import ContentBoxBase from '../../../../../layouts/ContentBoxBase';
import Map from './map/MapWidget';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';


export interface MapContentBoxProps {
  isActive?: boolean;
  displacement: number;
  iconSize?: number;
  compensation?: number;
}

export interface Target {
  id?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  value?: number
}

const MapContentBox: React.FC<MapContentBoxProps> = ({
  isActive = true,
  displacement,
  compensation = 30,
  iconSize = 0
}: MapContentBoxProps): React.ReactElement => {
  const { size: sizeBottom, setSize: setSizeBottom } = useSizeCallback();
  const { t } = useTranslation();
  const toggleDisplacement = useMediaQuery('(max-width: 899px)');
  
  return (
    <ContentBoxBase
      compensation={compensation}
      sizeBottom={sizeBottom || 0}
      topComponent={<Map />}
      displacement={toggleDisplacement ? 80 : 90}
      iconSize={iconSize}
      isActive={isActive}
      bottomComponent={<MapDescriptor setSize={setSizeBottom} mobileThreshold="900px" />}
      tooltipText={t('shared.tooltips.map')}
    />
  );
};

export default MapContentBox;
