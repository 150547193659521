import { createSlice } from '@reduxjs/toolkit';


export interface TokenExpirationState {
    tokenIsExpiring : boolean
}

const initialState: TokenExpirationState = {
  tokenIsExpiring: false,
};

const tokenExpirationSlice = createSlice({
  name: 'tokenExpirationSlice',
  initialState,
  reducers: {
    setTokenIsExpiring(state) {
      state.tokenIsExpiring = true
  },
  }
});

export const { setTokenIsExpiring } = tokenExpirationSlice.actions;
export default tokenExpirationSlice.reducer;
