import { Theme } from "@mui/material";
import  makeStyles  from '@mui/styles/makeStyles';

export const attemptsDetailsUseStyles = (modalLeftDisplacement: number): Function =>
makeStyles((theme : Theme) => ({
  dialogContent: {
    padding: `${theme.spacing(5)} ${theme.spacing(5)} 0px ${theme.spacing(
      5
    )} !important`,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)} !important`,
    },
  },
  dialogButtons: {
    padding: ` 0px ${theme.spacing(5)} ${theme.spacing(
      5
    )} ${theme.spacing(5)}`,
  },
  modalDisplacement: {
    left: `${modalLeftDisplacement}px`,
    [theme.breakpoints.down("lg")]: {
      left: 0,
      margin: 0,
      width: "100%",
    },
  },
}));
