import React from 'react';
import  makeStyles  from '@mui/styles/makeStyles';
import { ArrowContainerProps } from '../interfaces/components';
// import { ITheme } from "../../themes/ITheme";
import { commonCss, defaultBorderWidth } from '../assets/css/commonCss';
import clsx from 'clsx';

const useStyles = (height: number, flattenLeft: boolean): Function =>
    makeStyles((theme : any) => ({
        container: {
            display: 'flex',
            
            width: '100%',
            // margin: '0 auto 0 auto',
            // display: 'flex',
            // justifyContent: 'center'
        },
        containerSmaller: {
            width: '100%',
            margin: '0 auto 0 auto',
            // display: 'flex',
            // justifyContent: 'center'
        },
        containerArrow: {
            position: 'relative',
            borderLeftWidth: !flattenLeft ? '0px' : undefined,
            borderRightWidth: '0px',
            height: `${height}px`,
            paddingLeft: !flattenLeft ? `${height / 2}px` : undefined,
            // background: theme.palette.secondary.dark,
            background: '#005E7D',
            color: theme.palette.secondary.contrastText,
            borderColor: theme.palette.secondary.contrastText,
            zIndex: 1,
            width: '100%',
            // '&::after': {
            //     content: '""',
            //     width: `${height}px`,
            //     height: `${height}px`,
            //     border: 'inherit',
            //     borderRightWidth: `${defaultBorderWidth}px`,
            //     position: 'absolute',
            //     right: '0px',
            //     top: '50%',
            //     transform: 'translate(50%, -50%) rotate(45deg) scale(0.7)',
            //     borderLeftColor: 'transparent',
            //     borderBottomColor: 'transparent',
            //     background: theme.palette.secondary.dark,
            //     zIndex: -1
            // },
            '&::before': !flattenLeft
                ? {
                    content: '""',
                    width: `${height}px`,
                    height: `${height}px`,
                    border: 'inherit',
                    borderRightWidth: `${defaultBorderWidth}px`,
                    position: 'absolute',
                    left: '0px',
                    top: '50%',
                    transform: 'translate(-50%, -50%) rotate(45deg) scale(0.7)',
                    borderLeftColor: 'transparent',
                    borderBottomColor: 'transparent'
                }
                : undefined
        }
    }));

const ArrowContainer: React.FunctionComponent<ArrowContainerProps> = ({
      height,
      flattenLeft = false,
      children
    }: ArrowContainerProps): React.ReactElement => {
    const classes = useStyles(height, flattenLeft)();
    const commonClasses = commonCss()();
    return (
        // style={{ paddingRight: `${height / 2}px` }}
        <div className={clsx(classes.container)} >
            <div className={clsx(classes.containerArrow, commonClasses.defaultBorder)}><>{children}</></div>
        </div>
    );
};

export default ArrowContainer;
