/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useEffect, useState } from "react";
import { debouncedResize } from "../../../services/helpers/helpers";
import { useAppSelector, useAppDispatch } from "../../../../../store/hooks";
import { useFetchMetricsQuery } from '../store/metrics-api-slice';
import { Color, LinearGradient, Pattern, RadialGradient } from '@amcharts/amcharts4/core';
import { capitalize } from 'lodash';
import { injectDataIntoSeries, createXYChartBase,getChartResolution, XyChart, changeLegend, checkMinPeriod, roundDate, weekOffsetFrom, weekOffsetTo} from "../../ChartIP/chart-utils/chart-utils";
import { useTranslation } from "react-i18next";
import { setError } from "src/features/sensor/features/SystemHealth/store/errorSlice";
import { calculateCustomDays } from "../../TimePeriodSelection/utils/timeSelect-utils";
import { pushToPeriodStack, popPeriodFromStack } from "src/features/sensor/features/DataReload/store/period-stack-slice";
import { setTimeScalePeriod } from "src/features/sensor/features/DataReload/store/time-scale-slice";
import { setTimePeriod } from "src/features/sensor/features/Devices/store/device-slice";
import { baseSerialChartStyles } from "src/features/sensor/components/charts/base/BaseSerialChart-styles";
import clsx from 'clsx';

export type AmColor = Color | Pattern | LinearGradient | RadialGradient;

interface FirewallStateChartProps {
	className?: string;
}

const FirewallStateChart: React.FC<FirewallStateChartProps> = ({...props
}: FirewallStateChartProps): React.ReactElement => {

	const deviceData = useAppSelector(state => state.deviceBase);
	const timeScale = useAppSelector(state => state.timePeriod);
	let periodSelected = deviceData.timePeriod.value;
  	if (periodSelected === 'custom') {
		periodSelected = calculateCustomDays(timeScale);
	}
	const resolution = getChartResolution(periodSelected);
	const queryParams = `?from=${timeScale.from}&to=${timeScale.to}&hostname=${deviceData.selectedDevice?.hostname}&resolution=${resolution}`;
	const { data, error} = useFetchMetricsQuery(queryParams);
	const dispatch = useAppDispatch();
	const divRefStacked = useRef<HTMLDivElement>(null);
	const chartId: string = "chartdiv";
	const period = useAppSelector(state => state.periodStackSlice.lastPeriod);
	const stack = useAppSelector(state => state.periodStackSlice.stack);
	const classes = baseSerialChartStyles()()

	// const [chart, setChart] = useState<XyChart>();


	useEffect(() => {
		if(error){
			dispatch(setError({error : true, errorMessage: capitalize(t('dashboard.healthChart.error'))}))
		}
	}, [error]);

	const { t } = useTranslation();
	const chartOpts = {
		xAxisTitle: capitalize(t('dashboard.healthChart.time_period')),
		yAxisTitle: capitalize(t('dashboard.healthChart.current_states')),
		valueY: "currentFirewallStates",
		dateX: "id",
		name: "states",
		type: "SERIES"
	  };

	const [chart, setChart] = useState<XyChart>();

	useEffect(() => {
		const chart = createXYChartBase(chartOpts, divRefStacked.current as HTMLDivElement)
		setChart(chart)
		const xAxis = chart.chart.xAxes.getIndex(0);
		if(xAxis){
		  chart.chart.cursor.behavior = "selectX";
		  chart.chart.cursor.events.on('selectended', zoomEnded)
		}
	}, []);
	useEffect(() => {
		if(chart){
			changeLegend(chart, chartOpts)
			setChart(chart)
		}

	}, [t]);


	const buttonClick = () => {
		dispatch(popPeriodFromStack())

		if(chart){
		  chart.chart.cursor.behavior = "selectX";
		  chart.chart.cursor.events.on('selectended', zoomEnded)
		}
	}

	useEffect(() => {
		if(period){
			dispatch(setTimeScalePeriod(period))
			if(chart) {
				const xAxis = chart.chart.xAxes.getIndex(0);
				if(xAxis){
				  chart.chart.cursor.behavior = "selectX";
				  chart.chart.cursor.events.on('selectended', zoomEnded)
				}
			}
		}
	}, [period]);

	function zoomEnded(ev : any){
		let range = ev.target.xRange;
		let axis = ev.target.chart.xAxes.getIndex(0);
		let from = axis.positionToDate(
			axis.toAxisPosition(range.start)
		  )
		  let to = axis.positionToDate(
			axis.toAxisPosition(range.end)
		  )
		  if(axis.baseInterval.timeUnit === 'week'){
			from.setDate(weekOffsetFrom(from))
			to.setDate(weekOffsetTo(to))
		}

		from = roundDate(from, axis.baseInterval.timeUnit, axis.baseInterval.count)
		to = roundDate(to, axis.baseInterval.timeUnit, axis.baseInterval.count)

		ev.target.chart.cursor.selection.hide()	

		if(to.getTime() - from.getTime() === 0) return

		if(checkMinPeriod(from, to)){
			ev.target.chart.cursor.behavior = "none";
			ev.target.chart.cursor.events.off('selectended')
		}



		const period = {from : from.toISOString(), to: to.toISOString()}


		dispatch(pushToPeriodStack(period))


		dispatch(setTimeScalePeriod(period))
		dispatch(setTimePeriod({value : 'custom', selectedDates : {from: new Date(period.from), to: new Date(period.to)}}))
	  }


	useEffect(() => {
		let isMounted = true;
		if (chart && !chart.chart.isDisposed() && data && isMounted) {
		  const firewallList = data.map((metric, index) => {
				return {
					Time: new Date(metric.time),
					currentFirewallStates: metric.currentFirewallStates,
					id: new Date(metric.time)
				}
		  });
		  
			const filteredObj = firewallList.filter(firewall => firewall.currentFirewallStates > 0);

			if(filteredObj.length === 1) {
				filteredObj.push({Time: new Date(filteredObj[0].id.getTime() + 60000),
					currentFirewallStates: filteredObj[0].currentFirewallStates,
					id: new Date(filteredObj[0].id.getTime() + 60000)})
			}

			injectDataIntoSeries(chart, filteredObj, chartOpts);

		}
		
		return () => {
			isMounted = false;
			chart?.chart.isDisposed();
		}
	}, [data, ]);


	const handleResize = useCallback((): void => {
		if (divRefStacked.current) {
			divRefStacked.current.style.height = `${Math.floor(
				divRefStacked.current.offsetWidth / 5
			)}px`;
		}
	}, []);

	useEffect(() => {
		handleResize();
		const clear = debouncedResize(handleResize);
		return (): void => clear();
	}, [handleResize]);


	return (
		<React.Fragment>
			{stack.length > 1 ? <div className={clsx(classes.circle)} onClick={buttonClick} ><div className={clsx(classes.line)} ></div></div> : ''}
			<div id={chartId} className={props.className} ref={divRefStacked} data-testid="chart"/>
		</React.Fragment>
	);
};

export default React.memo(FirewallStateChart);
