import React from 'react';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { notWellDefined } from '../../../interfaces/common';
import { StatusValue } from '../../Devices/store/device/statusSlice';
import { IndicatorStatusDescriptor, getIndicatorClassForStatus } from '../utils/systHealth-utils';
import { statusDescriptorUseStyles } from '../assets/systemHealth-styles';

export interface StatusDescriptorObj extends IndicatorStatusDescriptor {
  labelLeft: string;
  labelRight: string;
}

export interface StatusDescriptorProps {
  status: notWellDefined<StatusValue>;
  value: string | number;
  statusDescriptor: StatusDescriptorObj;
  colorSheme: keyof PaletteColor;
}

const StatusDescriptor: React.FunctionComponent<StatusDescriptorProps> = ({
  status,
  value,
  statusDescriptor,
  colorSheme,
  ...props
}: StatusDescriptorProps): React.ReactElement => {
  
  const mediaMl = useMediaQuery('(max-width: 1370px) and (min-width: 1280px)');
  const mediaXxs = useMediaQuery('(max-width: 500px)');
  const theme = useTheme();
  const { statusClassObj, label } = getIndicatorClassForStatus(status, statusDescriptor, theme, colorSheme);
  const classes = statusDescriptorUseStyles(statusClassObj)();

  return (
    <Box {...props} className={clsx(classes.container)}>
      <div className={clsx(classes.containerDecriptor, classes.containerDecriptorLeft)}>
        <Typography
          component="div"
          variant={mediaXxs || mediaMl ? 'body2' : 'body1'}
          color="inherit"
          noWrap
          style={{ flexGrow: 1 }}
        >
          {statusDescriptor.labelLeft}
        </Typography>
        <Typography
          component="span"
          className={clsx(classes.statusText, classes.status)}
          variant={'h6' /*mediaXxs || mediaMl ? 'h6' : 'h5'*/}
          color="inherit"
          noWrap
          style={{ flexGrow: 1 }}
        >
          {label}
        </Typography>
      </div>
      <div className={clsx(classes.containerDecriptor, classes.containerDecriptorRight)}>
        <Typography
          component="h1"
          variant={mediaXxs || mediaMl ? 'h6' : 'h5'}
          color="inherit"
          noWrap
          style={{ flexGrow: 1 }}
        >
          {value}
        </Typography>
        <Typography
          component="div"
          variant={mediaXxs || mediaMl ? 'body2' : 'body1'}
          color="inherit"
          noWrap
          style={{ flexGrow: 1 }}
        >
          {statusDescriptor.labelRight}
        </Typography>
      </div>
    </Box>
  );
};

export default StatusDescriptor;
