import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { useTranslation } from 'react-i18next';
import { commonCss } from '../../../assets/css/commonCss';
import { notWellDefined } from '../../../interfaces/common';
import { IndicatorStatusDescriptor, getIndicatorClassForStatus } from '../utils/systHealth-utils';
import { StatusValue } from '../../Devices/store/device/statusSlice';
import Tooltip from '../../../components/UI/Tooltip';
import { DeviceApiObj } from 'src/store/features/user/devicesSlice';
import { menuIndicatorUseStyles } from '../assets/systemHealth-styles';

export interface MenuIndicatorStatus extends IndicatorStatusDescriptor {
  status: notWellDefined<StatusValue>;
}

export interface MenuIndicatorProps {
  label: string;
  statusDescriptor: MenuIndicatorStatus;
  colorSheme: keyof PaletteColor;
  device: DeviceApiObj | null;
}

const MenuIndicator: React.FunctionComponent<MenuIndicatorProps> = ({
  label,
  statusDescriptor,
  colorSheme,
  device,
  ...props
}: MenuIndicatorProps): React.ReactElement => {
  const commonClasses = commonCss()();
  const theme = useTheme();
  const { statusClassObj } = getIndicatorClassForStatus(
    statusDescriptor.status,
    statusDescriptor,
    theme,
    colorSheme
  );
  const classes = menuIndicatorUseStyles(statusClassObj)();
  const { t } = useTranslation();

  return (
    <Box {...props} className={classes.indicatorContainer}>
      <div className={clsx(commonClasses.relative, classes.indicatorWrapper)}>
        <div className={clsx(classes.indicator, commonClasses.absolutelyCentered, classes.indicatorStatus)} />
      </div>
      <div className={clsx(classes.indictorLabel)}>
        <Typography
          component="div"
          variant="body2"
          noWrap
          align="left"
          className={clsx(commonClasses.textColorDark)}
        >
          {device ? device.name : ''}
        </Typography>
        <Typography
          component="div"
          variant="body2"
          noWrap
          align="left"
          className={clsx(commonClasses.textColorDark)}
        >
          {label}
        </Typography>
      </div>
      <Tooltip size={'SMALL'} text={t('shared.tooltips.circular_indicator')} placement={'right-start'} />
    </Box>
  );
};

export default MenuIndicator;
