import { Theme } from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { StatusValue } from '../../Devices/store/device/statusSlice';
import { notWellDefined } from '../../../interfaces/common';

export interface IndicatorStatusDescriptor {
  labelOk: string;
  labelWarn: string;
  labelNOk: string;
  labelUnknown: string;
}

interface IndicatorClassForStatus {
  label: string;
  statusClassObj: CSSProperties;
}


export function getIndicatorClassForStatus(
  status: notWellDefined<StatusValue>,
  statusDescriptor: IndicatorStatusDescriptor,
  theme: Theme,
  version: keyof PaletteColor
): IndicatorClassForStatus {
  let statusClassObj = {
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.grey[500]
  };
  let label = statusDescriptor.labelUnknown;
  switch (status) {
    case 'OK':
      statusClassObj = {
        borderColor: theme.palette.success[version],
        color: theme.palette.success[version],
        backgroundColor: theme.palette.success[version]
        //boxShadow: '0px 0px 6px 2px rgba(31,237,0,1)',
      };
      label = statusDescriptor.labelOk;
      break;
    case 'WARN':
      statusClassObj = {
        borderColor: theme.palette.warning[version],
        color: theme.palette.warning[version],
        backgroundColor: theme.palette.warning[version]
        //boxShadow: '0px 0px 6px 2px rgba(245,228,0,1)',
      };
      label = statusDescriptor.labelWarn;
      break;
    case 'NOK':
      statusClassObj = {
        borderColor: theme.palette.error[version],
        color: theme.palette.error[version],
        backgroundColor: theme.palette.error[version]
        //boxShadow: '0px 0px 6px 2px rgba(217,42,2,1)',
      };
      label = statusDescriptor.labelNOk;
      break;
    default:
  }

  return { statusClassObj, label };
}