import { createSlice } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../../../../../../store/features/common/common';
import { notWellDefined } from '../../../../interfaces/common';
import { PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from '../../../../interfaces/services';
import { IncrementalFullDevUpdateAction, IncrementalUpdateDeviceData } from './actions/commonDeviceActions';

//interface describing how an attempts object is looking like(how we get the information from the worker)
export interface AttemptsApiObj extends Timestamp {
  hostname: string;
  direction: string;
  type: string;
  threat: string;
  target: string;
  country: string;
  details: {
    sourcePort: number;
    sourceIp: string;
    destinationPort: number;
    destinationIp: string;
    protocol: string;
    url: string;
    hashName: string;
    tcpState: string;
  };
}

//update indicator(when this changes we should get new data from the worker)
export interface ItsTimeToUpdate {
  itsTimeToUpdateAttempts: string;
}

export interface AttemptsSliceDates {
  attemptsStartDate: notWellDefined<Date>;
  attemptsFloorDate: notWellDefined<Date>;
}

export interface AttemptsSliceOther {
  refreshInterval: number;
}

export type AttemptsSliceAdditionalProps = AttemptsSliceDates & AttemptsSliceOther;

export interface IncrementalFullAttemptsUpdateStateAction<T = AttemptsApiObj[]>
  extends IncrementalUpdateDeviceData<T>,
  IncrementalFullDevUpdateAction {
  attemptsStartDate: notWellDefined<Date>;
  attemptsFloorDate: notWellDefined<Date>;
}

const initialState = createInitialRequestState<string, AttemptsSliceAdditionalProps>(
  { inbound: 0, outbound: 0 },
  {
    attemptsStartDate: null,
    attemptsFloorDate: null,
    refreshInterval: 0
  },
  ''
);

const commonRequestReducers = createRequestReducers<string>();

const attemptsUpdateSlice = createSlice({
  name: 'attemptsUpdate',
  initialState,
  reducers: {
    setAttemptsRequestInProgress: commonRequestReducers.setRequestInProgress,
    setAttemptsRequestError: commonRequestReducers.setRequestError,
    setAttemptsRequestFulfilled: (state, action: PayloadAction<string>): void => {
      commonRequestReducers.setRequestFulfilled(state, action);
    },
    setAttemptsIncrementalDates: (
      state: typeof initialState,
      action: PayloadAction<AttemptsSliceDates>
    ): void => {
      state.attemptsStartDate = action.payload.attemptsStartDate;
      state.attemptsFloorDate = action.payload.attemptsFloorDate;
    },
    setAttemptsIncrementalRequestFulfilled: (
      state,
      action: PayloadAction<IncrementalFullAttemptsUpdateStateAction<string>>
    ): void => {
      if (action.payload.data)
        commonRequestReducers.setRequestFulfilled(state, { payload: action.payload.data, type: action.type });
      state.attemptsStartDate = action.payload.attemptsStartDate;
      state.attemptsFloorDate = action.payload.attemptsFloorDate;
    }
    // attemptsSetBlockedTablesData: (state, action: PayloadAction<Map<string, any>>) => {
    //   state.aggregatedTableData = action.payload;
    // },
  }
});

export const {
  setAttemptsRequestInProgress,
  setAttemptsRequestError,
  setAttemptsIncrementalDates,
  setAttemptsRequestFulfilled,
  setAttemptsIncrementalRequestFulfilled
} = attemptsUpdateSlice.actions;

export default attemptsUpdateSlice.reducer;
