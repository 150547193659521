import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import cache from "../../../../../services/memoryCache/cache";

export interface CountryAttemptApi {
  period: {
    to: string,
    from: string,
  },
  countries?: {
    [index: string]: number,
  }
}

export const attApiSlice = createApi({
  reducerPath: 'attemptsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_COUNTRIES as string,
    prepareHeaders(headers) {
      const token = cache.get('token');
      headers.set('Authorization', `${token.tokenType} ${token.accessToken}`);

      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchAttempts: builder.query<CountryAttemptApi, string | void>({
        query(queryParams) {
          return `${queryParams}`;
        }
      })
    }
  }
});

export const { useFetchAttemptsQuery } = attApiSlice;