import { CountryAttemptApi } from "../store/attempts-api-slice";
import { latlong, mapData } from './country-definitions';

export interface CountryAttempt {
  id: string;
  latitude?: number;
  longitude?: number;
  name: string;
  value: number
}

interface MaxAndMin {
    id: string, 
    value: number
}

export interface AttemptsGroupedbyCountry {
  attempts?: CountryAttempt[];
  max?: MaxAndMin;
  min?: MaxAndMin;
}



export const buildMapData = (data: CountryAttemptApi) => {

  const countryAttempts: CountryAttempt[] = [];
  const attemptInfoObject: AttemptsGroupedbyCountry = {
    max: { id: '', value: 0 },
    min: { id: '', value: 0 }
  };

  const { countries } = data;
  

  if (countries) {

    for (const key in countries) {
      const countryValue = countries[key];
      if (key as string !== "N/A") {
        if (attemptInfoObject.min?.value === 0) {
          attemptInfoObject.min = { id: key, value: countryValue }
        } else if (attemptInfoObject.min!.value > countryValue) {
          attemptInfoObject.min = { id: key, value: countryValue }
        }
    
        if (countryValue > attemptInfoObject.max!.value) {
          attemptInfoObject.max = { id: key, value: countryValue }
        }
        
        if(key in latlong){
          countryAttempts.push({
            id: key,
            latitude: latlong[key].latitude,
            longitude: latlong[key].longitude,
            name: mapData[key].name,
            value: countryValue
          })
        }
      }
    }
  }
  
  attemptInfoObject.attempts = countryAttempts;
  
  return attemptInfoObject;
}

