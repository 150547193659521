/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { toUpper } from 'lodash';
import { useAppSelector } from 'src/store/hooks';
import { mapDescriptorUseStyles } from './mapDescriptor-styles';
import Tooltip from 'src/features/sensor/components/UI/Tooltip';

export interface MapDescriptorProps {
  mobileThreshold?: string;
  setSize?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

type IpAttemptsData = {
  label: string;
  value: number;
}

const MapDescriptor: React.FunctionComponent<MapDescriptorProps> = ({
  mobileThreshold,
  setSize
}: MapDescriptorProps): ReactElement => {

  const [minValIp, setMinValIp] = React.useState<IpAttemptsData>();
  const { t } = useTranslation();
  const classes = mapDescriptorUseStyles()();
  const rootElement = useRef<HTMLDivElement>(null);

  const stats = useAppSelector(state => state.stats);
  const isWrapp = useMediaQuery('(max-width: 1240px)');

  const IpAttemptsTooltipText: string = t('shared.tooltips.total_threads_IP');
  

  useEffect(() => {
    if (stats) {
      setMinValIp({ label: toUpper(t('dashboard.abbreviations.ip')), value: stats.inboundAttempts });
    }
  }, [stats]);

  useEffect(() => {
    setSize && setSize(rootElement.current?.offsetHeight);
  });

  return (
    <div ref={rootElement}>
      <Grid
        container
        className={classes.container}
      >
        <div className={clsx(classes.title)}>
          {/* <div className={classes.headerContainer}> */}
            <div className={clsx(classes.icon)} />
            <Typography className={clsx(classes.text)} variant="h6" component="div" noWrap={!isWrapp}>
              {toUpper(t('dashboard.core.attacksMap'))}
            </Typography>
            {/* {ipDesc} */}
            <div className={classes.attemptsNumberContainer}>
              <Typography className={classes.text} component="div" variant="h6" >
                {minValIp ? minValIp.value : "N/A"}
              </Typography>
              <Tooltip
                size={'SMALL'}
                positionClassName={clsx(classes.tooltipIP)}
                text={IpAttemptsTooltipText}
                placement={'auto'}>
              </Tooltip>
            </div>
        </div>
      </Grid>
    </div>
  );
};

export default React.memo(MapDescriptor);
