import { CustomSettings } from '../../../store/features/view/customViewSettingsSlice';
import colorCodes from "../colorCodes";

const customSettings: CustomSettings = {
  dashboard: {
    map: {
      type: 'bubble',
      animation: {
        enabled: true,
        type: 'line-pernament',
        disableOnMobile: true,
        mobileThreshold: '700px'
      },
      colors: {
        background: colorCodes.mapBackground,
        main: colorCodes.mapMain,
        heatStart: colorCodes.light.warning.main,
        heatStop: colorCodes.light.error.main,
        animation: '#005e7d',
      },
      numberOfAnimatedLines: 16
    },
    attempts: {
      disableAttemptsDetailsOnMobile: true,
      mobileThreshold: '670px' //modal with attemps details will be disabled below that value
    },
    attemptsChart: {
      colors: {
        background: colorCodes.light.primary.light,
        firstChartColumn: '#007C88',
        secondChartColumn: '#3FAFBB',
        thirdChartColumn: '#9FD7DD',
        color: colorCodes.light.text.hint
      },
      timeAggregats: {
        hourValue: 60,
        dayValue: 15,
        weekValue: 1,
        monthValue: 1,
        threeMonthsValue: 1,
        sixMonthsValue: 1,
        yearValue: 1,
        customValue: {
          timeUnit: 'hour',
          count: 1

        }
      },
      isViewTypeSwitchShown: true
    },
    metricsChart: {
      colors: {
        background: colorCodes.light.primary.light,
        firstChartColumn: '#007C88',
        secondChartColumn: '#3FAFBB',
        thirdChartColumn: '#9FD7DD',
        color: colorCodes.light.text.hint
      },
      timeAggregatsMetrics: {
        hourValue: 60, //seconds
        dayValue: 60, //minutes
        weekValue: 1, //day
        monthValue: 1, //day
        threeMonthsValue: 1, //day
        sixMonthsValue: 1, //day
        yearValue: 1, //day
        customValue: {
          timeUnit: 'hour',
          count: 1
        }
      },
      isViewTypeSwitchShown: true
    },
    status: {
      heartbeat: {
        enabled: true,
        speed: 5 //number in seconds
      },
      colorScheme: 'main'
    }
  }
};

export default customSettings;

