import  makeStyles  from '@mui/styles/makeStyles';


export const useStyles = () : Function => makeStyles(() => ({
  attByLocationContainer: {
    width: '100%',
    marginBottom: '30px'
  },
  metricsContainer: {
    width: '100%'
  },
  marginContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    marginBottom: '40px'
  }
}))