import React, { useRef, useMemo } from 'react';
import clsx from 'clsx';
import  makeStyles  from '@mui/styles/makeStyles';
import { capitalize } from '@mui/material';
import { ITheme } from '../../../themes/ITheme';
import Tooltip from '../components/UI/Tooltip';
import { commonCss } from '../assets/css/commonCss';
import IconBox from '../components/UI/IconBox';

const DIVIDER = 8

const useStyles = (displacement: number, iconSize: number): Function => {

  // console.log(iconSize + (-1 + (displacement/DIVIDER)));
  
  return makeStyles((theme: ITheme) => ({
    topElement: {
      position: 'relative',
      top: `-${iconSize + theme.spacing(1 + (displacement/DIVIDER))}`,
      width: '100%',
      zIndex: theme.zIndex.mobileStepper,
      backgroundClip: 'padding-box',

    },
    bottomElement: {
      position: 'relative',
      top: `-${iconSize + theme.spacing(-1 + (50/DIVIDER))}`,
      // top: `-150px`,
      [theme.breakpoints.down(900)]: {
        // top: `-150px`,
        top: `-${iconSize + theme.spacing(-1 + (50/DIVIDER))}`,
      },
      [theme.breakpoints.up(1200)]: {
        // top: `-150px`,
        top: `-${iconSize + theme.spacing(-1 + (50/DIVIDER))}`,
      }
    },
    tooltip: {
      position: 'absolute',
      zIndex: 1,
      right: -15,
      top: -15
    },
    container: {
      marginRight : '10px',
    },
    hideContainer: {
      display: 'none'
    }
  }))};


export interface ContentBoxBaseProps {
  topComponent: React.ReactElement;
  bottomComponent?: React.ReactElement;
  displacement: number;
  bordered?: boolean;
  iconSize: number;
  tooltipText?: string;
  isActive?: boolean;
  sizeBottom: number;
  compensation: number;
}

const ContentBoxBase: React.FunctionComponent<ContentBoxBaseProps> = ({
  topComponent,
  bottomComponent,
  displacement,
  bordered = false,
  iconSize,
  tooltipText,
  isActive = true,
  sizeBottom,
  compensation,
  ...props
}: ContentBoxBaseProps): React.ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const classes = useStyles(displacement, iconSize)();
  const commonClasses = commonCss()();

  const content = useMemo(
    (): React.ReactElement => (
      <React.Fragment>
        <div
          className={clsx(
            classes.topElement,
            bordered && commonClasses.defaultBorder,
            commonClasses.defaultBorderRadius
          )}
          ref={topRef}
        >
          {tooltipText && (
            <Tooltip
              size={'LARGE'}
              positionClassName={clsx(classes.tooltip)}
              text={capitalize(tooltipText)}
              placement={'bottom'}
            />
          )}
          {topComponent}
        </div>
        {bottomComponent && (
          <div className={clsx(classes.bottomElement)} ref={bottomRef}>
            {bottomComponent}
          </div>
        )}
      </React.Fragment>
    ),
    [topComponent, bottomComponent, tooltipText, classes, bordered, commonClasses.defaultBorder, commonClasses.defaultBorderRadius]
  );

  return (
    <IconBox
      className={clsx(classes.container, !isActive && classes.hideContainer)}
      component={content}
      ref={wrapperRef}
      iconSize={iconSize}
    />
  );
};

export default React.memo(ContentBoxBase);
