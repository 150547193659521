import  makeStyles  from '@mui/styles/makeStyles';
import { ITheme } from '../../../../../../../../themes/ITheme';

export const mapDescriptorUseStyles = (): Function =>
makeStyles((theme: ITheme) => ({
  container: {
    // height: '100px',
    marginBottom: '-10%',
    marginTop : '-3%',
    [theme.breakpoints.up('xl')]: {
      marginBottom: '-2%',
      marginTop : '0%',
    },
    [theme.breakpoints.down(960)]: {
      marginBottom: '-7%',
      marginTop : 0,
    },
    [theme.breakpoints.down(1200)]: {
      marginBottom: '-2%',
      marginTop : 0,
    }
  },
  headerContainer: {
    display: 'flex',
    textAlign: 'center',
    width: 'inherit',
    [theme.breakpoints.up(1200)]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
    // [theme.breakpoints.up(1024)]: {
    //   width: '70%'
    // },
    // [theme.breakpoints.between(678, 1023)]:{
    //   width: '60%'
    // },
    [theme.breakpoints.between(375, 426)]:{
      width: '90%'
    },
    [theme.breakpoints.between(320, 375)]:{
      width: '100%'
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    }
  },
  gridElement: {
    padding: `${theme.spacing(1)}`,
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing(1)} 0`
    },
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  title: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    [theme.breakpoints.up(1200)]: {
      // flexDirection: 'row',
      // justifyContent: 'flex-start',
    },
  },
  imageWrapper: {
    marginRight: theme.spacing(1)
  },
  icon: {
    background: `center / contain no-repeat url(${theme.extraThemeSettings?.icon.map})`,
    width: '50px',
    // height: '50px',
    maxWidth: '20px',
    [theme.breakpoints.up(1900)]: {
      maxWidth: '35px',
    }
  },
  text: {
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    [theme.breakpoints.up(600)]: {
      marginLeft: '6px',
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up(1200)]: {
      fontSize: '1.5rem',
      // paddingRight: '20px'
    },
    [theme.breakpoints.up(1900)]: {
      paddingRight: '30px',
      fontSize: '2rem'
    }

  },
  totalThreats: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up(400)]: {
      marginLeft: '10px',
    },
  },
  ipLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
    // height: '50px',
    // justifyContent: 'flex-start',
    // fontSize: '1rem',
    // [theme.breakpoints.up(600)]: {
    //   fontSize: '1.5rem',
    //   justifyContent: 'flex-start',
    // },
  },
  tooltipIP: {
    // marginLeft: 3,
    display: 'flex',
    alignItems: 'center'
  },
  attemptsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 3,
  }
}));