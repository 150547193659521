import { ThemeOptions } from '@mui/material/styles/createTheme';
import colorCodes from '../colorCodes';

// equal to theme, theme changing mechanism not finished
const theme: ThemeOptions = {
  palette: {
    common: { black: '#000', white: '#fff' },
    // type: 'light',

    primary: colorCodes.light.primary,
    secondary: colorCodes.light.secondary,
    error: colorCodes.light.error,
    warning: colorCodes.light.warning,
    info: colorCodes.light.info,
    success: colorCodes.light.success,

    text: {
      primary: colorCodes.light.text.primary,
      secondary: colorCodes.light.text.secondary,
      disabled: colorCodes.light.text.disabled,
      // hint: colorCodes.light.text.hint
    },

    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: colorCodes.light.background.paper,
      default: colorCodes.light.background.default
    },

    action: {
      active: colorCodes.light.action.active,
      hover: colorCodes.dark.action.hover,
      hoverOpacity: 0.04,
      selected: colorCodes.dark.action.selected,
      disabled: colorCodes.dark.action.disabled,
      disabledBackground: colorCodes.dark.action.disabledBackground
    }
  },

  shape: { borderRadius: 4 },

  // overrides: {
  //   MuiOutlinedInput: {
  //     root: {
  //       '&:hover $notchedOutline': {
  //         borderColor: colorCodes.light.action.hover
  //       }
  //     }
  //   }
  // }
};

export default theme;
