import React from 'react';
import { Paper } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';
import clsx from 'clsx';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IconBoxProps } from '../../interfaces/components';
import Tooltip from './Tooltip';
import {ITheme} from "../../../../themes/ITheme";

const useStyles = (iconSize: number): Function =>
  makeStyles((theme:ITheme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.primary.main,
      width: '100%',
      height: 'auto',
      padding: theme.spacing(4),
      paddingTop: '0',
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 5px;',
      marginTop: '7rem'
    },
    icon: {
      fontSize: `${iconSize}px`,
      [theme.breakpoints.down('md')]: {
        visibility: 'hidden'
      }
    },
    tooltip: {
      position: 'absolute',
      zIndex: 1,
      top: -16,
      right: -16
    }
  }));

const IconBox: React.ForwardRefRenderFunction<HTMLDivElement, IconBoxProps> = (
  { component, iconSize, tooltipText, ...props }: IconBoxProps,
  ref: React.Ref<HTMLDivElement>
): React.ReactElement => {
  const classes = useStyles(iconSize)();

  return (
    <Paper className={clsx(props.className, classes.container)} elevation={0} ref={ref}>
      <DragIndicatorIcon className={clsx(classes.icon)} />
      {tooltipText && (
        <Tooltip
          positionClassName={clsx(classes.tooltip)}
          size={'LARGE'}
          text={tooltipText}
          placement={'bottom'}
        />
      )}
      {component}
    </Paper>
  );
};

export default React.forwardRef(IconBox);
