import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import cache from "../../../../../services/memoryCache/cache";

export interface IpAttempt {
  timestamp: string;
  hostname: string;
  direction: string;
  type: string;
  threat: string;
  target: string;
  country: string;
  details: {
    sourcePort: number;
    sourceIp: string;
    destinationPort: number;
    destinationIp: string;
    protocol: string;
    url: string;
    hasname: string;
    tcpState: string;
  }
}

export interface IPBlockedAttemptApiResp {
  total: number;
  requestPeriod: {
    to: string;
    from: string;
  },
  limit: number;
  offset: number;
  attempts: IpAttempt[],
}

export const ipAttempsApi = createApi({
  reducerPath: 'ipAttempsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ATTEMPTS as string,
    prepareHeaders(headers) {
      const token = cache.get('token');
      if (token) {
        headers.set('Authorization', `${token.tokenType} ${token.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      fetchIpAttempts: builder.query<IPBlockedAttemptApiResp, string | void>({
        query(queryParams) {
          return `${queryParams}`;
        }
      })
    }
  }
});

export const { useFetchIpAttemptsQuery } = ipAttempsApi;