import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
// import { setSelectedDevice } from '../../store/features/device/deviceBaseSilce';
import { setDevice as setSelectedDevice } from '../../features/Devices/store/device-slice';
import { useDispatch } from 'react-redux';
import { capitalize } from '@mui/material';
import { useFetchSensorsQuery } from 'src/features/sensor/features/Devices/store/device-api-slice';
// import Autocomplete from '@mui/material/Autocomplete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { inputDeviceSelectorUseStyles } from './inputDeviceSelector-styles';
import { setError } from 'src/features/sensor/features/SystemHealth/store/errorSlice';

const InputDeviceSelector: React.FunctionComponent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {data, refetch} = useFetchSensorsQuery()
  const classes = inputDeviceSelectorUseStyles()()

  const [device, setDevice] = useState({hostname: '', location: ''});

  const handleInputChange = (event: any) => {
    event.persist();
    setDevice({hostname : event.target.value, location: '' });
  };

  const handleRefreshClick = () => {
    refetch()
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const deviceAux = data?.filter(function(el) {
      return el.hostname === device.hostname;
    })

    dispatch(setSelectedDevice({hostname: device.hostname, location: '', country: ''}));

    //TODO: Uncomment this when the autocomplete is working
    
    if(deviceAux && deviceAux.length > 0){
      dispatch(setSelectedDevice({hostname: device.hostname, location: deviceAux[0].location, country: deviceAux[0].country}));
    }else{
			dispatch(setError({error : true, errorMessage: capitalize(t('shared.deviceSelector.error'))}))
    }
  };

  return (
<div className={classes.container}>
  <form onSubmit={handleSubmit} className={classes.form}>

    {/* TODO: Uncomment this when the autocomplete is working */}
  {/* <Autocomplete
      className={classes.autocomplete}
      freeSolo
      id="autocomplete"
      onChange={handleInputChange}
      disableClearable
      options={ data ? data.map((a) => a.hostname).filter(Boolean) : []}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Change device"
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
        />
      )}
    /> */}
        <TextField
          value={device.hostname}
          label="Change device"
          onChange={handleInputChange}
        />
    <Button type="submit" color="primary" style={{ marginTop: '10px',marginRight: '10px', height: '20px', fontWeight: 'bold' }}>
      Ok
    </Button>
    <Button type="submit" color="primary" style={{ marginTop: '10px', height: '20px' }}>
      <RefreshIcon onClick={handleRefreshClick}/>
    </Button>  
  </form>
</div> 
  );
};

export default InputDeviceSelector;
