import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const Roles = {
	PORTALADMIN: "PORTALADMIN",
  PORTALUSER: "PORTALUSER",
  PORTALCRIS: "CRISCONSULTANT",
  CRISADMIN: "CRISADMIN"
}

export interface userRole {
  userRole : string[]
}

export interface authState {
  isAdmin: boolean,
  isCris: boolean,
  isCrisAdmin: boolean,
  isDashboardUser: boolean
}

const initialState: authState = {
  isAdmin: false,
  isCris:false,
  isCrisAdmin: false,
  isDashboardUser: false
};

const auth0Slice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAdmin(state, action: PayloadAction<userRole>) {
      if(action.payload.userRole.includes(Roles.PORTALADMIN)) state.isAdmin = true
  },
    setCrisAdmin(state, action: PayloadAction<userRole>) {
      if(action.payload.userRole.includes(Roles.CRISADMIN)) state.isCrisAdmin = true
  },
    setCris(state, action: PayloadAction<userRole>) {
      if(action.payload.userRole.includes(Roles.PORTALCRIS)) state.isCris = true
},
    setIsDashboardUser(state, action: PayloadAction<userRole>) {
      if(action.payload.userRole.includes( Roles.PORTALUSER)) state.isDashboardUser = true;
      if(action.payload.userRole.includes(Roles.PORTALADMIN)) state.isDashboardUser = true;
},
  }
});

export const { setAdmin } = auth0Slice.actions;
export const { setCris } = auth0Slice.actions;
export const { setCrisAdmin } = auth0Slice.actions;
export const { setIsDashboardUser } = auth0Slice.actions;
export default auth0Slice.reducer;
