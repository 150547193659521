import {
  Typography,
  Button,
  Theme,
  CSSObject,
  styled
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import  makeStyles  from '@mui/styles/makeStyles';
import { ITheme } from '../../../../themes/ITheme'
import { logoCss } from '../../assets/css/logoCss';
import Logo from 'src/features/mainMenu/Logo';
import MainMenu from 'src/features/mainMenu/MainMenu';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const drawerWidth = 220;

const useStyles = makeStyles((theme: ITheme) => ({
  drawerPaper: {
    position: 'relative',
    height: '100vh',
    whiteSpace: 'nowrap',
    backgroundColor: '#01657F',
    color: '#fff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  closeDropdownButton: {
    top: '10px',
    right: '10px',
    position: 'absolute',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  closeDropdownIcon: {
    color: theme.palette.grey[500],
    fontSize: '26px'
  },
  feedbackButton: {
    background: theme.palette.success.main,
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 30,
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    textTransform: 'none',
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    '& > span': {
      cursor: 'pointer'
    },
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    },
    [theme.breakpoints.down('sm')]: {
      '&:hover': {
        backgroundColor: theme.palette.success.main
      }
    },
  },
}));

const openedMixin = (theme: Theme): CSSObject => {
  return ({
  
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: '0.29s',
  }),
  overflow: 'visible',
  backgroundColor: '#01657F',
  color: '#fff',
})};

const closedMixin = (theme: Theme): CSSObject => {
  return ({
  backgroundColor: '#01657F',
  color: '#fff',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: '0.29s',
  }),
  overflow: 'visible',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})};

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflow: 'visible',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface DrawerUIProps {
  open: boolean;
  openCloseDrawer: (open: boolean) => void;
}

export default function DrawerUI({ open, openCloseDrawer }: DrawerUIProps) {

  const { t } = useTranslation();
  const classes = useStyles();

  const logoClasses = logoCss()();
  
  const toggleMenuHandler = (): void => {
    openCloseDrawer(!open);
  };

  return (
    <Drawer
      className={
        classes.drawerPaper //, !open && classes.drawerPaperClose),
      }
      open={open}
      variant="permanent" >
      {open ? <Logo logoClass={logoClasses.logo} containerClass={logoClasses.containerDrawer} /> : null}
      {open ? <Typography component="h2" variant="body1" style={{ textAlign: 'center' }}>
        {process.env.REACT_APP_LOGO_CONTACT_NUMBER}
      </Typography> : null}
      <IconButton 
              sx={{ 
                position: 'absolute', 
                top: '50vh', 
                right: -22, 
                zIndex: 1000000, 
                width: 40, 
                alignSelf: 'center', 
                height: 40, 
                backgroundColor: '#01657F', 
                transition: 'left 0.29s ease',
                '&:hover': {
                  backgroundColor: '#005065'
                }
              }} onClick={toggleMenuHandler}>
              {!open ? <ArrowForwardIosRoundedIcon sx={{ fontSize: 'medium', color: '#fff' }} /> : <ArrowBackIosRoundedIcon sx={{ fontSize: 'medium', color: '#fff' }} />}
            </IconButton>
      <MainMenu open={open} menuPosition="flow" showDividers linkedList />
      {open ? <Button href="mailto:operations@mmox.nl" variant="text" className={classes.feedbackButton}>
        {t('shared.feedback_button')}
      </Button> : null}
    </Drawer>
  )
}
