import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSizeCallback } from '../../../../hooks/utilityHooks';
import { SystemInfoChartWidget } from '../../../charts/SystemInfoChartWidget';
import ContentBoxBase from '../../../../layouts/ContentBoxBase';
import SystemInfoChartDescriptor from '../../../../features/Metrics/Components/chartDescriptor/SystemInfoChartDescriptorContainer';
export interface SystemInfoContentBoxProps {
  isActive?: boolean;
  displacement: number;
  iconSize?: number;
  compensation?: number;
}

const SystemInfoContentBox: React.FC<SystemInfoContentBoxProps> = ({
  isActive = true,
  displacement
}: SystemInfoContentBoxProps): React.ReactElement => {
  const { size: sizeBottom, } = useSizeCallback();
  const { t } = useTranslation();

  return (
    <ContentBoxBase
      compensation={90}
      sizeBottom={sizeBottom || 0}
      topComponent={<SystemInfoChartWidget active={isActive} />}
      displacement={displacement}
      iconSize={0}
      isActive={isActive}
      bottomComponent={<SystemInfoChartDescriptor />}
      tooltipText={t('shared.tooltips.health_chart')}
    />
  );
};

export default SystemInfoContentBox;
