const colorCodes = {
    mapBackground : "#e6f1f5",
    mapMain : "#3fafbb",

    white: "#ffffff",
    black: "#000000",

    offWhite: "#fafafa",
    whiteBlue: "#e8f1f4",
    blueLight: "#e6f1f5",

    logoBlueLight: "#3fafbb",
    logoDarkBlue: "#01657f",
    logoGreen: "#8dc265",

    lightGrey: "#333438",
    midGrey: "#2b2c31",
    darkGrey: "#1f1e24",

    lightGreen: "#81c784",
    midGreen: "#91c37b", // voor mijzelf: ff checken welke van logo is en 1 van 2 weghalen
    darkGreen: "#388e3c",

    orangeLight: "#ff8a65",
    orange: "#ff6c00",
    red: "#ce2020",

    // random stuff
    otherWhiteBlue: "#E6F1F5", //f

    grey: {
        '50': '#fafafa',
        '100': '#f5f5f5',
        '200': '#eeeeee',
        '300': '#e0e0e0',
        '400': '#bdbdbd',
        '500': '#9e9e9e',
        '600': '#757575',
        '700': '#616161',
        '800': '#424242',
        '900': '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161'
    },

    dark: {
        primary: {light: '#fff', main: '#3fafbb', dark: '#fff', contrastText: '#fff'},
        secondary: {light: '#333438', main: '#2b2c31', dark: '#1f1e24', contrastText: '#fff'},
        error: { light: '#ce202080', main: '#ce2020', dark: '#d32f2f', contrastText: '#fff' },
        warning: { light: '#ffb74d', main: '#ff9800', dark: '#ff6c00', contrastText: 'rgba(255, 255, 255, 0.87)' },
        info: { light: '#3fafbb80', main: '#8dc265', dark: '#1976d2', contrastText: '#fff' },
        success: { light: '#8dc26580', main: '#8dc265', dark: '#388e3c', contrastText: '#fff' },

        text: {
            primary: '#fff',
            secondary: '#000',
            disabled: 'rgba(255,255,255, 0.38)',
            hint: '#fff'
        },

        action: {
            active: 'rgba(255, 255, 255, 0.54)',
            hover: 'rgba(255, 255, 255, 0.04)',
            selected: 'rgba(255, 255, 255, 0.08)',
            disabled: 'rgba(255, 255, 255, 0.38)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)'
        },

        background: {
            paper: '#1f1e24',
            default: '#2b2c31'
        }
    },

    light: {
        primary: {light: '#e6f1f5', main: '#3fafbb', dark: '#01657f', contrastText: '#fff'},
        secondary: {light: '#e6f1f5', main: '#f0f0f0', dark: '#01657f', contrastText: '#01657f'},
        error: {light: '#ce202080', main: '#ce2020', dark: '#d32f2f', contrastText: '#fff'},
        warning: {light: '#ffb74d', main: '#ff9800', dark: '#ff6c00', contrastText: 'rgba(0, 0, 0, 0.87)'},
        info: {light: '#3fafbb80', main: '#8dc265', dark: '#1976d2', contrastText: '#01657f'},
        success: {light: '#8dc26580', main: '#8dc265', dark: '#388e3c', contrastText: '#fff'},

        text: {
            primary: '#01657f',
            secondary: '#000',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: '#333438'
        },

        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.38)',
            selected: 'rgba(0, 0, 0, 0.08)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)'
        },

        background: {
            paper: '#fafafa',
            default: '#fff'
        }
    }
}

export default colorCodes;




