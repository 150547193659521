import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';

export const paginationUseStyles = () : Function => makeStyles((theme : Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: 0,
    '& button': {
      fontSize: '14px'
    }
  },
  links: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    alignSelf: 'center'
  },
  pageLinksContainer: {
    padding: '12px'
  }
}));
