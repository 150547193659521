import { RootState } from '../../../../../../../store/rootReducer';
import { DeviceApiObj } from '../../../../../../../store/features/user/devicesSlice';
import { createSelector } from '@reduxjs/toolkit';
import { Timescale, ApiError } from '../../../../../interfaces/services';
import { notWellDefined } from '../../../../../interfaces/common';
import { StatsApiObj } from '../totalStats';
import { SystemInfoApiObj } from '../statusSlice';
import { ReportsApiObj } from '../reportsSlice';
import { getMetricsRequestError } from './metricsUpdateSelectors';
import { getAttemptsRequestError } from './attemptsUpdateSelectors';
//import { Aggregate, AggregatedAttemptsResultTransformFunction } from '../../../../providers/AggregatedAttemptsProvider';

const selectedDevice = (state: RootState): DeviceApiObj | null => state.deviceBase.selectedDevice;
const totalStatsData = (state: RootState): notWellDefined<StatsApiObj> => state.totalStats.data;
const timeScale = (state: RootState): Timescale => state.deviceBase.timePeriod;
const datatReloading = (state: RootState): boolean => state.deviceBase.dataReloadingActive;
const statusData = (state: RootState): notWellDefined<SystemInfoApiObj> => state.status.data;
const totalStatsApiError = (state: RootState): ApiError => state.totalStats.error;
const statusApiError = (state: RootState): ApiError => state.status.error;
const reportsData = (state: RootState): notWellDefined<ReportsApiObj[]> => state.reports.data;
const reportsApiError = (state: RootState): ApiError => state.reports.error;
const targetCountryCode = (state: RootState): notWellDefined<string> =>
  state.deviceBase.selectedDevice?.country;
const isSpinnerActive = (state: RootState): notWellDefined<boolean> => state.deviceBase.isSpinnerActive;

export interface UdpadeDataError {
  error: Error;
  isError: boolean;
  isAuthError: boolean;
  isClientError: boolean;
  isSettingsError: boolean;
}

export interface SingleUpdateDataError {
  error: Error;
  isError: boolean;
  errorMessage: string;
}

export const updateDeviceDataApiErrorSelector = createSelector(
  [getAttemptsRequestError, totalStatsApiError, statusApiError, getMetricsRequestError],
  (attemptsError, totalStatsError, statusError): UdpadeDataError => {
    return {
      error: new Error(
        `attempts: ${attemptsError.errorMsg}\n totalStats: ${totalStatsError.errorMsg}\n status: ${statusError.errorMsg}`
      ),
      isError: attemptsError.isError || totalStatsError.isError || statusError.isError,
      isAuthError: attemptsError.isError,
      isClientError: totalStatsError.isError,
      isSettingsError: statusError.isError
    };
  }
);

export const totalStatsApiErrorSelector = createSelector(
  totalStatsApiError,
  (totalStatsError): SingleUpdateDataError => {
    return {
      error: new Error(`totalStats: ${totalStatsError.errorMsg}\n`),
      isError: totalStatsError.isError,
      errorMessage: 'dashboard.sync.total_stats'
    };
  }
);

export const statusErrorSelector = createSelector(
  statusApiError,
  (statusError): SingleUpdateDataError => {
    return {
      error: new Error(`status: ${statusError.errorMsg}\n`),
      isError: statusError.isError,
      errorMessage: 'dashboard.sync.status'
    };
  }
);

export const reportsDataSelector = createSelector(reportsData, reports => {
  return reports;
});

export const reportsApiErrorSelector = createSelector(reportsApiError, reportsError => {
  return reportsError;
});

export const selectedDeviceSelector = createSelector(selectedDevice, device => {
  return device;
});

export const timeScaleSelector = createSelector(timeScale, timePeriod => {
  return timePeriod;
});

export const dataReloadingInfoSelector = createSelector(
  [selectedDeviceSelector, timeScale, datatReloading],
  (device, timePeriod, dataReloadingActive) => {
    return { device, timePeriod, dataReloadingActive };
  }
);

export const totalStatsDataSelector = createSelector(totalStatsData, totalStats => {
  return totalStats;
});

export const statusDataSelector = createSelector(statusData, status => {
  return status;
});

export const targetCountryCodeSelector = createSelector(targetCountryCode, countryCode => {
  return countryCode;
});

export const isSpinnerActiveSelector = createSelector(isSpinnerActive, spinnerActive => {
  return spinnerActive;
});
