/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import clsx from 'clsx';
import {
  Dashboard as DashboardIcon,
  Security as SecurityIcon,
  MeetingRoom as LogoutIcon
} from '@mui/icons-material';
import { capitalize } from 'lodash';
import { MainMenuProps } from '../sensor/interfaces/components';
import { useTranslation } from 'react-i18next';
import { commonCss } from '../sensor/assets/css/commonCss';
import { mainMenuUseStyles } from './assets/mainMenu-styles';
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { useEffect } from 'react';
import { setHeaderVisibility } from './store/menu-slice'; 

export interface MenuItemProto {
  path: string;
  icon: React.FunctionComponent;
  text: string;
}

//menu items definition list
//path used by react-router
export const createListItemsProto = (t: Function, open: boolean): MenuItemProto[] => [
  {
    path: '/dashboard',
    icon: DashboardIcon,
    text: open ? capitalize(t('dashboard.menu.dashboard')) : ''
  },
  {
    path: '/cris',
    icon: SecurityIcon,
    text: open ? 'CRIS' : ''
  },
  // This options will be useful in the next features to come
  // {
  //   path: '/dashboard/reports',
  //   icon: AssignmentIcon,
  //   text: capitalize(t('dashboard.menu.reports'))
  // },
  // {
  //   path: '/dashboard/services',
  //   icon: SecurityIcon,
  //   text: capitalize(t('dashboard.menu.services'))
  // }
  /*{
    path: '/Dashboard/Settings',
    icon: SettingsIcon,
    text: t('Settings'),
  },*/
];

const MainMenu: React.FunctionComponent<MainMenuProps> = ({
  menuPosition,
  showDividers,
  linkedList,
  open,
  ...props
}: MainMenuProps): React.ReactElement => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const classes = mainMenuUseStyles(open)();
  const commonClasses = commonCss()();
  const listItemsProto = createListItemsProto(t, open);
  const { logout } = useAuth0();
  const isCris = useAppSelector(state => state.auth0Slice.isCris)
  const isDashboardUser = useAppSelector(state => state.auth0Slice.isDashboardUser)

  const testListItems = listItemsProto.filter((item) => (item.path === '/dashboard' && isDashboardUser) || (item.path === '/cris' && isCris))
  .map((listItem) => {
    const DynamicIcon = listItem.icon;
    return {
      path: listItem.path,
      children: (
        <ListItem button key={listItem.text}>
          <ListItemIcon className={clsx(classes.iconBackground)}>
            <DynamicIcon />
          </ListItemIcon>
          <ListItemText primary={listItem.text} />
        </ListItem>
      )
    }
  });

  //clean all the tokens and logout
  const handleLogOut = useCallback(() => {
    logout();
  }, [logout]);

  const history = useLocation();

  useEffect(() => {
    const path = history.pathname;
    if (path === '/cris') {
      dispatch(setHeaderVisibility(false));
    } else {
      dispatch(setHeaderVisibility(true));
    }
  }, [history.pathname]);

  //logout component
  const temporaryLogOut = useMemo((): React.ReactElement => {
    return (
      <ListItem button className={classes.logoutButton} onClick={handleLogOut}>
        <ListItemIcon className={classes.iconBackground}>
          <LogoutIcon />
        </ListItemIcon>
        {open ? <ListItemText primary={'Log out'} /> : null}
      </ListItem>
    );
  }, [classes.iconBackground, classes.logoutButton, handleLogOut]);

  //render menu items based on createListItemsProto
  return (
    <Box
      className={clsx(
        menuPosition === 'middle' && classes.verticallyCenteredNoFlex,
        commonClasses.fillerX,
        menuPosition === 'flow' && classes.menuMargin,
        classes.menuListContainer
      )}
      {...props}
    >
      <List className={commonClasses.fillerX}>
        {testListItems.map((elem) => {
          if (linkedList && elem.path) {
            return (
              <Link 
                key={elem.path} 
                className={classes.linkReset} 
                to={elem.path}
                // onClick={(ev) => handleRouteSelection(ev, elem.path)}
              >
                {elem.children}
              </Link>
            );
          } else {
            return elem.children;
          }
        })}
        {temporaryLogOut}
      </List>
    </Box>
  );
};

export default MainMenu;
