import { useMediaQuery, Grid } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAttemptsInfo } from '../../../../hooks/viewHooks';
import MapDataProvider from '../MapDataProvider';
import { AttemptsFilterObj } from '../../../../interfaces/components';
import MapContentBox from './mapContentBox/MapContentBox';
import IconBox from '../../../../components/UI/IconBox';
import AttemptsByLocation from './countryListWidget/AttemptsByLocation';
import AttemptsDetailsModal from '../../../modals/components/AttemptsDetailsModal';
import { attemptsByCountryUseStyles } from './attemptsByCountry-styles';

export interface AttemptsByCountryContainerProps {
  modalLeftDisplacement?: number;
}

const AttemptsByCountryContainer: React.FunctionComponent<AttemptsByCountryContainerProps> = ({
  modalLeftDisplacement = 0
}: AttemptsByCountryContainerProps): React.ReactElement => {
  const { t } = useTranslation();
  const breakpoint1200px = useMediaQuery('1200px');
  const breakpoint900px = useMediaQuery('900px');
  const displacement = breakpoint900px ? 90 : 0;
  const classes = attemptsByCountryUseStyles(displacement, breakpoint1200px)();
  const openModalRef: React.MutableRefObject<((filter: AttemptsFilterObj) => void) | undefined> = useRef();
  const { disableAttemptsDetailsOnMobile: disableOnMobile, mobileThreshold } = useAttemptsInfo();

  //Align items baseline makes every grid children have its own height, this way we can have the country list matching his own data grid height 
  return (
    <MapDataProvider >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="baseline"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item className={classes.mapGridContainer}>
          <MapContentBox displacement={displacement} />
        </Grid>

        <Grid item className={classes.sideBox}  >
          <IconBox
            tooltipText={t('shared.tooltips.attempts_country_codes_list')}
            component={
              <AttemptsByLocation
                disableOnMobile={disableOnMobile}
                mobileThreshold={mobileThreshold}
                clickHandlerRef={openModalRef}
                displacement={90}
              />
            }
            className={classes.sideBox}
            iconSize={0}
          />
        </Grid>
      </Grid>
      <AttemptsDetailsModal
        disableOnMobile={disableOnMobile}
        setOpenRef={openModalRef}
        modalLeftDisplacement={modalLeftDisplacement}
        mobileThreshold={mobileThreshold}
      />
    </MapDataProvider>
  );
};

export default React.memo(AttemptsByCountryContainer);
