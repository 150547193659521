import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';

export const statsContainerUseStyles = () : Function =>  makeStyles((theme : Theme) => ({
  wrapper: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.grey[200],
    width: '100%',
    height: '100%'
  },
  container: {
    marginBottom: theme.spacing(3)
  },
  gridContainer: {
    // marginRight: theme.spacing(2)
    display: 'flex',
    justifyContent: 'center'
  },
  adBox: {
    minHeight: '100px'
  },
  gridPaddingRightReset: {
    paddingRight: 0
  },
  gridPaddingLeftReset: {
    paddingLeft: 0
  }
}));

export const themeSettingsUseStyles = () : Function => makeStyles((theme : Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  gridContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  switchButton: {
    fontSize: '1px',
    color: theme.palette.primary.main
  },
  modalContentContainer: {
    // minWidth: '20vw',
    backgroundColor: theme.palette.background.paper
  },
  settingsButton: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
}));
