import { MetricsApiObj } from '../store/metrics-api-slice'


export function getLatestData(data : MetricsApiObj[]) {
    return   {
        activeMemory : data[data.length -1].activeMemory,
        currentFirewallStates : data[data.length -1].currentFirewallStates,
        diskFree: String(data[data.length -1].diskFree),
        load_1: data[data.length -1].load_1,
        uptime: data[data.length -1].uptime,
        tlfVersion: data[data.length-1].tlfVersion,
        time: data[data.length-1].time,
        latestUpdate: data[data.length-1].latestUpdate,
        freeMemory: data[data.length -1].freeMemory,
        activationDate : "",
      }
    }