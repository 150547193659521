import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { commonCss } from '../../assets/css/commonCss';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import  makeStyles  from '@mui/styles/makeStyles';
import { CoverSpinnerProps } from '../../interfaces/components';

const useStyles = (selectedType: string): Function =>
  makeStyles((theme : Theme) => ({
    cover: {
      background: theme.palette.grey[200],
      opacity: '0.8',
      'z-index': theme.zIndex.drawer,
      position: selectedType === 'full' ? 'fixed' : 'absolute',
      top: 0,
      left: 0,
      padding: 0,
      margin: 0,
      width: selectedType === 'full' ? '100vw' : '100%',
      height: selectedType === 'full' ? '100vh' : '100%',
      overflow: 'hidden'
    }
  }));

const CoverSpinner: React.FunctionComponent<CoverSpinnerProps> = ({
  type,
  size,
  ...props
}: CoverSpinnerProps): React.ReactElement => {
  const selectedType = type || 'full';
  const classes = useStyles(selectedType)();
  const commonClasses = commonCss()();

  return (
    <div
      className={clsx(
        selectedType === 'full' ? commonClasses.stretcherX : commonClasses.fillerX,
        selectedType === 'full' ? commonClasses.stretcherY : commonClasses.fillerY,
        classes.cover
      )}
      {...props}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        className={clsx(commonClasses.fillerY, commonClasses.marginReset)}
        spacing={2}
      >
        <Grid item xs={12}>
          <div className={clsx(commonClasses.centerText, commonClasses.opacityReset)}>
            <CircularProgress size={size} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CoverSpinner;
