import { createSlice } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../../../../../../store/features/common/common';
import { notWellDefined } from '../../../../interfaces/common';
import { PayloadAction } from '@reduxjs/toolkit';
import { IncrementalFullDevUpdateAction, IncrementalUpdateDeviceData } from './actions/commonDeviceActions';
import { Time } from '../../../../interfaces/services';

export interface ItsTimeToUpdateMetrics {
  itsTimeToUpdateMetrics: string;
}

export interface AttemptsSliceAdditionalProps {
  metricsStartDate: notWellDefined<Date>;
  metricsFloorDate: notWellDefined<Date>;
}

export interface MetricsApiObj extends Time {
  activeMemory: number;
  currentFirewallStates: number;
  freeMemory: number;
  latestUpdate: string;
  load_1: number;
  load_5: number;
  load_15: number;
  maximumFirewallStates: number;
  pfsenseVersion: string;
  diskFree: string;
  squidOk: boolean;
  pfOk: boolean;
  temperature: number;
  tlfVersion: string;
  totalBlacklistIp: number;
  totalBlacklistUrl: number;
  totalBlacklistHash: number;
  uptime: number;
  activationDate: any;
}

export interface MetricsSliceDates {
  metricsStartDate: notWellDefined<Date>;
  metricsFloorDate: notWellDefined<Date>;
}

export interface MetricsSliceOther {
  refreshInterval: number;
  resolution: notWellDefined<string>;
}

export type MetricsSliceAdditionalProps = MetricsSliceDates & MetricsSliceOther;

//ToDo change to a type
export interface IncrementalMetricsDevUpdateStateAction<T = MetricsApiObj[]>
  extends IncrementalUpdateDeviceData<T>,
  IncrementalFullDevUpdateAction {
  metricsStartDate: notWellDefined<Date>;
  metricsFloorDate: notWellDefined<Date>;
  refreshInterval: number;
}

const initialState = createInitialRequestState<string, MetricsSliceAdditionalProps>(
  { inbound: 0, outbound: 0 },
  {
    metricsStartDate: null,
    metricsFloorDate: null,
    resolution: '',
    refreshInterval: 0
  },
  ''
);

const commonRequestReducers = createRequestReducers<string>();

const metricsUpdateSlice = createSlice({
  name: 'metricsUpdate',
  initialState,
  reducers: {
    setMetricsRequestInProgress: commonRequestReducers.setRequestInProgress,
    setMetricsRequestError: commonRequestReducers.setRequestError,
    setMetricsRequestFulfilled: (state, action: PayloadAction<string>): void => {
      commonRequestReducers.setRequestFulfilled(state, action);
    },
    setMetricsIncrementalDates: (
      state: typeof initialState,
      action: PayloadAction<MetricsSliceDates>
    ): void => {
      state.metricsStartDate = action.payload.metricsStartDate;
      state.metricsFloorDate = action.payload.metricsFloorDate;
    },
    setMetricsIncrementalRequestFulfilled: (
      state,
      action: PayloadAction<IncrementalMetricsDevUpdateStateAction<string>>
    ): void => {
      if (action.payload.data)
        commonRequestReducers.setRequestFulfilled(state, { payload: action.payload.data, type: action.type });
      state.metricsStartDate = action.payload.metricsStartDate;
      state.metricsFloorDate = action.payload.metricsFloorDate;
      state.refreshInterval = action.payload.refreshInterval;
    }
  }
});

export const {
  setMetricsRequestInProgress,
  setMetricsRequestError,
  setMetricsIncrementalDates,
  setMetricsRequestFulfilled,
  setMetricsIncrementalRequestFulfilled
} = metricsUpdateSlice.actions;

export default metricsUpdateSlice.reducer;
