import { differenceInDays } from 'date-fns';
import { timeValues } from '../../../interfaces/services';
import { Timescale } from '../../DataReload/store/time-scale-slice';
import { getPeriodForCustom } from '../../../services/timeCalculations/timeScaleTranslation';

export const calculateCustomDays = (timePeriod: Timescale) => {

  let period: timeValues =  'h';

  let startDate = new Date(timePeriod.from);
  let endDate = new Date(timePeriod.to);
  const timeDiff = differenceInDays(endDate, startDate);
  const timeScaleValue = getPeriodForCustom(timeDiff);
  period = timeScaleValue;

  return period;
}

export const formatDate = (date: string) => {
  if(date === "") return ""
  const d = new Date(date);
  const formatedDate = d.toLocaleString('nl-NL',{day:'numeric', year: 'numeric', month: 'numeric', hour:'2-digit', minute:'2-digit', timeZone: 'CET', hour12: false})

  const cleanedDate = formatedDate.replace(',', '');
  return cleanedDate;
}