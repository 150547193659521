import { useAuth0 } from "@auth0/auth0-react";

function Auth0LoginPage(){

    const { loginWithRedirect, isAuthenticated } = useAuth0();

    if(!isAuthenticated) loginWithRedirect()

    return (<></>)

}

export default Auth0LoginPage