import { createSlice } from "@reduxjs/toolkit";
import { statsApiSlice, SystemStatsAPI } from './stats-api-slice';

const initialState: SystemStatsAPI = {
  inboundAttempts: 0,
  outboundAttempts: 0,
  totalAttackers: 0,
  totalAttempts: 0,
  totalThreatList: 0,
}

const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      statsApiSlice.endpoints.fetchSystemStats.matchFulfilled,
      (state, {payload}) => {
        state.inboundAttempts = payload.inboundAttempts;
        state.outboundAttempts = payload.outboundAttempts;
        state.totalAttackers = payload.totalAttackers;
        state.totalAttempts = payload.totalAttempts;
        state.totalThreatList = payload.totalThreatList;
      }
    )
  },
});

export default statsSlice.reducer;