import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../common/common';
import { Token, Callback, StateApiObj } from '../../../features/sensor/interfaces/services';
import { AppThunk } from '../../store';
import {
  clientSetRequestInProgress,
  clientSetRequestError,
  clientSetRequestFulfilled,
  setCurrentUserByName
} from './clientSlice';
import {
  userSettingsSetRequestInProgress,
  userSettingsSetRequestError,
  userSettingsSetRequestFulfilled
} from './userSettingsSlice';
import {
  auth,
  getCustomerInfoApi,
  getUserPreferencesApi,
  refreshToken,
} from '../../../features/sensor/services/remoteApi/clientApi';
import cache from '../../../services/memoryCache/cache';
import allSettled from 'promise.allsettled';
import { execCallback } from '../../../features/sensor/services/helpers/helpers';

export interface Credentials {
  userName: string;
  pass: string;
}

export type AuthState = StateApiObj<Token> & {
  userLogged: boolean;
};

const initialState = { ...createInitialRequestState<Token, {}>({}, {}, {}), userLogged: false };

const commonRequestReducers = createRequestReducers<Token>();

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSetRequestInProgress: commonRequestReducers.setRequestInProgress,
    authSetRequestError: commonRequestReducers.setRequestError,
    authSetRequestFulfilled: commonRequestReducers.setRequestFulfilled,
    setUserLogged(state, action: PayloadAction<boolean>): void {
      state.userLogged = action.payload;
    },
    refreshTokenSetPostInProgress: commonRequestReducers.setPostRequestInProgress,
    refreshTokenSetPostError: commonRequestReducers.setPostRequestError
  }
});

export const {
  authSetRequestInProgress,
  authSetRequestError,
  authSetRequestFulfilled,
  setUserLogged,
  refreshTokenSetPostInProgress,
  refreshTokenSetPostError
} = authSlice.actions;

export default authSlice.reducer;

const actionMap = [
  {
    name: 'client' as const,
    fulfill: clientSetRequestFulfilled,
    error: clientSetRequestError
  },
  {
    name: 'settings' as const,
    fulfill: userSettingsSetRequestFulfilled,
    error: userSettingsSetRequestError
  }
];

//ToDo, split
//auth action
export const authenticate =
  (token?: Token, credentials?: Credentials, cb?: Callback<Token>): AppThunk =>
  async (dispatch): Promise<void> => {
    //start auth process
    dispatch(authSetRequestInProgress(true));

    //empty array that is holding all the promises(api calls that are called in the same time)
    const promises: Promise<any>[] = [];
    let authSuccesfull = false;
    try {
      //If token provided use simple auth, if not get the token by credentials
      let authResult = token ? token : credentials ? await auth(credentials) : null;
      authSuccesfull = true;
      if (authResult) {
        //get user initial data
        //api call for getting the info over the client
        promises.push(getCustomerInfoApi(authResult));

        //api call for getting the user preferences
        promises.push(getUserPreferencesApi(authResult));
        //get devices on modal open (token)
        dispatch(clientSetRequestInProgress(true));
        dispatch(userSettingsSetRequestInProgress(true));
        
        //we get the information over the clients, what roles they have and the devices that they can see
        //the api calls are resolved both in the same time
        const results = await allSettled(promises);
        
        //we check if we receive the info over the clients and the result is 'fulfilled'
        //if the status of the result is fulfilled we dispatch fulfill as an action
        results.forEach((result, idx) => {
          const action = actionMap[idx];
          result.status === 'fulfilled'
            ? dispatch(action.fulfill(result.value))
            : dispatch(action.error({ isError: true, errorMsg: result.reason as string }));

          //if we dont have the information we dispatch and error
          if (result.status !== 'fulfilled' && action.name === 'client') {
            authSuccesfull = false;
            if (token && credentials) authenticate(undefined, credentials, cb);
            dispatch(authSetRequestError({ isError: true, errorMsg: result.reason as string }));
            execCallback(cb, new Error(result.reason as string));
          }
        });

        //we decode the jwt to get the user name of the client to set him as an admin or not
        const parseJwt = JSON.parse(atob(authResult.accessToken.split('.')[1]));
        dispatch(setCurrentUserByName(parseJwt.username));

        //if the auth is successful we store the token and dispatch the success
        if (authSuccesfull) {
          //store token and dispatch success if no errors
          if(token) authResult = await refreshToken(token)

          if(authResult){
            token = authResult

            cache.put('token', authResult);
            credentials?.userName && cache.put('userName', credentials.userName);

            //we dispatch the request of being authenticated as successful
            dispatch(authSetRequestFulfilled(authResult));

          //we dispatch the user as being logged
            dispatch(setUserLogged(true));
            if (credentials) {
            dispatch(setCurrentUserByName(credentials.userName));
            }
            if (cb && typeof cb === 'function') cb(undefined, authResult);
          }
        }
      } else {
        dispatch(authSetRequestError({ isError: true, errorMsg: '' /*TODO add err msg */ }));
        execCallback(cb, new Error(/*TODO add err msg */));
      }
    } catch (authException) {
        dispatch(authSetRequestError({ isError: true, errorMsg: (authException as Error).message }));
        if (cb && typeof cb === 'function') cb(authException as Error, undefined);
    }
  };
