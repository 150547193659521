import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createRequestReducers, createInitialRequestState } from '../common/common';
import { Token } from '../../../features/sensor/interfaces/services';
import { AppThunk } from '../../store';
import { notWellDefined } from '../../../features/sensor/interfaces/common';
import { getUserPreferencesApi, postDefaultDevice } from '../../../features/sensor/services/remoteApi/clientApi';

export type Language = 'EN' | 'NL';

export interface DefaultPreferences {
  defaultDevice: notWellDefined<string>;
  language: string;
  theme: string;
}

export interface PreferenceParameters extends DefaultPreferences {
  id?: number;
}

export const createUserSettingsObj = (settings: Partial<PreferenceParameters>): PreferenceParameters => {
  return {
    id: settings?.id,
    theme: settings?.theme || (process.env.REACT_APP_DEFAULT_THEME as string),
    language: settings?.language || (process.env.REACT_APP_DEFAULT_LANGUAGE as string),
    defaultDevice: settings?.defaultDevice
  };
};

const initialState = createInitialRequestState<PreferenceParameters, {}>({}, createUserSettingsObj({}), {});

const commonRequestReducers = createRequestReducers<PreferenceParameters>();

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    userSettingsSetRequestInProgress: commonRequestReducers.setRequestInProgress,
    userSettingsSetRequestError: commonRequestReducers.setRequestError,
    userSettingsSetRequestFulfilled: commonRequestReducers.setRequestFulfilled,
    userSettingsSetPostRequestInProgress: commonRequestReducers.setPostRequestInProgress,
    userSettingsSetPostRequestError: commonRequestReducers.setPostRequestError,
    setUserSettings(state, action: PayloadAction<PreferenceParameters>): void {
      state.data = action.payload;
    },
    changeLanguage(state, action: PayloadAction<Language>): void {
      if (state.data) state.data.language = action.payload;
    }
  }
});

export const {
  userSettingsSetRequestInProgress,
  userSettingsSetRequestError,
  userSettingsSetRequestFulfilled,
  userSettingsSetPostRequestInProgress,
  userSettingsSetPostRequestError,
  setUserSettings,
  changeLanguage
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;

export const fetchUserSettings =
  (token: Token): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(userSettingsSetRequestInProgress(true));
    try {
      const result = await getUserPreferencesApi(token);
      dispatch(userSettingsSetRequestFulfilled(result));
    } catch (e) {
      const fetchErr: Error = e as Error;
      dispatch(userSettingsSetRequestError({ isError: true, errorMsg: fetchErr.message }));
    }
  };

export const postUserSettings =
  (userSettings: DefaultPreferences, token: Token): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(userSettingsSetPostRequestInProgress(true));
    dispatch(setUserSettings(userSettings));
    try {
      await postDefaultDevice(token, userSettings);
      dispatch(userSettingsSetPostRequestInProgress(false));
    } catch (e) {
      const postErr: Error = e as Error;
      dispatch(userSettingsSetPostRequestError({ isError: true, errorMsg: postErr.message }));
    }
  };
