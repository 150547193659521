import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';

export const attemptsByLocationUseStyles = (displacement: number, disableOnMobile: boolean): Function =>
makeStyles((theme : Theme) => ({
  header: {
    textTransform: 'uppercase',
    padding: `0 ${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(4)}`,
    // marginTop: '-10px',
    color: theme.palette.text.primary,
  },
  // content : {
  //   position: 'relative',
  //   top: `-${0 + theme.spacing(1) + displacement}px`,
  // },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      cursor: 'pointer',
      [theme.breakpoints.down(670)]: {
        backgroundColor: disableOnMobile ? 'inherit' : theme.palette.secondary.light,
        cursor: disableOnMobile ? 'inherit' : 'pointer'
      }
    }
  },
  tableCell: {
    color: theme.palette.text.primary,
    padding: '5px 30px',
    border: 0
  },
  pagination: {
    width: '100%',
    '& p': {
      display: 'none'
    }
  },
  paginationToolbar: {
    padding: 0
  },
  paginationSpacer: {
    flex: 0
  },
}));
