import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import cache from "../../../../../services/memoryCache/cache";


export interface ChartIpAttempts {
  period: {
    to: string;
    from: string;
  },
  attempts: [{
    timestamp: string;
    attempts: number;
  }]
}

export const aggregateApiSlice = createApi({
  reducerPath: 'agrregateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_AGGREGATED_ATTEMPTS as string,
    prepareHeaders(headers) {
      const token = cache.get('token');
      headers.set('Authorization', `${token.tokenType} ${token.accessToken}`);

      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchAggregateAttempts: builder.query<ChartIpAttempts, string | void>({
        query(queryParams) {
          return `${queryParams}`;
        }
      })
    }
  }
})

export const { useFetchAggregateAttemptsQuery } = aggregateApiSlice;