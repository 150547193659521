import  makeStyles  from '@mui/styles/makeStyles';

export const ipListStyles = () : Function => makeStyles((theme : any) => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      color: theme.palette.primary.dark,
      fontSize: '1rem',
      marginBottom: '2px',
    },
  },
  
  
}));

export const listPaginationStyles = (themeSelected: any) => {

  const listStyles = {
    '.css-wjh20t-MuiPagination-ul': {
      justifyContent: 'center'
    },
    '.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
      height: '40px',
      width: '40px',
      borderRadius: '50px'
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '52px',
    },
    '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
      color: themeSelected.palette.text.primary,
    },
    '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
      backgroundColor: themeSelected.palette.primary.main,
      color: themeSelected.palette.primary.contrastText,
    },
    '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover': {
      backgroundColor: themeSelected.palette.primary.light,
      color: themeSelected.palette.text.secondary,
    },
    '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover': {
      backgroundColor: themeSelected.palette.primary.light,
      color: themeSelected.palette.text.secondary,
    }
  }

  return listStyles;

}