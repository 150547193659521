import * as am4core from '@amcharts/amcharts4/core';
import {
  HeatLegend,
  MapChart,
  MapPolygonSeries,
  MapImageSeries,
  MapPolygon,
  projections,
  MapImage,
  MapArcSeries,
  MapLineSeries,
  MapArc,
  MapLine,
} from '@amcharts/amcharts4/maps';
import am4geodataWorldLow from '@amcharts/amcharts4-geodata/worldLow';
import { notWellDefined } from '../../interfaces/common';
import { prepareMapData } from './mapDictionary';
import { ValueAxisDataItem } from '@amcharts/amcharts4/charts';
import {
  MapColors,
  MapType,
  MapAnimationType,
  MapOptions
} from '../../../../store/features/view/customViewSettingsSlice';
import { AccumulatedObj } from '../../interfaces/services';
import { AttemptsFilterObj } from '../../interfaces/components';
import { Latlong, latlong } from '../../features/MapAttempts/map-utils/country-definitions';

// all the functions that are defining the map are stored here

export interface MapHeatLegend {
  serie: HeatLegend;
  minRange: ValueAxisDataItem;
  maxRange: ValueAxisDataItem;
}

export function changeHeatLegendColors(heatLegend: HeatLegend, mapColors: MapColors): void {
  const { heatStart, heatStop } = mapColors;
  heatLegend.minColor = am4core.color(heatStart);
  heatLegend.maxColor = am4core.color(heatStop);
}

export function createHeatLegend(map: MapChart, mapColors?: MapColors): MapHeatLegend {
  const heatLegend = map.createChild(HeatLegend);
  heatLegend.id = 'legend';
  heatLegend.align = 'right';
  heatLegend.width = am4core.percent(25);
  heatLegend.marginRight = am4core.percent(5);
  heatLegend.marginBottom = am4core.percent(5);
  heatLegend.valign = 'bottom';

  mapColors && changeHeatLegendColors(heatLegend, mapColors);

  const minRange = heatLegend.valueAxis.axisRanges.create();
  const maxRange = heatLegend.valueAxis.axisRanges.create();

  heatLegend.valueAxis.renderer.labels.template.adapter.add('text', function (labelText: string | undefined) {
    return '';
  });

  return { serie: heatLegend, minRange, maxRange };
}

export function configureHeatLegend(
  heatLegend: MapHeatLegend,
  minVal: number,
  maxVal: number,
  mapColors?: MapColors
): void {
  mapColors && changeHeatLegendColors(heatLegend.serie, mapColors);
  if (minVal === maxVal && maxVal > 0) minVal = 0;
  heatLegend.serie.minValue = minVal;
  heatLegend.serie.maxValue = maxVal;

  if (minVal !== maxVal) {
    heatLegend.minRange.value = minVal; //heatLegend.minValue;
    heatLegend.minRange.label.text = `${minVal}`;
    heatLegend.maxRange.value = maxVal; //heatLegend.maxValue;
    heatLegend.maxRange.label.text = `${maxVal}`;
  }
}

// changes the background color of the map so basically the sea
export function changeBackgroundColor(map: MapChart, mapColors: MapColors): void {
  const { background } = mapColors;
  map.background.fill = am4core.color(background);
  map.background.fillOpacity = 1;
}

// changes the colors of the countries and the borders
export function changeBaseColor(baseSeries: MapPolygonSeries, mapColors: MapColors): void {
  const { main, background } = mapColors;
  baseSeries.mapPolygons.template.fill = am4core.color(main);
  baseSeries.mapPolygons.template.stroke = am4core.color(background);
  baseSeries.mapPolygons.template.strokeWidth = 1;
}

export function createBaseSerie(mapColors: MapColors): MapPolygonSeries {
  const baseSeries = new MapPolygonSeries();
  baseSeries.useGeodata = true;
  baseSeries.id = 'base';
  changeBaseColor(baseSeries, mapColors);
  baseSeries.exclude = ['AQ'];
  return baseSeries;
}

export function changeBubbleSize(serie: MapImageSeries, mobileBreakpoint: boolean): void {
  const rule = serie.heatRules.pop();
  if (rule) {
    rule.max = mobileBreakpoint ? 15 : 25;
    serie.heatRules.push(rule);
  }
}

export function prepareBubbleSerie(smallBubbles: boolean): MapImageSeries {
  const boubleSerie = new MapImageSeries();
  boubleSerie.dataFields.value = 'value';
  boubleSerie.id = 'bouble';
  const boubleTemplate = boubleSerie.mapImages.template;
  boubleTemplate.propertyFields.latitude = 'latitude';
  boubleTemplate.propertyFields.longitude = 'longitude';
  boubleTemplate.propertyFields.id = 'id';
  boubleTemplate.tooltipText = '{name}';
  boubleTemplate.nonScaling = true;
  //boubleTemplate.showSystemTooltip = false;
  const circle = boubleTemplate.createChild(am4core.Circle);
  circle.fillOpacity = 0.7;
  circle.propertyFields.fill = 'color';
  //circle.tooltipText = '{name}';

  boubleSerie.heatRules.push({
    target: circle,
    property: 'radius',
    min: 6,
    max: smallBubbles ? 15 : 25,
    dataField: 'value'
  });
  //prepare series - heat

  return boubleSerie;
}

export function changeHeatSerieColors(serie: MapPolygonSeries, mapColors: MapColors): void {
  const { main } = mapColors;
  serie.mapPolygons.template.fill = am4core.color(main);
}

export function prepareHeatSerie(mapColors: MapColors): MapPolygonSeries {
  const heatSerie = new MapPolygonSeries();
  heatSerie.id = 'heat';
  changeHeatSerieColors(heatSerie, mapColors);
  heatSerie.mapPolygons.template.propertyFields.id = 'id';
  heatSerie.exclude = ['AQ'];
  heatSerie.mapPolygons.template.tooltipText = '{name}';
  if (heatSerie.tooltip) {
    heatSerie.tooltip.getFillFromObject = false;
    heatSerie.tooltip.background.fill = am4core.color('#000000');
  }
  return heatSerie;
}

export function addOnClickHandler(
  dataSerie: MapPolygonSeries | MapImageSeries,
  type: MapType,
  handler: notWellDefined<(filter: AttemptsFilterObj) => void>
): void {
  if (handler) {
    switch (type) {
      case 'heat':
        (dataSerie as MapPolygonSeries).mapPolygons.template.events.on('hit', function (ev) {
          handler({ name: 'country', filterValue: ev.target.id });
        });
        break;
      case 'bubble':
      default:
        (dataSerie as MapImageSeries).mapImages.template.events.on('hit', function (ev) {
          handler({ name: 'country', filterValue: ev.target.id });
        });
    }
  }
}

export function createHomeButton(map: MapChart, mapColors: MapColors, mobileBreakpoint: boolean): void {
  const homeButton = map.chartContainer.createChild(am4core.Button);
  const homeIcon = homeButton.createChild(am4core.Sprite);
  const iconPath =
    'm503.871094 231.433594-236.800782-226.984375c-6.183593-5.933594-15.957031-5.933594-22.140624 0l-237.035157 227.21875c-5.015625 5.015625-7.894531 11.925781-7.894531 18.988281 0 14.699219 11.96875 26.667969 26.667969 26.667969h37.332031v202.664062c0 17.664063 14.335938 32 32 32h90.667969c8.832031 0 16-7.167969 16-16v-138.664062c0-2.925781 2.386719-5.335938 5.332031-5.335938h96c2.921875 0 5.332031 2.410157 5.332031 5.335938v138.664062c0 8.832031 7.167969 16 16 16h90.667969c17.664062 0 32-14.335937 32-32v-202.664062h37.332031c14.699219 0 26.667969-11.96875 26.667969-26.667969 0-7.0625-2.878906-13.972656-8.128906-19.222656zm0 0';
  const buttonPadding = mobileBreakpoint ? 4 : 8;

  homeButton.states.create('down');
  const homeButtonDownState = homeButton.background.states.getKey('down');
  if (homeButtonDownState && homeButtonDownState.properties)
    homeButtonDownState.properties.fill = am4core.color(mapColors.homeButtonPress);

  if (!mobileBreakpoint) {
    homeButton.states.create('hover');
    const homeButtonHoverState = homeButton.background.states.getKey('hover');
    if (homeButtonHoverState && homeButtonHoverState.properties)
      homeButtonHoverState.properties.fill = am4core.color(mapColors.homeButtonHover);
  }

  homeButton.strokeWidth = 1;
  homeButton.padding(buttonPadding, buttonPadding, buttonPadding, buttonPadding);
  homeButton.marginRight = 15;
  homeButton.align = 'right';
  homeButton.contentAlign = 'center';
  homeButton.contentValign = 'middle';
  homeButton.events.on('hit', (): void => map.goHome());

  homeIcon.path = iconPath;
  homeIcon.fill = am4core.color(mapColors.homeIcon);
  homeIcon.nonScaling = false;
  homeIcon.scale = mobileBreakpoint ? 0.03 : 0.04;
}

//explicit any, no idea how to type this with amcharts api yet
export function addDataHoverEffects(template: any, legend: HeatLegend): void {
  template.events.on('over', (ev: any): void => {
    if (!isNaN(ev.target.dataItem.value)) {
      legend.valueAxis.showTooltipAt(ev.target.dataItem.value);
    } else {
      legend.valueAxis.hideTooltip();
    }
  });
  template.events.on('out', function (ev: any): void {
    legend.valueAxis.hideTooltip();
  });
}

export function updateDataSerie(dataSerie: MapPolygonSeries | MapImageSeries, data: AccumulatedObj[]): void {
  dataSerie.data = data;
}

export interface MapObj {
  container: am4core.Container;
  map: MapChart;
  heatLegend: MapHeatLegend;
  baseSerie: MapPolygonSeries;
  dataSerie: MapPolygonSeries | MapImageSeries;
  lineSerie: MapArcSeries | MapLineSeries;
  type: MapType;
}

const calculateLineStroke = (series: MapArcSeries | MapLineSeries): void => {
  series.mapLines.template.line.strokeWidth = 2;
};

const configLineSeries = (series: MapArcSeries | MapLineSeries, color: string): void => {
  series.mapLines.template.line.strokeOpacity = 1;
  calculateLineStroke(series);
  series.mapLines.template.line.stroke = am4core.color(color);
};

export const createLineSeries = (
  map: MapChart,
  variant: MapAnimationType,
  lineColor: string
): MapLineSeries => {
  const lineSeries = map.series.push(new MapLineSeries());
  lineSeries.mapLines.template.line.nonScalingStroke = true;
  configLineSeries(lineSeries, lineColor);
  switch (variant) {
    case 'dot-once':
    case 'dot-infinite':
      lineSeries.mapLines.template.line.strokeOpacity = 0;
      break;
    case 'line-infinite':
      lineSeries.id = 'line-infinite';
      break;
    case 'line-once':
      lineSeries.id = 'line-once';
      break;
    case 'line-pernament':
    default:
      lineSeries.id = 'line-pernament';
  }
  lineSeries.zIndex = 10;
  return lineSeries;
};

export function createMap(
  containerId: string | HTMLElement,
  mapOpts: MapOptions,
  mobileBreakpoint: boolean
): MapObj {
  const container = am4core.create(containerId, am4core.Container);

  //Removing the logo
  container.logo.dispose()
  container.width = am4core.percent(100);
  container.height = am4core.percent(100);

  const newMapInstance = container.createChild(MapChart);

  newMapInstance.geodata = am4geodataWorldLow;
  newMapInstance.seriesContainer.draggable = true;
  newMapInstance.projection = new projections.Miller();
  newMapInstance.maxZoomLevel = 1;

  changeBackgroundColor(newMapInstance, mapOpts.colors);

  const baseSerie = createBaseSerie(mapOpts.colors);
  newMapInstance.series.push(baseSerie);
  const heatLegend = createHeatLegend(newMapInstance, mapOpts.colors);

  let dataSerie: MapPolygonSeries | MapImageSeries;
  let dataSerieTemplate: MapPolygon | MapImage;
  switch (mapOpts.type) {
    case 'heat':
      dataSerie = prepareHeatSerie(mapOpts.colors);
      dataSerieTemplate = dataSerie.mapPolygons.template;
      newMapInstance.series.push(dataSerie);
      dataSerie.useGeodata = true;
      break;
    case 'bubble':
    default:
      dataSerie = prepareBubbleSerie(mobileBreakpoint);
      dataSerieTemplate = dataSerie.mapImages.template;
      newMapInstance.series.push(dataSerie);
  }
  addDataHoverEffects(dataSerieTemplate, heatLegend.serie);

  const lineSerie = createLineSeries(newMapInstance, mapOpts.animation.type, mapOpts.colors.animation);

  return {
    container,
    map: newMapInstance,
    baseSerie,
    heatLegend,
    dataSerie,
    lineSerie,
    type: mapOpts.type
  };
}

export function disableZoom(map : MapChart): void {
  map.zoomControl.visible= false;
}

export function prepareData(data: AccumulatedObj[], mapColors: MapColors, maxVal: number): any[] {

  const enchanceDataWithColors = prepareMapData(mapColors.heatStart, mapColors.heatStop);
  return data.map((dataItem: AccumulatedObj) => {
    return enchanceDataWithColors(dataItem.id, maxVal, Number(dataItem.value));
  });
}

const createDots = (line: MapArc | MapLine, color: string, variant: MapAnimationType): void => {
  const bullet = line.lineObjects.create();
  const moveAnimation = bullet.animate({ from: 0, to: 1, property: 'position' }, 3000, am4core.ease.sinInOut);
  moveAnimation.events.on('animationended', () => {
    bullet.dispose();
    variant === 'dot-infinite' && createDots(line, color, variant);
  });
  const circle = bullet.createChild(am4core.Circle);
  circle.radius = 2;
  circle.fill = am4core.color(color);
  circle.strokeWidth = 1;
  circle.stroke = am4core.color(color);
};

const addLine = (
  series: MapArcSeries | MapLineSeries,
  from: Latlong,
  to: Latlong,
  infinite = false
): MapArc | MapLine => {
  const line = series.mapLines.create();

  line.multiGeoLine = [[from, to]];
  line.strokeDasharray = '1000';
  
  const lineAnimation = line.animate(
    { from: 1000, to: infinite ? -500 : 0, property: 'strokeDashoffset' },
    6000,
    am4core.ease.sinInOut
  );
  infinite && lineAnimation.loop();
  return line;
};

export function changeLegendVisibility(legend : MapHeatLegend | undefined, visible: boolean): void{
  if(legend)
  legend.serie.visible = visible;
}

export const sortAttemptsArrayByValueDesc = (data: AccumulatedObj[]): AccumulatedObj[] => {
  const sortByAttemptsValueDescending = data.sort((a, b) => Number(b.value) - Number(a.value));

  return sortByAttemptsValueDescending;
};

export function animateMap<T extends AccumulatedObj>(
  lineSeries: MapArcSeries | MapLineSeries,
  variant: MapAnimationType,
  data: T[],
  mapColors: MapColors,
  targetCountryCode: string,
  numberOfAnimatedLines: number
): void {
  lineSeries.mapLines.clear();
  const sortByAttemptsValueDescending = sortAttemptsArrayByValueDesc(data); // sort by attempts value descending before slicing
  const filteredData: AccumulatedObj[] = sortByAttemptsValueDescending.slice(0, numberOfAnimatedLines);


  filteredData.forEach((agreggatedAttempt) => {
    if (agreggatedAttempt) {
      const from = latlong[agreggatedAttempt.id];
      
      const to = latlong[targetCountryCode];
      let currentLine;
      if (from && to) {
        currentLine = addLine(lineSeries, from, to);
        if (variant === 'dot-once' || variant === 'dot-infinite')
          createDots(currentLine, mapColors.animation, variant);
      }
    }
  });
}

export function changeAnimationColor(lineSeries: MapArcSeries | MapLineSeries, color: string): void {
  lineSeries.mapLines.template.line.stroke = am4core.color(color);
}

export function removeAnimations(map: MapChart): void {
  map.series.each((serie) => {
    if (
      ['dot-once', 'dot-infinite', 'line-infinite', 'line-once', 'line-pernament'].includes(serie.id) &&
      !serie.isDisposed()
    )
      serie.dispose();
  });
}

export function removeDataSeries(map: MapChart): void {
  map.series.each((serie) => {
    if (['bouble', 'heat'].includes(serie.id) && !serie.isDisposed()) serie.dispose();
  });
}

export function removeHeatData(map: MapChart): void {
  map.children.each((mapChild) => {
    if (mapChild.className === 'HeatLegend' && !mapChild.isDisposed()) mapChild.dispose();
  });
}

export function removeBaseSeries(map: MapChart): void {
  map.series.each((serie) => {
    if (serie.id === 'base' && !serie.isDisposed()) serie.dispose();
  });
}

export function clearMapData(map: MapChart): void {
  removeAnimations(map);
  removeDataSeries(map);
  removeHeatData(map);
  removeBaseSeries(map);
}

export function clearMap(container: am4core.Container, map: MapChart): void {
  clearMapData(map);
  !map.isDisposed() && map.dispose();
  !container.isDisposed() && container.dispose();
}
