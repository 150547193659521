import { Theme } from '@mui/material';
import  makeStyles  from '@mui/styles/makeStyles';

export const chartDescriptorUseStyles = () : Function => makeStyles((theme : Theme) => ({
  container: {
    marginLeft: 30,
    marginRight: 30,
    paddingBottom: 15,
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.down(940)]: {
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  headerContainer: {
    display: 'flex',

    minHeight: '0',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
    marginBottom: 20
  },
  header: {
    textAlign: 'center'
  },
  dataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down(940)]: {
      flexDirection: 'column'
    },
    // [theme.breakpoints.down('md')]: {
    //   marginRight: 10
    // },
    // [theme.breakpoints.down('md')]: {
    //   flexDirection: 'column'
    // },
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 'auto',
      marginRight: 0,
      marginBottom: 10
    },
    [theme.breakpoints.down(940)]: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 'auto',
      marginRight: 0,
      marginBottom: 10
    },
    // [theme.breakpoints.down('md')]: {
    //   marginRight: 10
    // },
  },
  normalCellDistance: {
    marginRight: 50,
    [theme.breakpoints.down(940)]: {
      marginRight: 0
    }
  },
  mobileCellDistance: {
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    },
    [theme.breakpoints.down(940)]: {
      marginRight: 0
    }
  },
  mobileCellDivider: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 50
    },
    [theme.breakpoints.down(940)]: {
      marginRight: 50
    }
  },
  dataRowLastCell: {
    marginRight: 0
  },
  dataCell: {
    color: theme.palette.primary.main
  },
  imageWrapper: {
    color: theme.palette.primary.dark,
    height: theme.spacing(3),
    paddingRight: theme.spacing(1)
  },
  tooltip: {
    marginLeft: 3
  }
}));
