import { subHours, subDays, subWeeks, subMonths, subYears } from 'date-fns';
import { Timescale, timeValues } from '../../interfaces/services';

export function timescale2ISOdates(timescale: Timescale): { from: string; to: string; res?: timeValues } {
  let endDate: Date = new Date();
  let startDate: Date = subHours(endDate, 1);
  switch (timescale.value) {
    case 'd':
      startDate = subDays(endDate, 1);
      break;
    case 'w':
      startDate = subWeeks(endDate, 1);
      break;
    case 'm':
      startDate = subMonths(endDate, 1);
      break;
    case '3m':
      startDate = subMonths(endDate, 3);
      break;
    case '6m':
      startDate = subMonths(endDate, 6);
      break;
    case 'y':
      startDate = subYears(endDate, 1);
      break;
    case 'custom':
      if (timescale.selectedDates) {
        startDate = timescale.selectedDates.from;
        endDate = timescale.selectedDates.to;
      }
      break;
    case 'h':
    default:
      startDate = subHours(endDate, 1);
      break;
  }
  return { from: startDate.toISOString(), to: endDate.toISOString() };
}

export function getPeriodForCustom(days: number) {
  
  let value: timeValues = 'h';
  if (days === 1) {
    value = 'd';
  } else if (days > 1 && days <= 7) {
    value = 'w';
  } else if (days > 7 && days <= 30) {
    value = 'm';
  } else if (days > 30 && days <= 90) {
    value = '3m';
  } else if (days > 90 && days <= 180) {
    value = '6m';
  } else if (days > 180) {
    value = 'y';
  }

  return value;

}
