import { notWellDefined } from '../../../interfaces/common';
import i18next from 'i18next';
import { toUpper } from 'lodash';

export function getDefaultValue(
  value: notWellDefined<string | number>,
  returnZero?: boolean,
  optionalOutput?: string
): string {
  if (typeof value === 'number') {
    return value.toString();
  } else {
    return value || (returnZero ? '0' : optionalOutput || toUpper(i18next.t('shared.na')));
  }
}