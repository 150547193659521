import { createSlice } from "@reduxjs/toolkit";
import { statusAPISlice, SystemInfoApiObj } from './status-api-slice';

const initialState: SystemInfoApiObj = {
    activationDate: "",
    deviceHealth: "",
    externalProtection: "OK",
    hostname: "",
    internalProtection: "OK",
    monitored: "OK",
    timestamp: "",
}

const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
        statusAPISlice.endpoints.fetchSystemHealth.matchFulfilled,
      (state, {payload}) => {
        state.activationDate = payload.activationDate;
        state.deviceHealth = payload.deviceHealth;
        state.externalProtection = payload.externalProtection;
        state.hostname = payload.hostname;
        state.internalProtection = payload.internalProtection;
        state.monitored = payload.monitored;
        state.timestamp = payload.timestamp;
      }
    )
  },
});

export default statsSlice.reducer;