import { DictionaryEntry } from '../../../interfaces/services';

export interface Latlong {
  latitude: number;
  longitude: number;
}

export interface LatlongWrapper {
  [key: string]: Latlong;
}

export type MergedData = DictionaryEntry & Latlong;

export const latlong: LatlongWrapper = {
  AD: { latitude: 42.5, longitude: 1.5 },
  AE: { latitude: 24, longitude: 54 },
  AF: { latitude: 33, longitude: 65 },
  AG: { latitude: 17.05, longitude: -61.8 },
  AI: { latitude: 18.25, longitude: -63.1667 },
  AL: { latitude: 41, longitude: 20 },
  AM: { latitude: 40, longitude: 45 },
  AN: { latitude: 12.25, longitude: -68.75 },
  AO: { latitude: -12.5, longitude: 18.5 },
  AP: { latitude: 35, longitude: 105 },
  AQ: { latitude: -90, longitude: 0 },
  AR: { latitude: -34, longitude: -64 },
  AS: { latitude: -14.3333, longitude: -170 },
  AT: { latitude: 47.3333, longitude: 13.3333 },
  AU: { latitude: -27, longitude: 133 },
  AW: { latitude: 12.5, longitude: -69.9667 },
  AZ: { latitude: 40.5, longitude: 47.5 },
  BA: { latitude: 44, longitude: 18 },
  BB: { latitude: 13.1667, longitude: -59.5333 },
  BD: { latitude: 24, longitude: 90 },
  BE: { latitude: 50.8333, longitude: 4 },
  BF: { latitude: 13, longitude: -2 },
  BG: { latitude: 43, longitude: 25 },
  BH: { latitude: 26, longitude: 50.55 },
  BI: { latitude: -3.5, longitude: 30 },
  BJ: { latitude: 9.5, longitude: 2.25 },
  BM: { latitude: 32.3333, longitude: -64.75 },
  BN: { latitude: 4.5, longitude: 114.6667 },
  BO: { latitude: -17, longitude: -65 },
  BR: { latitude: -10, longitude: -55 },
  BS: { latitude: 24.25, longitude: -76 },
  BT: { latitude: 27.5, longitude: 90.5 },
  BV: { latitude: -54.4333, longitude: 3.4 },
  BW: { latitude: -22, longitude: 24 },
  BY: { latitude: 53, longitude: 28 },
  BZ: { latitude: 17.25, longitude: -88.75 },
  CA: { latitude: 54, longitude: -100 },
  CC: { latitude: -12.5, longitude: 96.8333 },
  CD: { latitude: 0, longitude: 25 },
  CF: { latitude: 7, longitude: 21 },
  CG: { latitude: -1, longitude: 15 },
  CH: { latitude: 47, longitude: 8 },
  CI: { latitude: 8, longitude: -5 },
  CK: { latitude: -21.2333, longitude: -159.7667 },
  CL: { latitude: -30, longitude: -71 },
  CM: { latitude: 6, longitude: 12 },
  CN: { latitude: 35, longitude: 105 },
  CO: { latitude: 4, longitude: -72 },
  CR: { latitude: 10, longitude: -84 },
  CU: { latitude: 21.5, longitude: -80 },
  CV: { latitude: 16, longitude: -24 },
  CX: { latitude: -10.5, longitude: 105.6667 },
  CY: { latitude: 35, longitude: 33 },
  CZ: { latitude: 49.75, longitude: 15.5 },
  DE: { latitude: 51, longitude: 9 },
  DJ: { latitude: 11.5, longitude: 43 },
  DK: { latitude: 56, longitude: 10 },
  DM: { latitude: 15.4167, longitude: -61.3333 },
  DO: { latitude: 19, longitude: -70.6667 },
  DZ: { latitude: 28, longitude: 3 },
  EC: { latitude: -2, longitude: -77.5 },
  EE: { latitude: 59, longitude: 26 },
  EG: { latitude: 27, longitude: 30 },
  EH: { latitude: 24.5, longitude: -13 },
  ER: { latitude: 15, longitude: 39 },
  ES: { latitude: 40, longitude: -4 },
  ET: { latitude: 8, longitude: 38 },
  EU: { latitude: 47, longitude: 8 },
  FI: { latitude: 62, longitude: 26 },
  FJ: { latitude: -18, longitude: 175 },
  FK: { latitude: -51.75, longitude: -59 },
  FM: { latitude: 6.9167, longitude: 158.25 },
  FO: { latitude: 62, longitude: -7 },
  FR: { latitude: 46, longitude: 2 },
  GA: { latitude: -1, longitude: 11.75 },
  GB: { latitude: 54, longitude: -2 },
  GD: { latitude: 12.1167, longitude: -61.6667 },
  GE: { latitude: 42, longitude: 43.5 },
  GF: { latitude: 4, longitude: -53 },
  GH: { latitude: 8, longitude: -2 },
  GI: { latitude: 36.1833, longitude: -5.3667 },
  GL: { latitude: 72, longitude: -40 },
  GM: { latitude: 13.4667, longitude: -16.5667 },
  GN: { latitude: 11, longitude: -10 },
  GP: { latitude: 16.25, longitude: -61.5833 },
  GQ: { latitude: 2, longitude: 10 },
  GR: { latitude: 39, longitude: 22 },
  GS: { latitude: -54.5, longitude: -37 },
  GT: { latitude: 15.5, longitude: -90.25 },
  GU: { latitude: 13.4667, longitude: 144.7833 },
  GW: { latitude: 12, longitude: -15 },
  GY: { latitude: 5, longitude: -59 },
  HK: { latitude: 22.25, longitude: 114.1667 },
  HM: { latitude: -53.1, longitude: 72.5167 },
  HN: { latitude: 15, longitude: -86.5 },
  HR: { latitude: 45.1667, longitude: 15.5 },
  HT: { latitude: 19, longitude: -72.4167 },
  HU: { latitude: 47, longitude: 20 },
  ID: { latitude: -5, longitude: 120 },
  IE: { latitude: 53, longitude: -8 },
  IL: { latitude: 31.5, longitude: 34.75 },
  IM: { latitude: 54.23, longitude: -4.55 },
  IN: { latitude: 20, longitude: 77 },
  IO: { latitude: -6, longitude: 71.5 },
  IQ: { latitude: 33, longitude: 44 },
  IR: { latitude: 32, longitude: 53 },
  IS: { latitude: 65, longitude: -18 },
  IT: { latitude: 42.8333, longitude: 12.8333 },
  JM: { latitude: 18.25, longitude: -77.5 },
  JO: { latitude: 31, longitude: 36 },
  JP: { latitude: 36, longitude: 138 },
  KE: { latitude: 1, longitude: 38 },
  KG: { latitude: 41, longitude: 75 },
  KH: { latitude: 13, longitude: 105 },
  KI: { latitude: 1.4167, longitude: 173 },
  KM: { latitude: -12.1667, longitude: 44.25 },
  KN: { latitude: 17.3333, longitude: -62.75 },
  KP: { latitude: 40, longitude: 127 },
  KR: { latitude: 37, longitude: 127.5 },
  KW: { latitude: 29.3375, longitude: 47.6581 },
  KY: { latitude: 19.5, longitude: -80.5 },
  KZ: { latitude: 48, longitude: 68 },
  LA: { latitude: 18, longitude: 105 },
  LB: { latitude: 33.8333, longitude: 35.8333 },
  LC: { latitude: 13.8833, longitude: -61.1333 },
  LI: { latitude: 47.1667, longitude: 9.5333 },
  LK: { latitude: 7, longitude: 81 },
  LR: { latitude: 6.5, longitude: -9.5 },
  LS: { latitude: -29.5, longitude: 28.5 },
  LT: { latitude: 55, longitude: 24 },
  LU: { latitude: 49.75, longitude: 6 },
  LV: { latitude: 57, longitude: 25 },
  LY: { latitude: 25, longitude: 17 },
  MA: { latitude: 32, longitude: -5 },
  MC: { latitude: 43.7333, longitude: 7.4 },
  MD: { latitude: 47, longitude: 29 },
  ME: { latitude: 42.5, longitude: 19.4 },
  MG: { latitude: -20, longitude: 47 },
  MH: { latitude: 9, longitude: 168 },
  MK: { latitude: 41.8333, longitude: 22 },
  ML: { latitude: 17, longitude: -4 },
  MM: { latitude: 22, longitude: 98 },
  MN: { latitude: 46, longitude: 105 },
  MO: { latitude: 22.1667, longitude: 113.55 },
  MP: { latitude: 15.2, longitude: 145.75 },
  MQ: { latitude: 14.6667, longitude: -61 },
  MR: { latitude: 20, longitude: -12 },
  MS: { latitude: 16.75, longitude: -62.2 },
  MT: { latitude: 35.8333, longitude: 14.5833 },
  MU: { latitude: -20.2833, longitude: 57.55 },
  MV: { latitude: 3.25, longitude: 73 },
  MW: { latitude: -13.5, longitude: 34 },
  MX: { latitude: 23, longitude: -102 },
  MY: { latitude: 2.5, longitude: 112.5 },
  MZ: { latitude: -18.25, longitude: 35 },
  NA: { latitude: -22, longitude: 17 },
  NC: { latitude: -21.5, longitude: 165.5 },
  NE: { latitude: 16, longitude: 8 },
  NF: { latitude: -29.0333, longitude: 167.95 },
  NG: { latitude: 10, longitude: 8 },
  NI: { latitude: 13, longitude: -85 },
  NL: { latitude: 52.5, longitude: 5.75 },
  NO: { latitude: 62, longitude: 10 },
  NP: { latitude: 28, longitude: 84 },
  NR: { latitude: -0.5333, longitude: 166.9167 },
  NU: { latitude: -19.0333, longitude: -169.8667 },
  NZ: { latitude: -41, longitude: 174 },
  OM: { latitude: 21, longitude: 57 },
  PA: { latitude: 9, longitude: -80 },
  PE: { latitude: -10, longitude: -76 },
  PF: { latitude: -15, longitude: -140 },
  PG: { latitude: -6, longitude: 147 },
  PH: { latitude: 13, longitude: 122 },
  PK: { latitude: 30, longitude: 70 },
  PL: { latitude: 52, longitude: 20 },
  PM: { latitude: 46.8333, longitude: -56.3333 },
  PR: { latitude: 18.25, longitude: -66.5 },
  PS: { latitude: 32, longitude: 35.25 },
  PT: { latitude: 39.5, longitude: -8 },
  PW: { latitude: 7.5, longitude: 134.5 },
  PY: { latitude: -23, longitude: -58 },
  QA: { latitude: 25.5, longitude: 51.25 },
  RE: { latitude: -21.1, longitude: 55.6 },
  RO: { latitude: 46, longitude: 25 },
  RS: { latitude: 44, longitude: 21 },
  RU: { latitude: 60, longitude: 100 },
  RW: { latitude: -2, longitude: 30 },
  SA: { latitude: 25, longitude: 45 },
  SB: { latitude: -8, longitude: 159 },
  SC: { latitude: -4.5833, longitude: 55.6667 },
  SD: { latitude: 15, longitude: 30 },
  SE: { latitude: 62, longitude: 15 },
  SG: { latitude: 1.3667, longitude: 103.8 },
  SH: { latitude: -15.9333, longitude: -5.7 },
  SI: { latitude: 46, longitude: 15 },
  SJ: { latitude: 78, longitude: 20 },
  SK: { latitude: 48.6667, longitude: 19.5 },
  SL: { latitude: 8.5, longitude: -11.5 },
  SM: { latitude: 43.7667, longitude: 12.4167 },
  SN: { latitude: 14, longitude: -14 },
  SO: { latitude: 10, longitude: 49 },
  SR: { latitude: 4, longitude: -56 },
  ST: { latitude: 1, longitude: 7 },
  SV: { latitude: 13.8333, longitude: -88.9167 },
  SY: { latitude: 35, longitude: 38 },
  SZ: { latitude: -26.5, longitude: 31.5 },
  TC: { latitude: 21.75, longitude: -71.5833 },
  TD: { latitude: 15, longitude: 19 },
  TF: { latitude: -43, longitude: 67 },
  TG: { latitude: 8, longitude: 1.1667 },
  TH: { latitude: 15, longitude: 100 },
  TJ: { latitude: 39, longitude: 71 },
  TK: { latitude: -9, longitude: -172 },
  TM: { latitude: 40, longitude: 60 },
  TN: { latitude: 34, longitude: 9 },
  TO: { latitude: -20, longitude: -175 },
  TR: { latitude: 39, longitude: 35 },
  TT: { latitude: 11, longitude: -61 },
  TV: { latitude: -8, longitude: 178 },
  TW: { latitude: 23.5, longitude: 121 },
  TZ: { latitude: -6, longitude: 35 },
  UA: { latitude: 49, longitude: 32 },
  UG: { latitude: 1, longitude: 32 },
  UM: { latitude: 19.2833, longitude: 166.6 },
  US: { latitude: 38, longitude: -97 },
  UY: { latitude: -33, longitude: -56 },
  UZ: { latitude: 41, longitude: 64 },
  VA: { latitude: 41.9, longitude: 12.45 },
  VC: { latitude: 13.25, longitude: -61.2 },
  VE: { latitude: 8, longitude: -66 },
  VG: { latitude: 18.5, longitude: -64.5 },
  VI: { latitude: 18.3333, longitude: -64.8333 },
  VN: { latitude: 16, longitude: 106 },
  VU: { latitude: -16, longitude: 167 },
  WF: { latitude: -13.3, longitude: -176.2 },
  WS: { latitude: -13.5833, longitude: -172.3333 },
  XK:	{ latitude: 42.602636, longitude:	20.902977},
  YE: { latitude: 15, longitude: 48 },
  YT: { latitude: -12.8333, longitude: 45.1667 },
  ZA: { latitude: -29, longitude: 24 },
  ZM: { latitude: -15, longitude: 30 },
  ZW: { latitude: -20, longitude: 30 },
  SX: { latitude: 18.04, longitude: -63.06 }
};

export const mapData: DictionaryEntry[] = [
  { id: 'AF', name: 'Afghanistan' },
  { id: 'AL', name: 'Albania' },
  { id: 'DZ', name: 'Algeria' },
  { id: 'AO', name: 'Angola' },
  { id: 'AR', name: 'Argentina' },
  { id: 'AM', name: 'Armenia' },
  { id: 'AU', name: 'Australia' },
  { id: 'AT', name: 'Austria' },
  { id: 'AZ', name: 'Azerbaijan' },
  { id: 'BH', name: 'Bahrain' },
  { id: 'BD', name: 'Bangladesh' },
  { id: 'BY', name: 'Belarus' },
  { id: 'BE', name: 'Belgium' },
  { id: 'BJ', name: 'Benin' },
  { id: 'BT', name: 'Bhutan' },
  { id: 'BO', name: 'Bolivia' },
  { id: 'BA', name: 'Bosnia and Herzegovina' },
  { id: 'BW', name: 'Botswana' },
  { id: 'BR', name: 'Brazil' },
  { id: 'BN', name: 'Brunei' },
  { id: 'BG', name: 'Bulgaria' },
  { id: 'BF', name: 'Burkina Faso' },
  { id: 'BI', name: 'Burundi' },
  { id: 'KH', name: 'Cambodia' },
  { id: 'CM', name: 'Cameroon' },
  { id: 'CA', name: 'Canada' },
  { id: 'CV', name: 'Cape Verde' },
  { id: 'CF', name: 'Central African Rep.' },
  { id: 'TD', name: 'Chad' },
  { id: 'CL', name: 'Chile' },
  { id: 'CN', name: 'China' },
  { id: 'CO', name: 'Colombia' },
  { id: 'KM', name: 'Comoros' },
  { id: 'CD', name: 'Congo, Dem. Rep.' },
  { id: 'CG', name: 'Congo, Rep.' },
  { id: 'CR', name: 'Costa Rica' },
  { id: 'CI', name: "Cote d'Ivoire" },
  { id: 'HR', name: 'Croatia' },
  { id: 'CU', name: 'Cuba' },
  { id: 'CY', name: 'Cyprus' },
  { id: 'CZ', name: 'Czech Rep.' },
  { id: 'DK', name: 'Denmark' },
  { id: 'DJ', name: 'Djibouti' },
  { id: 'DO', name: 'Dominican Rep.' },
  { id: 'EC', name: 'Ecuador' },
  { id: 'EG', name: 'Egypt' },
  { id: 'SV', name: 'El Salvador' },
  { id: 'GQ', name: 'Equatorial Guinea' },
  { id: 'ER', name: 'Eritrea' },
  { id: 'EE', name: 'Estonia' },
  { id: 'ET', name: 'Ethiopia' },
  { id: 'FJ', name: 'Fiji' },
  { id: 'FI', name: 'Finland' },
  { id: 'FR', name: 'France' },
  { id: 'GA', name: 'Gabon' },
  { id: 'GM', name: 'Gambia' },
  { id: 'GE', name: 'Georgia' },
  { id: 'DE', name: 'Germany' },
  { id: 'GH', name: 'Ghana' },
  { id: 'GR', name: 'Greece' },
  { id: 'GT', name: 'Guatemala' },
  { id: 'GN', name: 'Guinea' },
  { id: 'GW', name: 'Guinea-Bissau' },
  { id: 'GY', name: 'Guyana' },
  { id: 'HT', name: 'Haiti' },
  { id: 'HN', name: 'Honduras' },
  { id: 'HK', name: 'Hong Kong, China' },
  { id: 'HU', name: 'Hungary' },
  { id: 'IS', name: 'Iceland' },
  { id: 'IN', name: 'India' },
  { id: 'ID', name: 'Indonesia' },
  { id: 'IR', name: 'Iran' },
  { id: 'IQ', name: 'Iraq' },
  { id: 'IE', name: 'Ireland' },
  { id: 'IL', name: 'Israel' },
  { id: 'IM', name: 'Isle of Man' },
  { id: 'IT', name: 'Italy' },
  { id: 'JM', name: 'Jamaica' },
  { id: 'JP', name: 'Japan' },
  { id: 'JO', name: 'Jordan' },
  { id: 'KZ', name: 'Kazakhstan' },
  { id: 'KE', name: 'Kenya' },
  { id: 'KP', name: 'Korea, Dem. Rep.' },
  { id: 'KR', name: 'Korea, Rep.' },
  { id: 'KW', name: 'Kuwait' },
  { id: 'KG', name: 'Kyrgyzstan' },
  { id: 'LA', name: 'Laos' },
  { id: 'LV', name: 'Latvia' },
  { id: 'LB', name: 'Lebanon' },
  { id: 'LS', name: 'Lesotho' },
  { id: 'LR', name: 'Liberia' },
  { id: 'LY', name: 'Libya' },
  { id: 'LT', name: 'Lithuania' },
  { id: 'LU', name: 'Luxembourg' },
  { id: 'MK', name: 'Macedonia, FYR' },
  { id: 'MG', name: 'Madagascar' },
  { id: 'MW', name: 'Malawi' },
  { id: 'MY', name: 'Malaysia' },
  { id: 'ML', name: 'Mali' },
  { id: 'MR', name: 'Mauritania' },
  { id: 'MU', name: 'Mauritius' },
  { id: 'MX', name: 'Mexico' },
  { id: 'MD', name: 'Moldova' },
  { id: 'MN', name: 'Mongolia' },
  { id: 'ME', name: 'Montenegro' },
  { id: 'MA', name: 'Morocco' },
  { id: 'MZ', name: 'Mozambique' },
  { id: 'MM', name: 'Myanmar' },
  { id: 'NA', name: 'Namibia' },
  { id: 'NP', name: 'Nepal' },
  { id: 'NL', name: 'Netherlands' },
  { id: 'NZ', name: 'New Zealand' },
  { id: 'NI', name: 'Nicaragua' },
  { id: 'NE', name: 'Niger' },
  { id: 'NG', name: 'Nigeria' },
  { id: 'NO', name: 'Norway' },
  { id: 'OM', name: 'Oman' },
  { id: 'PK', name: 'Pakistan' },
  { id: 'PA', name: 'Panama' },
  { id: 'PG', name: 'Papua New Guinea' },
  { id: 'PY', name: 'Paraguay' },
  { id: 'PE', name: 'Peru' },
  { id: 'PH', name: 'Philippines' },
  { id: 'PL', name: 'Poland' },
  { id: 'PT', name: 'Portugal' },
  { id: 'PR', name: 'Puerto Rico' },
  { id: 'QA', name: 'Qatar' },
  { id: 'RO', name: 'Romania' },
  { id: 'RU', name: 'Russia' },
  { id: 'RW', name: 'Rwanda' },
  { id: 'SA', name: 'Saudi Arabia' },
  { id: 'SN', name: 'Senegal' },
  { id: 'RS', name: 'Serbia' },
  { id: 'SL', name: 'Sierra Leone' },
  { id: 'SG', name: 'Singapore' },
  { id: 'SK', name: 'Slovak Republic' },
  { id: 'SI', name: 'Slovenia' },
  { id: 'SB', name: 'Solomon Islands' },
  { id: 'SO', name: 'Somalia' },
  { id: 'ZA', name: 'South Africa' },
  { id: 'ES', name: 'Spain' },
  { id: 'LK', name: 'Sri Lanka' },
  { id: 'SD', name: 'Sudan' },
  { id: 'SR', name: 'Suriname' },
  { id: 'SZ', name: 'Swaziland' },
  { id: 'SE', name: 'Sweden' },
  { id: 'CH', name: 'Switzerland' },
  { id: 'SY', name: 'Syria' },
  { id: 'TW', name: 'Taiwan' },
  { id: 'TJ', name: 'Tajikistan' },
  { id: 'TZ', name: 'Tanzania' },
  { id: 'TH', name: 'Thailand' },
  { id: 'TG', name: 'Togo' },
  { id: 'TT', name: 'Trinidad and Tobago' },
  { id: 'TN', name: 'Tunisia' },
  { id: 'TR', name: 'Turkey' },
  { id: 'TM', name: 'Turkmenistan' },
  { id: 'UG', name: 'Uganda' },
  { id: 'UA', name: 'Ukraine' },
  { id: 'AE', name: 'United Arab Emirates' },
  { id: 'GB', name: 'United Kingdom' },
  { id: 'US', name: 'United States' },
  { id: 'UY', name: 'Uruguay' },
  { id: 'UZ', name: 'Uzbekistan' },
  { id: 'VE', name: 'Venezuela' },
  { id: 'PS', name: 'West Bank and Gaza' },
  { id: 'VN', name: 'Vietnam' },
  { id: 'YE', name: 'Yemen, Rep.' },
  { id: 'ZM', name: 'Zambia' },
  { id: 'ZW', name: 'Zimbabwe' },
  { id: 'SC', name: 'Seychelles' },
  { id: 'BZ', name: 'Belize' },
  { id: 'AD', name: 'Andorra' },
  { id: 'AI', name: 'Anguilla' },
  { id: 'AG', name: 'Antigua and Barbuda' },
  { id: 'AN', name: 'Netherlands Antilles' },
  { id: 'AP', name: 'Asia/Pacific Region' },
  { id: 'AQ', name: 'Antarctica' },
  { id: 'AS', name: 'American Samoa' },
  { id: 'AW', name: 'Aruba' },
  { id: 'BB', name: 'Barbados' },
  { id: 'BM', name: 'Bermuda' },
  { id: 'BS', name: 'Bahamas' },
  { id: 'BV', name: 'Bouvet Island' },
  { id: 'CC', name: 'Cocos (Keeling) Islands' },
  { id: 'CK', name: 'Cook Islands' },
  { id: 'CX', name: 'Christmas Island' },
  { id: 'DM', name: 'Dominica' },
  { id: 'EH', name: 'Western Sahara' },
  { id: 'EU', name: 'Europe' },
  { id: 'FK', name: 'Falkland Islands (Malvinas)' },
  { id: 'FM', name: 'Federated States of Micronesia' },
  { id: 'FO', name: 'Faroe Islands' },
  { id: 'GD', name: 'Grenada' },
  { id: 'GF', name: 'French Guiana' },
  { id: 'GL', name: 'Greenland' },
  { id: 'GP', name: 'Guadeloupe' },
  { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { id: 'GU', name: 'Guam' },
  { id: 'HM', name: 'Heard Island and McDonald Islands' },
  { id: 'IO', name: 'British Indian Ocean Territory' },
  { id: 'KI', name: 'Kiribati' },
  { id: 'KN', name: 'Saint Kitts and Nevis' },
  { id: 'KY', name: 'Cayman Islands' },
  { id: 'LC', name: 'Saint Lucia' },
  { id: 'LI', name: 'Liechtenstein' },
  { id: 'MC', name: 'Monaco' },
  { id: 'MH', name: 'Marshall Islands' },
  { id: 'MO', name: 'Macau' },
  { id: 'MP', name: 'Northern Mariana Islands' },
  { id: 'MQ', name: 'Martinique' },
  { id: 'MS', name: 'Montserrat' },
  { id: 'MT', name: 'Malta' },
  { id: 'MV', name: 'Maldives' },
  { id: 'NC', name: 'New Caledonia' },
  { id: 'NF', name: 'Norfolk Island' },
  { id: 'NR', name: 'Nauru' },
  { id: 'NU', name: 'Niue' },
  { id: 'PF', name: 'French Polynesia' },
  { id: 'PM', name: 'Saint Pierre and Miquelon' },
  { id: 'PW', name: 'Palau' },
  { id: 'SH', name: 'Saint Helena' },
  { id: 'SJ', name: 'Svalbard and Jan Mayen' },
  { id: 'SM', name: 'San Marino' },
  { id: 'SX', name: 'Sint Maarten' },
  { id: 'ST', name: 'Sao Tome and Principe' },
  { id: 'TC', name: 'Turks and Caicos Islands' },
  { id: 'TF', name: 'French Southern Territories' },
  { id: 'TK', name: 'Tokelau' },
  { id: 'TO', name: 'Tonga' },
  { id: 'TV', name: 'Tuvalu' },
  { id: 'UM', name: 'United States Minor Outlying Islands' },
  { id: 'VA', name: 'Holy See (Vatican City State)' },
  { id: 'VC', name: 'Saint Vincent and the Grenadines' },
  { id: 'VG', name: 'British Virgin Islands' },
  { id: 'VG', name: 'British Virgin Islands' },
  { id: 'VI', name: 'U.S. Virgin Islands' },
  { id: 'VU', name: 'Vanuatu' },
  { id: 'WF', name: 'Wallis and Futuna' },
  { id: 'WS', name: 'Samoa' },
  { id: 'YT', name: 'Mayotte' },
  { id: 'GI', name: 'Gibraltar' },
  { id: 'RE', name: 'Reunion' },
  { id: 'XK', name: 'Kosovo' },
];

export const MergedMapData: MergedData[] = mapData.map((elem: DictionaryEntry) => {
  return { ...elem, latitude: latlong[elem.id].latitude, longitude: latlong[elem.id].longitude };
});
