/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  isAuthInProgressSelector,
  hasDefaultDeviceSelector,
  clieantAvailableDevicesSelector,
  userSettingsSelector,
  authErrorSelector,
  combinedAuthInfoSelector,
  combinedUserInfoSelector,
  clientInfoSelector,
  isAdminSelector,
  selectedLanguageSelector
} from '../../../store/features/user/selectors/userSelectors';
import { useEffect } from 'react';
import { DeviceApiObj } from '../../../store/features/user/devicesSlice';
import { useTranslation } from 'react-i18next';
import { toLower } from 'lodash';


export function useUserAuthenticated(): ReturnType<typeof isAuthenticatedSelector> {
  return useSelector(isAuthenticatedSelector);
}

export function useAuthInProgress(): ReturnType<typeof isAuthInProgressSelector> {
  return useSelector(isAuthInProgressSelector);
}

export function useDefaultDevice(): ReturnType<typeof hasDefaultDeviceSelector> {
  return useSelector(hasDefaultDeviceSelector);
}

export function useClieantAvailableDevices(): ReturnType<typeof clieantAvailableDevicesSelector> {
  return useSelector(clieantAvailableDevicesSelector);
}

export function useUserSettings(): ReturnType<typeof userSettingsSelector> {
  return useSelector(userSettingsSelector);
}

export function useAuthError(): ReturnType<typeof authErrorSelector> {
  return useSelector(authErrorSelector);
}

export function useCombinedAuthInfo(): ReturnType<typeof combinedAuthInfoSelector> {
  return useSelector(combinedAuthInfoSelector);
}

export function useCombinedUserSettingsInfo(): ReturnType<typeof combinedUserInfoSelector> {
  return useSelector(combinedUserInfoSelector);
}

export function useDefaultDeviceValidation(): DeviceApiObj | undefined {
  let validatedDevice: DeviceApiObj | undefined;
  const defaultDevice = useDefaultDevice();
  
  const availableDevices = useClieantAvailableDevices();

  if (defaultDevice && availableDevices) {
   validatedDevice = availableDevices.find((device) => device.hostname === defaultDevice);
  }

  return validatedDevice;
}

export function useClientInfo(): ReturnType<typeof clientInfoSelector> {
  return useSelector(clientInfoSelector);
}

export function useIsAdmin(): ReturnType<typeof isAdminSelector> {
  return useSelector(isAdminSelector);
}

export function useLanguage(): ReturnType<typeof selectedLanguageSelector> {
  const { i18n } = useTranslation();
  const currentLanguage = useSelector(selectedLanguageSelector);

  useEffect((): void => {
    if (i18n.language !== currentLanguage && currentLanguage) i18n.changeLanguage(toLower(currentLanguage));
  }, [i18n.language, currentLanguage]);

  return currentLanguage;
}
